import { useSelector } from "react-redux";
export const useHasScreenPermission = (screenIdToCheck) => {
    const myProfile = useSelector((state) => state.adminReducer?.myProfile);
    if (myProfile?.staff_type === 'Admin') {
        return true;
    } else if (myProfile?.id) {
        const groups = myProfile?.groups || [];
        if (Array.isArray(groups)) {
            const foundScreen = groups.some((gds) => {
                const screens = gds?.group?.groupScreens || [];
                return screens?.some((sc) => sc?.screen_id === screenIdToCheck)
            })
            return foundScreen;
        }

        return false;
    }
    return true;
}