import React, { Component } from "react";
import PropTypes from "prop-types";

var parent = null;
class Button extends Component {
  constructor(props) {
    super(props);
    parent = this;
    this.state = {};
    this.handleRedirectComponent = this.handleRedirectComponent.bind(this);
  }

  handleRedirectComponent(path) {
    parent.props.history.push(path);
  }

  render() {
    if (this.props.handleClick != null) {
      return (
        <button
          class={`btn btn-primary btn-glow ${this.props?.cssClasses}`}
          onClick={this.props.handleClick}
        >
          {this.props.title}
        </button>
      );
    }
    if (this.props.handleRedirect != null) {
      var path = this.props.handleRedirect;
      return (
        <button
          class={`btn btn-primary btn-glow ${this.props?.cssClasses}`}
          onClick={(event) => {
            if (path != null && path.length > 0) {
              this.handleRedirectComponent(path);
            } else if (this.props.handleClick) {
              this.props.handleClick(event);
            }
          }}
        >
          {this.props.title}
        </button>
      );
    }
  }
}

Button.defaultProps = {
  title: "",
  handleClick: null,
  handleRedirect: null,
};

Button.propTypes = {
  title: PropTypes.string.isRequired,
  handleRedirect: PropTypes.string,
  handleClick: PropTypes.func,
};

export default Button;
