/* eslint-disable no-unused-vars */
/* eslint-disable no-script-url */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Component } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { actionAdminLogin, ACTION_ADMINLOGIN_SUCCESS } from "./action";
import $ from "jquery";
import validator from "validator";
import { setData, KEY_USER, KEY_ADMIN_TOKEN } from "../../../utils/preferences";
import { getUrlByScreenId } from "../../../utils/screens";
import { SaveAlert } from "../../../utils/Swal";

var AdminLogin = {
  username: "",
  password: "",
};

var parent = null;

class Login extends Component {
  constructor(props) {
    super(props);
    parent = this;
    this.state = {
      AdminLogin: Object.assign({}, AdminLogin),
      error: {
        username: "",
        password: "",
      },
    };
  }

  async componentWillReceiveProps(nextProps) {
    const { AdminLogin } = nextProps;

    if (AdminLogin != null) {
      var AdminLoginResponse = Object.assign({}, AdminLogin);
      if (AdminLoginResponse.success === true) {
        await setData(
          KEY_USER,
          JSON.stringify({
            ...AdminLoginResponse.data,
            token: AdminLoginResponse.token,
          })
        );
        await setData(
          KEY_ADMIN_TOKEN,
          JSON.stringify({ token: AdminLoginResponse.token })
        );

        const redirectTo =
          getUrlByScreenId(AdminLoginResponse?.data?.onLoadScreen) ||
          "/admin/dashboard";
        window.location.href = redirectTo;
      } else {
        SaveAlert("EmailNotFound", "error");
        return;
      }
    }
  }

  handleFormFields = (event) => {
    event.preventDefault();
    const { name, value } = event.target;
    parent.handleValidation(name, value);
  };

  handleValidation(name, value) {
    var { AdminLogin } = this.state;

    var status = true;
    $("#fg-username, #fg-password").removeClass("error");

    if (AdminLogin.username.length === 0) {
      $("#fg-username").addClass("error");
      status = false;
    }
    if (!validator.isEmail(AdminLogin.username)) {
      $("#fg-username").addClass("error");
      status = false;
    }
    if (AdminLogin.password.length === 0) {
      $("#fg-password").addClass("error");
      status = false;
    }
    AdminLogin[name] = value;
    parent.setState({ AdminLogin });
  }

  handleSubmit(event) {
    event.preventDefault();

    var { AdminLogin } = parent.state;
    var status = true;
    var key = "";
    for (key in AdminLogin) {
      parent.handleValidation(key, AdminLogin[key]);
    }
    if (status) {
      parent.props.actionAdminLogin(parent.state.AdminLogin);
    } else {
    }
  }

  render() {
    const { username, password } = this.state.AdminLogin;
    const { lang } = this.props;

    return (
      <section className="flexbox-container mt-5">
        <div className="col-12 d-flex align-items-center justify-content-center">
          <div className="col-lg-4 col-md-8 col-10 box-shadow-2 p-0">
            <div className="card border-grey border-lighten-3 px-1 py-1 m-0">
              <div className="card-header border-0"></div>
              <div className="card-content">
                <p className="card-subtitle text-muted text-center font-large-1 mx-2 mb-2 primary">
                  <span>{lang?.AdminPortalLogin}</span>
                </p>
                <div className="card-body">
                  <form className="form-horizontal" novalidate>
                    <fieldset className="form-group position-relative has-icon-left">
                      <div className="form-group" id="fg-username">
                        <input
                          type="text"
                          className="form-control"
                          id="username"
                          name="username"
                          placeholder={lang?.Username}
                          value={username}
                          onChange={this.handleFormFields}
                          required
                        />
                        <div className="form-control-position">
                          <i className="ft-user"></i>
                        </div>
                      </div>
                    </fieldset>
                    <fieldset className="form-group position-relative has-icon-left">
                      <div className="form-group" id="fg-password">
                        <input
                          type="password"
                          className="form-control"
                          id="password"
                          name="password"
                          placeholder={lang?.Password}
                          value={password}
                          onChange={this.handleFormFields}
                          required
                        />
                        <div className="form-control-position">
                          <i className="fa fa-key"></i>
                        </div>
                      </div>
                    </fieldset>
                    <button
                      type="submit"
                      className="btn btn-primary btn-block"
                      onClick={this.handleSubmit}
                    >
                      <i className="ft-unlock"></i> {lang?.Login}
                    </button>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    );
  }
}

function mapStateToProps(state) {
  var AdminLogin = null;
  const lang = state?.adminReducer.lang;
  if (
    state.AdminLoginReducer != null &&
    Object.keys(state.AdminLoginReducer).length > 0 &&
    state.AdminLoginReducer.constructor === Object
  ) {
    switch (state.AdminLoginReducer.tag) {
      case ACTION_ADMINLOGIN_SUCCESS:
        AdminLogin = Object.assign({}, state.AdminLoginReducer.data);
        delete state.AdminLoginReducer;
        return { AdminLogin, lang };
      default:
        return { lang };
    }
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({ actionAdminLogin }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(Login);
