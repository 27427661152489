import Api from "../../../../api";
import { fetchSuccess } from "../../../../utils/action.util";
import {
  API_SUPPORT_INFO_GET,
  API_SUPPORT_INFO_GET_PREVIEW,
  API_SUPPORT_REQUEST_SAVE,
} from "../../../../api/constants";

export const ACTION_GET_SUPPORT_INFO = "ACTION_GET_SUPPORT_INFO";
export const ACTION_SAVE_SUPPORT_REQUEST = "ACTION_SAVE_SUPPORT_REQUEST";

export const getSupportInfo = (venueId) => async (dispatch) => {
  const isPreviewMode = localStorage.getItem("preview-mode");
  const previewType = localStorage.getItem("preview-type");
  Api.get(
    `${
      isPreviewMode && previewType === "all"
        ? API_SUPPORT_INFO_GET_PREVIEW
        : API_SUPPORT_INFO_GET
    }/${venueId}`
  ).then((res) => {
    dispatch(fetchSuccess(ACTION_GET_SUPPORT_INFO, res.data));
  });
};

export const saveSupportRequest = (data, files) => async (dispatch) => {
  // debugger;
  const isPreviewMode = localStorage.getItem("preview-mode");
  if (isPreviewMode) {
    return new Promise((resolve, reject) => {
      setTimeout(() => resolve("success"), 1000);
    });
  } else {
    return Api.multipart(API_SUPPORT_REQUEST_SAVE, data, files).then((res) => {
      dispatch(fetchSuccess(ACTION_SAVE_SUPPORT_REQUEST, res.data));
      return res;
    });
  }
};
