/* eslint-disable max-len */
/* eslint-disable no-plusplus */
/* eslint-disable no-restricted-syntax */
/* eslint-disable guard-for-in */
/* eslint-disable no-undef */
/* eslint-disable no-console */
import {
  KEY_TOKEN,
  KEY_ADMIN_TOKEN,
  KEY_USER_TOKEN,
} from "../utils/preferences";
import { PRINT } from "../utils";
function getAuthToken() {
  function getTokenOnly(lKey) {
    var info = localStorage.getItem(lKey);
    info = info ? JSON.parse(info) : "";
    return info?.token;
  }
  var pathname = window.location?.pathname;
  var tokenF = "";
  if (pathname.startsWith("/shop/")) {
    var shopUser = localStorage.getItem("shop-user");
    shopUser = shopUser ? JSON.parse(shopUser) : {};
    tokenF = shopUser?.auth?.token;
  } else if (pathname.startsWith("/salon/")) {
    var salonUser = localStorage.getItem("salon-user");
    salonUser = salonUser ? JSON.parse(salonUser) : {};
    tokenF = salonUser?.auth?.token;
  } else if (pathname.startsWith("/admin/")) {
    var venueAdmin = getTokenOnly(KEY_ADMIN_TOKEN);
    tokenF = venueAdmin;
  } else if (pathname.startsWith("/venue/")) {
    var venueUser = getTokenOnly(KEY_TOKEN);
    tokenF = venueUser;
  } else {
    var venueAppUser = getTokenOnly(KEY_USER_TOKEN);
    tokenF = venueAppUser;
  }
  if (!tokenF) {
    var keys = [KEY_ADMIN_TOKEN, KEY_TOKEN, KEY_USER_TOKEN];
    var foundKey = keys?.find((key) => getTokenOnly(key));
    tokenF = getTokenOnly(foundKey);
  }
  return tokenF;
}
class Api {
  static async headers() {
    const token = getAuthToken();
    if (token != null) {
      return {
        Accept: "application/json",
        "Content-Type": "application/json",
        "X-Requested-With": "XMLHttpRequest",
        "access-token": `${token}`,
      };
    }
    return {
      Accept: "application/json",
      "Content-Type": "application/json",
      "X-Requested-With": "XMLHttpRequest",
    };
  }

  static get(route) {
    return this.xhr(route, null, "GET");
  }

  static put(route, params, signal) {
    return this.xhr(route, params, "PUT", signal);
  }

  static post(route, params, signal = false) {
    return this.xhr(route, params, "POST", signal);
  }

  static delete(route, params) {
    return this.xhr(route, params, "DELETE");
  }

  static async xhr(route, params, verb, signal) {
    const url = `${route}`;
    PRINT("API", url);
    PRINT("PARAMS", params);
    const options = Object.assign(
      { method: verb },
      params ? { body: JSON.stringify(params) } : null
    );
    options.headers = await Api.headers();
    options.mode = "cors";
    if (signal) {
      options.signal = signal;
    }
    PRINT("HEADERS", options.headers);
    // console.log({ options }, "req options", url, "<=== url");
    return fetch(url, options).then((resp) => {
      const json = resp.json();
      if (resp.ok) {
        PRINT("RESPOSNE", json);
        return json;
      }
      return json.then((err) => {
        PRINT("ERROR", err);
        throw err;
      });
    });
  }

  static async multipart(route, params, files, method = "POST") {
    const url = `${route}`;
    PRINT("API", url);
    PRINT("PARAMS", params);
    const options = Object.assign({ method });
    const token = getAuthToken();
    if (token != null) {
      options.headers = {
        "access-token": `${token}`,
      };
    }
    const formData = new FormData();
    if (params != null) {
      for (const key in params) {
        PRINT("KEY", key);
        PRINT("VALUE", params[key]);
        formData.append(key, params[key]);
      }
    }
    if (files != null && files.length > 0) {
      PRINT("VALUE", files);
      for (let index = 0; index < files.length; index++) {
        const file = files[index];
        formData.append(file.name, file.value);
      }
    }
    options.body = formData;
    PRINT("HEADERS", options.headers);
    return fetch(url, options).then((resp) => {
      const json = resp.json();
      if (resp.ok) {
        PRINT("RESPOSNE", json);
        return json;
      }
      return json.then((err) => {
        PRINT("ERROR", err);
        throw err;
      });
    });
  }
}
export default Api;
