import Api from '../../../api';
import { fetchSuccess, loading } from '../../../utils/action.util';
import { API_NEW_PASSWORD } from '../../../api/constants';

export const NEWPASSWORD_LOADING = 'NEWPASSWORD_LOADING';
export const ACTION_NEWPASSWORD_SUCCESS = 'ACTION_NEWPASSWORD_SUCCESS';

export function actionNewPassword(params) {
    return (dispatch, getState) => Api.post(API_NEW_PASSWORD, params).then((res) => {
        const data = res;
        dispatch(fetchSuccess(ACTION_NEWPASSWORD_SUCCESS, { data }));
    }).catch((reason) => {
        /** Handle Error */
        var data = {
            message: ''
        }
        dispatch(loading(NEWPASSWORD_LOADING, { loading: false, data }));
    });
}
