import getUserLocale from "get-user-locale";
let _userLocale = getUserLocale();
_userLocale = _userLocale.split("-")?.[0];
export const userLocale = _userLocale;
console.log(userLocale, "userLocale");
let BASE_URL = process.env.REACT_APP_BASE_URL;
export const IMAGE_PATH = process.env.REACT_APP_S3_IMAGE_BASE_URL;

export const DOMAIN_URL = process.env.REACT_APP_DOMAIN_URL;

// =============================================== / ADMIN APIS URLS / ==================================================

export const API_GET_ADMIN_LANGUAGE = `${BASE_URL}/admin-${userLocale}.json`;
export const API_GET_ADMIN_LANGUAGE_WITHOUT_LOCALE = `${BASE_URL}/admin-en.json`;
const PREFIX_ADMIN = "/api/admin";
export const ADMIN_PROFILE_BY_TOKEN = `${BASE_URL}/api/admin/my-profile`;
// staff/admin auth
export const API_ADMINLOGIN = `${BASE_URL}${PREFIX_ADMIN}/login`;
export const API_FORGOT_PASSWORD = "";

// admin/staff managemnet
export const GENERIC_FILE_UPLOAD = `${BASE_URL}${PREFIX_ADMIN}/images/add`;

export const API_CREATE_STAFF = `${BASE_URL}${PREFIX_ADMIN}/create-admin-user`;
export const API_DELETE_STAFF = `${BASE_URL}${PREFIX_ADMIN}/delete/`;
export const API_GET_ALL_SCREENS = `${BASE_URL}/api/screens/all`;
export const API_UPDATE_GROUPS_SCREENS = `${BASE_URL}/api/groups/update-groups-screens`;
export const API_GET_ALL_STAFF = `${BASE_URL}${PREFIX_ADMIN}/all-admin-users`;
export const API_UPDATE_PASSWORD_STAFF = "";
export const API_UPDATE_STAFF = `${BASE_URL}${PREFIX_ADMIN}/update-admin-user`;
export const API_CREAT_AUTH_SCREENS = "";

// admin authorization groups
export const API_ADDSTAFFAUTHORIZATIONGROUP = `${BASE_URL}${PREFIX_ADMIN}/staff/create`;
export const API_DELETESTAFFAUTHORIZATIONGROUP = `${BASE_URL}${PREFIX_ADMIN}/staff/delete/`;
export const API_EDITSTAFFAUTHORIZATIONGROUP = `${BASE_URL}${PREFIX_ADMIN}/staff/update/`;
export const API_STAFFAUTHORIZATIONGROUPDETAILS = "";
export const API_GET_AUTH_GROUPS = `${BASE_URL}/api/groups/all-groups-with-screens`;
export const API_GET_AUTH_GROUPS_WITH_SCREENS = `${BASE_URL}/api/groups/all-groups-with-screens`;
export const API_GET_SCREEN_AUTHORIZATIONS = "";
export const API_STAFFAUTHORIZATIONGROUPLIST = `${BASE_URL}${PREFIX_ADMIN}/staff/get`;

// users managment

export const API_CONFIRM_DELETE_USER = `${BASE_URL}/api/user/delete-user-reservations`;
export const API_CREATE_USER = `${BASE_URL}/api/user/create-user`;
export const API_DELETE_UPDATE_USER = `${BASE_URL}/api/user/delete`;
export const API_GET_USERS = `${BASE_URL}/api/user/allusers`;
export const API_GET_USER_FORM_CONFIG = "";
export const API_UPDATE_USER = `${BASE_URL}/api/user/update-user`;
export const API_UPDATE_USER_FORM_CONFIG = "";
export const API_UPDATE_USER_FORM_FIELDS_SORT = "";

// object management
export const API_CREATE_OBJECT = `${BASE_URL}${PREFIX_ADMIN}/object/create-object`;
export const API_GET_OBJECTS = `${BASE_URL}${PREFIX_ADMIN}/object/all-objects`;
export const API_DELETE_OBJECTS = `${BASE_URL}${PREFIX_ADMIN}/object/delete`;
export const API_UPDATE_OBJECTS = `${BASE_URL}${PREFIX_ADMIN}/object/update-object/`;
export const API_UPDATE_STATUS = `${BASE_URL}${PREFIX_ADMIN}/object/update-status/`;
export const API_ADDONSERVICE_STATUS = `${BASE_URL}${PREFIX_ADMIN}/object/check-add-on-service-status`;
export const API_DELETEADDONSERVICE_OBJECTS = `${BASE_URL}${PREFIX_ADMIN}/object/delete-add-on-service`;

// service request overview
export const API_GETSERVICEREQUESTBYDATES = `${BASE_URL}${PREFIX_ADMIN}/service-request/get-service-request-by-id`;
export const API_UPDATEINTERNALNOTE = `${BASE_URL}${PREFIX_ADMIN}/service-request/update-internal-note/`;
export const API_UPDATESERVICEREQUESTSTATUSFROMADMIN = `${BASE_URL}${PREFIX_ADMIN}/service-request/update-status/`;
export const API_GETAllSERVICEREQUEST = `${BASE_URL}${PREFIX_ADMIN}/service-request/get-all-service-request`;

//refund
export const API_GETREFUNDLIST = `${BASE_URL}${PREFIX_ADMIN}/refund/find-refund-info`;
export const API_REFUNDTOSUER = `${BASE_URL}/api/user/reservation/refund-to-user`;
export const API_DELTEREFUNDRECORD = `${BASE_URL}/api/user/reservation/delete-refund-record`;
export const API_GETMOLLIEBALANCE = `${BASE_URL}/api/user/payment/getBalance`;

// service request cms
export const API_CREATESERVICEREQUESTINFO = `${BASE_URL}${PREFIX_ADMIN}/service-request-information/create`;
export const API_UPDATEESERVICEREQUESTINFO = `${BASE_URL}${PREFIX_ADMIN}/service-request-information/update/`;
export const API_GETSERVICEREQUESTINFO = `${BASE_URL}${PREFIX_ADMIN}/service-request-information/all`;
export const API_GETIMAGEFILEREAD = `${BASE_URL}${PREFIX_ADMIN}/service-request-information/readimage`;

export const API_GETSERVICEREQUESTSTATUSES = `${BASE_URL}${PREFIX_ADMIN}/service-request-status/all`;
export const API_GETSERVICEREQUESTSTATUSESREQUIRED = `${BASE_URL}${PREFIX_ADMIN}/service-request-status/allrequired`;

export const API_CREATESERVICEREQUESTSTATUSES = `${BASE_URL}${PREFIX_ADMIN}/service-request-status/create`;
export const API_UPDATESERVICEREQUESTSTATUSES = `${BASE_URL}${PREFIX_ADMIN}/service-request-status/update/`;
export const API_UPDATESERVICEREQUESTSTATUS = `${BASE_URL}${PREFIX_ADMIN}/service-request-status/update-status/`;
export const API_DELETESERVICEREQUESTSTATUS = `${BASE_URL}${PREFIX_ADMIN}/service-request-status/delete/`;

export const API_GETSERVICETABLECONFIG = `${BASE_URL}${PREFIX_ADMIN}/service-request-table-config/allServiceTable`;
export const API_UPDATESERVICETABLECONFIG = `${BASE_URL}${PREFIX_ADMIN}/service-request-table-config/update`;
export const API_SORTSERVICETABLECONFIG = `${BASE_URL}${PREFIX_ADMIN}/service-request-table-config/sort`;
export const API_GETSERVICETABLECONFIGREQUIRED = `${BASE_URL}${PREFIX_ADMIN}/service-request-table-config/allServiceTableRequired`;

//housekeeping planning listvieww
export const API_GETHOUSEKEEPING = `${BASE_URL}${PREFIX_ADMIN}/housekeeping/find-housekeeplanning`;
export const API_UPDATE_HOUSEKEEPINGINVOICE = `${BASE_URL}${PREFIX_ADMIN}/housekeeping/update-housekeeplanning/`;
export const API_GETHOUSEKEEPINGCALENDARINFO = `${BASE_URL}${PREFIX_ADMIN}/housekeeping/find-housekeeplanningCalendarInfo`;
export const API_GETHOUSEKEEPINGFORRESERVATION = `${BASE_URL}${PREFIX_ADMIN}/housekeeping/find-housekeeping-for-reservation`;
export const API_GETNONHOUSEKEEPINGFORRESERVATION = `${BASE_URL}${PREFIX_ADMIN}/housekeeping/find-non-housekeeping-for-reservation`;

//reservation management
export const API_GET_RESERVATIONCALENDAR = `${BASE_URL}/api/user/reservation/all-reservation-calendar`;
export const API_GET_SUITEPRICE = `${BASE_URL}/api/user/reservation/get-suite-price`;
export const API_GET_SUITEPRICEBYDATES = `${BASE_URL}/api/user/reservation/get-suite-price-by-dates`;
export const API_CHECK_OBJECTUNAVAILABLITY = `${BASE_URL}/api/user/reservation/check-object-unavailabilty`;

//object settings
export const API_CREATE_OBJECTSETTING = `${BASE_URL}${PREFIX_ADMIN}/object-setting/create-object-setting`;
export const API_GET_OBJECTSETTING = `${BASE_URL}${PREFIX_ADMIN}/object-setting/all`;
export const API_UPDATE_OBJECTSETTING = `${BASE_URL}${PREFIX_ADMIN}/object-setting/update-object-setting`;

//web app look  & feel
export const API_CREATE_LOOK_FEEL = `${BASE_URL}${PREFIX_ADMIN}/web-look-feel/create`;
export const API_UPDATE_LOOK_FEEL = `${BASE_URL}${PREFIX_ADMIN}/web-look-feel/update`;
export const API_GET_LOOK_FEEL = `${BASE_URL}${PREFIX_ADMIN}/web-look-feel/all`;

//web app cms
export const API_CREATE_WEBAPPCMS = `${BASE_URL}${PREFIX_ADMIN}/web-app-cms/create`;
export const API_UPDATE_WEBAPPCMS = `${BASE_URL}${PREFIX_ADMIN}/web-app-cms/update`;
export const API_GET_WEBAPPCMS = `${BASE_URL}${PREFIX_ADMIN}/web-app-cms/all`;

//web app cms
export const API_CREATE_RESERVATIONCMS = `${BASE_URL}${PREFIX_ADMIN}/reservation-cms/create`;
export const API_UPDATE_RESERVATIONCMS = `${BASE_URL}${PREFIX_ADMIN}/reservation-cms/update`;
export const API_GET_RESERVATIONCMS = `${BASE_URL}${PREFIX_ADMIN}/reservation-cms/all`;

//reservation cms
export const API_CREATE_TERMSCONDITION = `${BASE_URL}${PREFIX_ADMIN}/terms-and-condition/create`;
export const API_UPDATE_TERMSCONDITION = `${BASE_URL}${PREFIX_ADMIN}/terms-and-condition/update`;
export const API_GET_TERMSCONDITION = `${BASE_URL}${PREFIX_ADMIN}/terms-and-condition/all`;

//privacy statement
export const API_CREATE_PRIVACYSTATEMENT = `${BASE_URL}${PREFIX_ADMIN}/privacy-statement/create`;
export const API_UPDATE_PRIVACYSTATEMENT = `${BASE_URL}${PREFIX_ADMIN}/privacy-statement/update`;
export const API_GET_PRIVACYSTATEMENT    = `${BASE_URL}${PREFIX_ADMIN}/privacy-statement/all`;

//web app reservation screens
export const API_GET_RESERVATION_INFO = `${BASE_URL}/api/user/reservation/find-reservations`;
export const API_GET_ALLADDONSERVICES = `${BASE_URL}/api/user/reservation/get-add-on-service`;

export const API_UPDATE_RESERVATION_NOTE = `${BASE_URL}/api/user/reservation/update-reservation/`;
export const API_CANCEL_RESERVATION = `${BASE_URL}/api/user/reservation/cancel-status/`;
export const API_CHECK_CANCEL_RESERVATION = `${BASE_URL}/api/user/reservation/check-reservation-for-cancel-status`;

//admin app shortend reservation status
export const API_CHECK_SHORTEND_RESERVATION = `${BASE_URL}/api/user/reservation/check-reservation-for-shortend`;

export const API_UPDATESUITE = `${BASE_URL}/api/user/reservation/change-suite`;

//web app apis url

export const API_USERLOGIN = `${BASE_URL}/api/user/login`;
export const API_USER_SETPASSWORD = `${BASE_URL}/api/user/set-password`;
export const API_USER_UPDATEPASSWORD = `${BASE_URL}/api/user/update-password/`;
export const API_CHECKUSERNAME = `${BASE_URL}/api/user/check-username`;
export const API_CHECKUSERNAMEEXISTS = `${BASE_URL}/api/user/check-user-exists`;

export const API_GETCMSSTYLES = `${BASE_URL}${PREFIX_ADMIN}/web-app-cms/all`;
export const API_GET_ADD_ON_SERVICE = `${BASE_URL}${PREFIX_ADMIN}/object/all-add-on-service`;
export const API_ADDRESERVATION = `${BASE_URL}/api/user/reservation/create-reservation`;
export const API_GETRESERVATIONBYRESNUM = `${BASE_URL}/api/user/reservation/get-reservation-by-reservation-number/`;

export const API_GET_OBJECTSETTING_APP = `${BASE_URL}${PREFIX_ADMIN}/object-setting/all`;
export const API_GET_OBJECTS_APP = `${BASE_URL}${PREFIX_ADMIN}/object/all-objects`;
export const API_GET_CALENDAR_INFO = `${BASE_URL}/api/user/reservation/all-calendar-info`;
export const API_GETRESERVATION_BY_ID = `${BASE_URL}/api/user/reservation/get-reservation-by-id/`;
export const API_FILTERRESERVATION_BY_ID = `${BASE_URL}/api/user/reservation/filter-reservation/`;
export const API_UPDATERESERVATION_BY_ID = `${BASE_URL}/api/user/reservation/update-reservation-by-id/`;
export const API_GETADDONSERVICE_BY_ID = `${BASE_URL}${PREFIX_ADMIN}/object/get-add-on-service-by-objectID/`;
export const API_ADDSERVICEREQUEST = `${BASE_URL}/api/user/service-request/create`;
export const API_GETALLRESERVATION_BY_ID = `${BASE_URL}/api/user/reservation/get-all-reservation-by-id/`;
export const API_GETSERVICEREQUEST = `${BASE_URL}/api/user/service-request/get-service-request-by-id/`;
export const API_GETPAYMENTSTATUS = `${BASE_URL}/api/user/payment/getpaymentstatus`;

// =============================================== / APP APIS URLS / ==================================================

export const API_APP_GET_RESERVATION_INFO = "";
// export const API_GET_APP_LANGUAGE = `${BASE_URL}/app-${userLocale}.json`;
export const API_GET_APP_LANGUAGE = `${BASE_URL}/app-${
  ["nl", "en"].includes(userLocale) ? userLocale : "en"}.json`;
export const API_GET_APP_LANGUAGE_WITHOUT_LOCALE = `${BASE_URL}/app-en.json`;
export const API_GET_RESERVATION_REQUEST_HISTOR = "";
export const API_GET_RESERVATION_REQUEST_HISTORY = "";
export const API_GET_SERVICE_CATEGORY_REQUEST = "";
export const API_GET_SERVICE_REQUEST_HISTORY = "";
export const API_GET_WALK_IN_REGISTRATION = "";
export const API_NEW_PASSWORD = "";
export const API_SUPPORT_INFO_GET = "";
export const API_SUPPORT_INFO_GET_PREVIEW = "";

export const API_SUPPORT_REQUEST_SAVE = "";
export const API_TODAYS_NOTIFICATION = "";
export const API_VENUEAPPLOGIN = "";
export const API_VENUE_APP_RENTAL_GROUPS = "";
export const API_VENUE_APP_RENTAL_GROUPS_PREVIEW = "";

export const API_VENUE_CREATE_RESERVATION = "";
export const API_VENUE_DETAILS_BY_VENUE_CODE = "";
export const API_VENUE_GET_COVID_QUESTIONS = "";
export const API_VENUE_GET_RESERVATION_LOCATIONS = "";
export const API_VENUE_HOME_DETAILS = "";
export const API_VENUE_HOME_DETAILS_PREVIEW = "";
