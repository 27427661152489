/* eslint-disable no-script-url */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Component } from "react";
import { clearData, getData, KEY_USER } from "../../utils/preferences";
import { toggleSideNav, setSideNavToggle } from "../../utils/side-nav";
import { connect } from "react-redux";
import $ from "jquery";
import { setCSSVars } from "../../utils";
export const setMESTEditorPositions = () => {
  var newsTypeSelectbox = document.getElementById("news_type_select");
  if (
    newsTypeSelectbox?.innerHTML &&
    newsTypeSelectbox.getBoundingClientRect()?.y
  ) {
    const {
      y: TopPoint,
      x: LeftPoint,
      width,
    } = newsTypeSelectbox.getBoundingClientRect();
    setCSSVars({
      "--MESTEditorToolbarTopPosition": `${TopPoint}px`,
      "--MESTEditorToolbarLeftPosition": `${LeftPoint + width + 37}px`,
      "--MESTEditorToolbarWidth": `${
        window.innerWidth - LeftPoint - width - 90
      }px`,
    });
    setMESTEditorToolbarHeightVar();
  }
};
export const setMESTEditorToolbarHeightVar = () => {
  setCSSVars({
    "--MESTEditorToolbarHeight": `${
      document.getElementsByClassName(
        "multi-editor-single-toolbar-visible"
      )?.[0]?.clientHeight + 20 || 0
    }px`,
  });
};
class Header extends Component {
  constructor(props) {
    super(props);
    this.state = {
      user: null,
      pausedUntilTime: null,
      showPause: false,
      lang: {},
    };
  }
  async componentDidMount() {
    const { match } = this.props;
    var path = String(window.location.host).startsWith("suitesadmin")
      ? "/admin"
      : match.url;
    if (path === "/") {
      setCSSVars({ "--body-color": "white" });

      const { lang } = this.props;
      this.setState({ lang });
      toggleSideNav();
      const user = JSON.parse(localStorage.getItem("user_id"));
      this.setState({ user });
    } else {
      if (this.props.langR) {
        const lang = this.props.langR;
        this.setState({ lang });
      }
      toggleSideNav();
      const user = JSON.parse(localStorage.getItem("user"));
      this.setState({ user });
    }
    let resizeObserver = new ResizeObserver(() => {
      this.setActionsPosition();
      setMESTEditorPositions();
    });
    if (document.getElementById("content-wrapper")) {
      resizeObserver.observe(document.getElementById("content-wrapper"));
    }
    this.setActionsPosition();
  }
  setActionsPosition = () => {
    let itemNo = 3;
    if (window.innerWidth <= 1280) {
      itemNo = 2;
    }
    var offset = $(`#take-away-item-${itemNo}`).offset();
    $(".snackbarActions").addClass("invisible");
    if (offset?.left) {
      $(".snackbarActions")
        .css({ left: `${offset?.left}px` })
        .promise()
        .done(function () {
          $(".snackbarActions").removeClass("invisible");
        });
    }
  };
  componentDidUpdate(prevProps) {
    if (prevProps.langR !== this.props.langR) {
      const lang = this.props.langR;
      this.setState({ lang });
    }
    if (prevProps.langA !== this.props.langA) {
      const lang = this.props.langA;
      this.setState({ lang });
    }
    if (prevProps.langS !== this.props.langS) {
      const lang = this.props.langS;
      this.setState({ lang });
    }
    if (prevProps.langSH !== this.props.langSH) {
      const lang = this.props.langSH;
      this.setState({ lang });
    }
  }
  UNSAFE_componentWillReceiveProps() {
    toggleSideNav();
  }
  async logout(path) {
    path = String(window.location.host).startsWith("suitesadmin")
      ? "/admin"
      : path;
    await clearData(path);
    switch (path) {
      case "/admin":
        window.location.href = "/admin/login";
        break;
      case "/":
        window.location.href = "/";
        break;

      default:
        break;
    }
  }
  getPortalTitle = () => {
    const { lang, match } = this.props;
    var path = String(window.location.host).startsWith("suitesadmin")
      ? "/admin"
      : match.url;
    switch (path) {
      case "/admin":
        return lang?.AdminPortal;
      case "/":
      default:
        return lang?.CMSPortal;
    }
  };

  render() {
    const { user, lang } = this.state;
    const { match } = this.props;
    var path = String(window.location.host).startsWith("suitesadmin")
      ? "/admin"
      : match.url;

    //console.log(user)
    return (
      <>
        <nav
          className="header-navbar navbar-expand-md navbar navbar-with-menu fixed-top navbar-semi-dark navbar-shadow"
          style={
            match.url === "/admin" ||
            String(window.location.host).startsWith("suitesadmin")
              ? null
              : { minHeight: "45px", maxHeight: "45px" }
          }
          // style={match.url === "/" ? { backgroundColor: "#d45000" } : null}
        >
          <div className="navbar-wrapper">
            {path !== "/admin" ? null : (
              <div className="navbar-header">
                <ul className="nav navbar-nav flex-row">
                  <li className="nav-item mobile-menu d-sm-none mr-auto">
                    <a
                      className="nav-link nav-menu-main menu-toggle hidden-xs"
                      href
                      onClick={() => {
                        setSideNavToggle(path);
                      }}
                    >
                      <i className="ft-menu font-large-1"></i>
                    </a>
                  </li>
                  <li className="nav-item">
                    <a className="navbar-brand" href="javascript:void(0)">
                      <h2 className="brand-text pl-0">
                        {this.getPortalTitle()}
                      </h2>
                    </a>
                  </li>
                  <li className="nav-item d-sm-none">
                    <a
                      className="nav-link open-navbar-container"
                      data-toggle="collapse"
                      data-target="#navbar-mobile"
                    >
                      <i className="fa fa-ellipsis-v"></i>
                    </a>
                  </li>
                </ul>
              </div>
            )}
            <div className="navbar-container content">
              <div
                className={`navbar-collapse ${
                  (match.url === "/admin" ||
                    String(window.location.host).startsWith("suitesadmin")) &&
                  "d-none"
                } d-sm-flex justify-content-between`}
                id="navbar-mobile"
                style={{ display: "flex" }}
              >
                <ul
                  className="nav navbar-nav"
                  style={{
                    right: "auto !important",
                    left: "0",
                  }}
                >
                  <li
                    className={`nav-item ${
                      String(window.location.host).startsWith("suitesadmin") &&
                      "d-none"
                    } d-sm-block`}
                  >
                    <a
                      className="nav-link nav-menu-main menu-toggle hidden-xs"
                      href
                      onClick={() => {
                        setSideNavToggle(path);
                      }}
                    >
                      <i className="ft-menu"></i>
                    </a>
                  </li>
                </ul>
                {path === "/admin" && (
                  <ul className="nav navbar-nav">
                    <li className="dropdown dropdown-user nav-item ">
                      <a
                        className="dropdown-toggle nav-link dropdown-user-link"
                        href="#"
                        data-toggle="dropdown"
                      >
                        <span className="user-name">
                          {user != null ? user.staff_name : ""}
                        </span>
                      </a>
                      <div className="dropdown-menu dropdown-menu-right">
                        <div className="dropdown-divider" hidden></div>
                        <a
                          className="dropdown-item"
                          href="javascript:void(0)"
                          onClick={() => this.logout(path)}
                        >
                          <i className="ft-power"></i> {lang?.Logout}
                        </a>
                      </div>
                    </li>
                  </ul>
                )}
              </div>
            </div>
          </div>
        </nav>
      </>
    );
  }
}
const mapStateToProps = ({ adminReducer, VenueAppReducer }) => ({
  langA: adminReducer.lang,
  langS: VenueAppReducer.lang,
});

export default connect(mapStateToProps, {})(Header);
