import {
    ACTION_CHECKADDONSERVICE_SUCCESS
    } from './action';
    import createReducer from '../../../../reducers/createReducer';
    
    const INITIAL_STATE = {
            data: [],
            loading: false
        };
    
    const AddOnServiceReducer = createReducer(
        INITIAL_STATE,
        {
         [ACTION_CHECKADDONSERVICE_SUCCESS](state, action) {
            return {
              ...state,
              data: action.payload.data,
              tag: ACTION_CHECKADDONSERVICE_SUCCESS,
              loading: false
            };
          },
        }
    );
    export default AddOnServiceReducer;