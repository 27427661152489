import React, { Component } from "react";
import Button from "../ui/button";
import $ from "jquery";

export default class Header extends Component {
  componentDidMount() {
    document.getElementById("content-wrapper").style.paddingTop = "0px";
    const headerWidth = $("#header-title").width();

    var styles = {
      "--header-width": headerWidth + "px",
    };
    Object.keys(styles).map((k) => {
      var value = styles[k];
      return document.documentElement.style.setProperty(k, value);
    });
  }

  componentDidUpdate() {
    const headerWidth = $("#header-title").width();

    var styles = {
      "--header-width": headerWidth + "px",
    };
    Object.keys(styles).map((k) => {
      var value = styles[k];
      return document.documentElement.style.setProperty(k, value);
    });
  }
  componentWillUnmount() {
    document
      .getElementById("content-wrapper")
      .style.removeProperty("padding-top");
  }
  render() {
    const { title, children } = this.props;
    return (
      <div className={`content-header align-middle pl-2`}>
        {title ? (
          <div className={`title ${children ? "" : "py-1"}`}>
            <h3 className="content-header-title d-flex">
              {" "}
              <span id="header-title">{title || ""}</span>
            </h3>
          </div>
        ) : null}
        {children && (
          <div className={`actions mr-2 ${this.props?.cssClasses || "py-1"}`}>
            {children}
          </div>
        )}
        {!children && (
          <div
            className={`actions d-none d-md-block  mr-2 py-1 invisible ${this.props?.cssClasses}`}
          >
            {" "}
            <Button title={"dummy"} handleRedirect={""} />{" "}
          </div>
        )}
      </div>
    );
  }
}
