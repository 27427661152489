import React,{ Component } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { actionForgotPassword, ACTION_FORGOTPASSWORD_SUCCESS } from "./action";
import $ from 'jquery';
import validator from 'validator';
import swal from "sweetalert";

var parent = null;

class ForgotPassword extends Component {

	constructor(props){
		super(props);
		parent = this;
		this.state = {
			username: ''
		};
	}

	async componentWillReceiveProps(nextProps) {
		const { ForgotPassword } = nextProps;

		if (ForgotPassword != null) {
			var ForgotPasswordResponse = Object.assign({}, ForgotPassword);
			if (ForgotPasswordResponse.result === true) {
				swal({
					title:'Confirmation Email Sent',
					text: 'Please check your registered email for reset password link',
					icon: 'success'
				}).then ( success => {
					parent.props.history.goBack()
				})
			} else {
				swal({
					title:'Message',
					text: ForgotPasswordResponse.message,
					icon: 'error'
				})
			}
		}
	}

	handleFormFields = (event) => {
		event.preventDefault();
		var { username } = this.state;
		switch (event.target.id) {
			case 'username':
				username = event.target.value
				break;
			default:
		}
		parent.handleValidation()
		parent.setState({
			username
		})
	  };

	handleValidation() {
		const { username } = this.state;

		var status = true;
		$("#fg-username").removeClass("error");

		if (username.length === 0) {
			$("#fg-username").addClass("error");
			status = false;
		}
		if (!validator.isEmail(username)) {
			$("#fg-username").addClass("error");
			status = false;
		}
		return status;
	}

	async handleSubmit(event) {
		event.preventDefault();
		const {username} = parent.state
		if(parent.handleValidation()) {
			await parent.props.actionForgotPassword({
				username
			})
		}
	}

	render() {
		const {username} = this.state;
		return (
			<section className="flexbox-container mt-5">
				<div className="col-12 d-flex align-items-center justify-content-center">
				<div className="col-lg-4 col-md-8 col-10 box-shadow-2 p-0">
					<div className="card border-grey border-lighten-3 px-1 py-1 m-0">
					<div className="card-header border-0"></div>
					<div className="card-content">
						<p className="card-subtitle text-muted text-center font-large-1 mx-2 mb-2 primary">
						<span>Admin Forgot Password</span>
						</p>
						<div className="card-body">
						<form
							className="form-horizontal"
							novalidate
						>
							<fieldset className="form-group position-relative has-icon-left">
								<div className="form-group" id="fg-username">
									<input
										type="text"
										className="form-control"
										id="username"
										name="username"
										placeholder="Enter registered admin username"
										value={username}
										onChange={this.handleFormFields}
										required
									/>
									<div className="form-control-position">
										<i className="ft-user"></i>
									</div>
								</div>
							</fieldset>
							<button
							type="submit"
							className="btn btn-outline-primary btn-block"
							onClick={this.handleSubmit}
							>
							<i className="ft-unlock"></i> Submit
							</button>
						</form>
						</div>
					</div>
					</div>
				</div>
				</div>
			</section>
		)
	}
}

function mapStateToProps(state) {

	var ForgotPassword = null;
	if (state.ForgotPasswordReducer != null && Object.keys(state.ForgotPasswordReducer).length > 0 && state.ForgotPasswordReducer.constructor === Object) {
		switch(state.ForgotPasswordReducer.tag) {
			case ACTION_FORGOTPASSWORD_SUCCESS:
				ForgotPassword = Object.assign({}, state.ForgotPasswordReducer.data);
				delete state.ForgotPasswordReducer;
				return { ForgotPassword };
			default:
				return { ForgotPassword };
		}
	}
}

function mapDispatchToProps(dispatch) {
	return bindActionCreators({actionForgotPassword},dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(ForgotPassword);