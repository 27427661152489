import {
  ACTION_SEARCHRESERVATION_SUCCESS,
  ACTION_UPDATENOTE_SUCCESS,
  ACTION_CANCELSTATUS__SUCCESS,
} from "./action";
import createReducer from "../../../reducers/createReducer";

const INITIAL_STATE = {
  data: [],
  data2: [],
  data3: [],
  loading: false,
};

const ReserationDetailsReducer = createReducer(INITIAL_STATE, {
  [ACTION_SEARCHRESERVATION_SUCCESS](state, action) {
    return Object.assign(
      {},
      {
        ...state,
        data: action.payload.data,
        tag: ACTION_SEARCHRESERVATION_SUCCESS,
        loading: false,
      }
    );
  },
  [ACTION_UPDATENOTE_SUCCESS](state, action) {
    return Object.assign(
      {},
      {
        ...state,
        data2: action.payload.data,
        tag: ACTION_UPDATENOTE_SUCCESS,
        loading: false,
      }
    );
  },
  [ACTION_CANCELSTATUS__SUCCESS](state, action) {
    return Object.assign(
      {},
      {
        ...state,
        data3: action.payload.data,
        tag: ACTION_CANCELSTATUS__SUCCESS,
        loading: false,
      }
    );
  },
});
export default ReserationDetailsReducer;
