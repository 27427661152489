import createReducer from "../../../reducers/createReducer";
import {
  ACTION_GET_AUTH_GROUPS,
  ACTION_GET_STAFF,
  ACTION_CREATE_STAFF,
  ACTION_UPDATE_STAFF,
  ACTION_GET_ALL_SCREENS,
  ACTION_GET_SCREEN_AUTHORIZATIONS,
  ACTION_GET_AUTH_GROUPS_WITH_SCREENS_SUCCESS,
} from "./actions";

const INITIAL_STATE = {
  authGroups: [],
  staffUsers: [],
  allScreens: [],
  assignedScreenAuthorizations: [],
  AuthenticatioGroups: [],
  allScreensWithGroups: [],
};

const userManagementReducer = createReducer(INITIAL_STATE, {
  [ACTION_GET_AUTH_GROUPS](state, action) {
    // console.log("test", action.payload);
    return {
      ...state,
      AuthenticatioGroups: action.payload,
    };
  },
  [ACTION_GET_STAFF](state, action) {
    return {
      ...state,
      staffUsers: action.payload,
      authGroups: action.payload?.screenGroup,
    };
  },
  [ACTION_CREATE_STAFF](state, action) {
    return {
      ...state,
    };
  },
  [ACTION_UPDATE_STAFF](state, action) {
    return {
      ...state,
    };
  },
  [ACTION_GET_ALL_SCREENS](state, action) {
    return {
      ...state,
      allScreens: action.payload?.screens || [],
      authGroups: action.payload?.groups || [],
    };
  },
  [ACTION_GET_SCREEN_AUTHORIZATIONS](state, action) {
    const screenAuthorizations = action.payload.screenGroup.map((group) => {
      const screenIds = group.SCREENs.map((screen) => screen.ID);
      return {
        groupId: group.ID,
        screenIds,
      };
    });

    const allScreens = action.payload.screens.map((screen) => screen.Screens);

    return {
      ...state,
      assignedScreenAuthorizations: screenAuthorizations,
      allScreens,
    };
  },
  [ACTION_GET_AUTH_GROUPS_WITH_SCREENS_SUCCESS](state, action) {
    return { ...state, allScreensWithGroups: action?.payload || [] }
  }
});

export default userManagementReducer;
