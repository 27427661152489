import React, { Component } from "react";
import { ConnectedRouter } from "connected-react-router";
import Header from "../../components/header";
import SideBar from "../../components/sidebar";
import Content from "../../components/content";
import Footer from "../../components/footer";
import Login from "./login";
import { Route, Redirect } from "react-router-dom";

import { actionGetAdminLanguage, actionGetMyProfile } from "./action";
import { getData, KEY_USER } from "../../utils/preferences";
import ForgotPassword from "./forgot_password";
import NewPassword from "./new_password";
import { connect } from "react-redux";
import $ from "jquery";
import { getUrlByScreenId } from "../../utils/screens";

class AdminRoute extends Component {
  constructor(props) {
    super(props);
    this.state = {
      user: null,
    };
  }
  componentDidMount() {
    const res = this.props.actionGetMyProfile();
    this.props.actionGetAdminLanguage();
    this.setBtnWidths();
  }
  async componentWillMount() {
    var user = await getData(KEY_USER);
    if (user !== null) {
      var objUser = JSON.parse(user);
      if (objUser.staff_type === "Admin" || objUser.staff_type === "Staff") {
        this.setState({ user: objUser });
      }
    }
  }

  setBtnWidths = () => {
    const ac_wi = $("#btn-actions").width();
    var styles = {
      "--actions-width": ac_wi + "px",
    };
    Object.keys(styles).map((k) => {
      var value = styles[k];
      return document.documentElement.style.setProperty(k, value);
    });
  };

  render() {
    var { user } = this.state;
    var { props } = this;
    var { location } = props;
    var { lang } = props;
    const redirectToInitialScreenUponTheseURLS = [
      "/",
      "/admin",
      "/admin/",
      "/admin/login",
      "/admin/dashboard",
    ];

    const shouldRouteToInitial =
      (String(window.location.host).startsWith("suitesadmin")
        ? redirectToInitialScreenUponTheseURLS.includes(location.pathname)
        : redirectToInitialScreenUponTheseURLS.includes(location.pathname)) &&
      user !== null;

    if (
      (String(window.location.host).startsWith("suitesadmin") &&
        user === null) ||
      (location.pathname === "/admin/login" && user === null)
    ) {
      return (
        <ConnectedRouter history={this.props.history}>
          <Login {...props} />
        </ConnectedRouter>
      );
    } else if (location.pathname === "/admin/forgot-password") {
      return (
        <ConnectedRouter history={this.props.history}>
          <ForgotPassword {...props} />
        </ConnectedRouter>
      );
    } else if (location.pathname.includes("new-password")) {
      return (
        <div>
          <Route
            path={`${this.props.match.url}/new-password/:verify_id`}
            component={NewPassword}
          />
        </div>
      );
    } else if (shouldRouteToInitial) {
      console.log("frominside", location.pathname);

      const redirectTo =
        getUrlByScreenId(user.onLoadScreen) || "/admin/dashboard";
      return (
        <Redirect
          to={{
            pathname: redirectTo,
          }}
        />
      );
    } else {
      console.log(shouldRouteToInitial);

      console.log(location.pathname);
      //debugger;

      return (
        <ConnectedRouter history={this.props.history}>
          <Header {...props} />
          <SideBar {...props} />
          <Content {...props} />
          <Footer {...props} />
          <div id="btn-actions" className="invisible display-inline-block">
            <button type="button" class="btn btn-primary btn-sm round ml-1">
              {lang?.Edit}
            </button>
            <button type="button" class="btn btn-danger btn-sm round ml-1">
              {lang?.Inactivate}
            </button>
            <button
              type="button"
              class="btn btn-danger btn-delete btn-sm round ml-1"
            >
              {lang?.Delete}
            </button>
          </div>
        </ConnectedRouter>
      );
    }
  }
}

const mapStateToProps = ({ adminReducer }) => ({
  lang: adminReducer.lang,
});
export default connect(mapStateToProps, {
  actionGetAdminLanguage,
  actionGetMyProfile,
})(AdminRoute);
