/* eslint-disable react/jsx-no-target-blank */
/* eslint-disable no-script-url */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Component } from "react";

class Footer extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <footer
        style={{ marginTop: "-100px" }}
        className="footer footer-transparent"
      >
        {/* <p className="clearfix blue-grey lighten-2 text-sm-center mb-0 px-2">
					<span className="float-md-left d-block d-md-inline-block">
						Copyright &copy; 2020
						<a className="text-bold-800 grey darken-2" href="javascript:void(0)" target="_blank"> Venue Food Order</a></span></p> */}
      </footer>
    );
  }
}

export default Footer;
