import Api from "../../../api";
import { fetchSuccess, loading } from "../../../utils/action.util";
import { API_GETRESERVATIONBYRESNUM } from "../../../api/constants";

export function actionGetReservationByResNum(res_num) {
  return (dispatch, getState) =>
    Api.get(API_GETRESERVATIONBYRESNUM + res_num)
      .then((res) => {
        const data = res;
        return data;
      })
      .catch((reason) => {
        /** Handle Error */
        var data = {
          message: "",
        };
        return reason;
      });
}
