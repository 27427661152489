/* eslint-disable no-unused-vars */
/* eslint-disable no-script-url */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Component } from "react";
import { bindActionCreators } from "redux";
import { Route, Redirect } from "react-router-dom";
import { connect } from "react-redux";
import $ from "jquery";
import validator from "validator";
import { actionGetLanguageJson, ACTION_GET_LANG_JSON } from "../action";
import { setData, KEY_USER_TOKEN } from "../../../utils/preferences";
import { SaveAlert } from "../../../utils/Swal";
import { actionCheckUsername, ACTION_CHECKUSERNAME_SUCCESS } from "./action";

var UserLogin = {
  username: "",
};

var parent = null;

class ForgotPassword extends Component {
  constructor(props) {
    super(props);
    parent = this;
    this.state = {
      status: false,
      UserLogin: Object.assign({}, UserLogin),
      error: {
        username: "",
      },
    };
  }

  async componentWillMount() {}
  async componentDidMount() {}
  async componentWillReceiveProps(nextProps) {
    const { UsernameData } = nextProps;
    if (UsernameData !== undefined) {
      if (UsernameData.success) {
        SaveAlert("PleaseCheckYourEmail", "success", false, () => {
          window.location.href = "/";
        });
        // setTimeout(function () {
        //   window.location.href = "/";
        // }, 1400);
      } else {
        SaveAlert("UsernameNotFound", "error");
      }
    }
  }

  handleFormFields = (event) => {
    event.preventDefault();
    const { name, value } = event.target;
    parent.handleValidation(name, value);
  };

  handleValidation(name, value) {
    var { UserLogin } = this.state;

    UserLogin[name] = value;
    parent.setState({ UserLogin });

    this.setState({ status: true });

    $("#fg-username").removeClass("error");
    if (UserLogin.username.length === 0) {
      $("#fg-username").addClass("error");
      this.setState({ status: false });
    }
  }

  handleSubmit(event) {
    event.preventDefault();

    var { UserLogin, status } = parent.state;
    var key = "";
    for (key in UserLogin) {
      parent.handleValidation(key, UserLogin[key]);
    }
    if (status) {
      parent.props.actionCheckUsername(parent.state.UserLogin);
    }
  }

  render() {
    const { username } = this.state.UserLogin;
    const { lang } = this.props;

    return (
      <>
        <header
          style={{
            backgroundColor: "white",
            height: "150px",
            width: "100%",
            top: "0",
          }}
        ></header>
        <section className="flexbox-container mt-5">
          <div className="col-12 d-flex  align-items-center justify-content-center">
            <div className="col-lg-3 col-md-6 col-10 p-0">
              <div className="card px-1 py-1 m-0">
                {/* <div className="card-header border-0"></div> */}
                <div className="card-content">
                  {/* <p className="card-subtitle text-muted text-center font-large-1 mx-2 mb-2 primary">
                  <h5>{lang?.MONTEVIDEOSUITERESERVATIONSYSTEM}</h5>
                </p> */}
                  <h5 className="text-center font-weight-bold">
                    {lang?.ForgotPassword}
                  </h5>
                  <p className="card-subtitle text-muted text-center mt-2 mx-2 mb-2 primary">
                    <span>{lang?.PleaseEnterYourUsernameBelow}</span>
                  </p>
                  <div className="card-body">
                    <form className="form-horizontal" novalidate>
                      <fieldset className="form-group position-relative has-icon-left">
                        <div className="form-group" id="fg-username">
                          <input
                            type="text"
                            className="form-control"
                            id="username"
                            name="username"
                            value={username}
                            placeholder={lang?.Username}
                            onChange={this.handleFormFields}
                            required
                          />
                          <div className="form-control-position">
                            <i className="fa fa-key"></i>
                          </div>
                        </div>
                      </fieldset>
                      <button
                        type="submit"
                        style={{
                          backgroundColor: "#434D59",
                          padding: "5px",
                          height: "30px",
                          alignItems: "center",
                        }}
                        className="btn btn-dark btn-block"
                        onClick={this.handleSubmit}
                      >
                        {lang?.Next}
                      </button>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </>
    );
  }
}

const mapStateToProps = ({ VenueAppReducer, CheckUsernameReducer }) => {
  var UsernameData = null;
  switch (CheckUsernameReducer.tag) {
    case ACTION_CHECKUSERNAME_SUCCESS:
      UsernameData = Object.assign({}, CheckUsernameReducer.data);
      delete CheckUsernameReducer.data;
      return { lang: VenueAppReducer.lang, UsernameData };
    default:
      return { lang: VenueAppReducer.lang };
  }
};

function mapDispatchToProps(dispatch) {
  return bindActionCreators({ actionCheckUsername }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(ForgotPassword);
