import Api from "../../../api";
import {
  API_GET_ALL_STAFF,
  API_CREATE_STAFF,
  API_UPDATE_STAFF,
  API_DELETE_STAFF,
  API_UPDATE_PASSWORD_STAFF,
  API_GET_AUTH_GROUPS,
  API_GET_ALL_SCREENS,
  API_CREAT_AUTH_SCREENS,
  API_GET_SCREEN_AUTHORIZATIONS,
  API_UPDATE_GROUPS_SCREENS,
  API_GET_AUTH_GROUPS_WITH_SCREENS,
} from "../../../api/constants";

import { fetchSuccess } from "../../../utils/action.util";
import { Alert } from "../../../utils";

export const ACTION_GET_AUTH_GROUPS = "ACTION_GET_AUTH_GROUPS";
export const ACTION_GET_STAFF = "ACTION_GET_STAFF";
export const ACTION_CREATE_STAFF = "ACTION_CREATE_STAFF";
export const ACTION_UPDATE_STAFF = "ACTION_UPDATE_STAFF";
export const ACTION_GET_ALL_SCREENS = "ACTION_GET_ALL_SCREENS";
export const ACTION_CREATE_AUTH_SCREENS = "ACTION_CREATE_AUTH_SCREENS";
export const ACTION_GET_AUTH_GROUPS_WITH_SCREENS_SUCCESS =
  "ACTION_GET_AUTH_GROUPS_WITH_SCREENS_SUCCESS";
export const ACTION_GET_SCREEN_AUTHORIZATIONS =
  "ACTION_GET_SCREEN_AUTHORIZATIONS";

export const actionGetAuthGroups = (venueId) => (dispatch) => {
  return Api.get(`${API_GET_AUTH_GROUPS}`)
    .then((respose) => {
      const data = respose.data;
      dispatch(fetchSuccess(ACTION_GET_AUTH_GROUPS, data));
      return respose;
    })
    .catch((e) => e);
};

export const actionGetStaff = (ID) => (dispatch) => {
  try {
    return Api.get(API_GET_ALL_STAFF)
      .then((respose) => {
        const data = respose.data;
        console.log(data);
        dispatch(fetchSuccess(ACTION_GET_STAFF, data));
      })
      .catch((e) => e);
  } catch (err) {
    console.error(err);
    return err;
  }
};

export const actionCreateStaff = (staffData) => async (dispatch) => {
  try {
    const response = await Api.post(API_CREATE_STAFF, staffData);
    const data = response.data;
    console.log(data);
    dispatch(fetchSuccess(ACTION_CREATE_STAFF, data));
    return response;
  } catch (err) {
    return err;
  }
};

export function actionUpdateStaff(staffData, passwordObj) {
  // return (dispatch, getState) => {
  // try {
  //   const response = await Api.post(
  //     API_UPDATE_STAFF + "/" + staffData.ID,
  //     staffData
  //   );
  //   if (passwordObj.isPasswordChanged) {
  //     await actionUpdateStaffPassword();
  //   }
  //   const data = response.data;
  //   console.log(data)
  //   dispatch(fetchSuccess(ACTION_UPDATE_STAFF, data));
  //   return response;
  // } catch (err) {
  //   return err;
  // }

  return (dispatch, getState) =>
    Api.post(API_UPDATE_STAFF + "/" + staffData.ID, staffData)
      .then(async (res) => {
        const data = res.data;
        console.log(data);
        dispatch(fetchSuccess(ACTION_UPDATE_STAFF, data));
        return res;
      })
      .catch((reason) => {
        /** Handle Error */
        return reason;
      });
}
export function actionDeleteStaff(id) {
  return (dispatch, getState) =>
    Api.delete(API_DELETE_STAFF + id)
      .then((res) => {
        const data = res;
        console.log(data);
        dispatch(actionGetStaff(null));
        return res;
      })
      .catch((reason) => {
        /** Handle Error */
        return reason;
      });
}

export const actionUpdateStaffPassword =
  (password, staffId) => async (dispatch) => {
    try {
      Api.post(API_UPDATE_PASSWORD_STAFF + "/" + staffId, {
        PASSWORD: password,
      }).then((res) => {});
    } catch (err) {
      throw err;
    }
  };

export const actionsGetAllScreens = () => (dispatch) => {
  Api.get(API_GET_ALL_SCREENS).then((response) => {
    dispatch(fetchSuccess(ACTION_GET_ALL_SCREENS, response.data));
  });
};

export const actionsCreatAuthScreens = (data) => (dispatch) => {
  try {
    return Api.post(API_CREAT_AUTH_SCREENS, data).then((response) => {
      dispatch(fetchSuccess(ACTION_CREATE_AUTH_SCREENS, response.data));
      return response;
    });
  } catch (err) {
    console.error(err);
  }
};

export const actionGetScreenAuthorizations = (venueId) => (dispatch) => {
  Api.get(`${API_GET_SCREEN_AUTHORIZATIONS}/${venueId}`).then((response) => {
    dispatch(fetchSuccess(ACTION_GET_SCREEN_AUTHORIZATIONS, response.data));
  });
};

export const actionUpdateGroupsScreens = (params, cb) => () => {
  return Api.post(API_UPDATE_GROUPS_SCREENS, params)
    .then((result) => {
      cb && cb();
    })
    .catch((err) => {
      console.error(err);
    });
};

export const actionGetGroupsWithScreens = () => (dispatch) => {
  return Api.get(`${API_GET_AUTH_GROUPS_WITH_SCREENS}`).then((response) => {
    dispatch({
      type: ACTION_GET_AUTH_GROUPS_WITH_SCREENS_SUCCESS,
      payload: response?.data,
    });
  });
};
