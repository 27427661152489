/* eslint-disable no-unused-vars */
/* eslint-disable no-script-url */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Component } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import $ from "jquery";
import validator from "validator";
import {
  setData,
  KEY_NORMAL_USER,
  KEY_APP_TOKEN,
  KEY_USER_TOKEN,
} from "../../../utils/preferences";
import { getUrlByScreenId } from "../../../utils/screens";
import { setCSSVars } from "../../../utils";
import { actionUserLogin, ACTION_USERLOGIN_SUCCESS } from "./action";
import { SaveAlert } from "../../../utils/Swal";
import StickyPage from "../../../components/ui/stickyPage";
import Header from "../../../components/ui/Header";
import StickyHeader from "../../../components/ui/StickyHeader";
import bgImg from "../../../assets/header-img.jpg";
var UserLogin = {
  username: "",
  password: "",
};

var parent = null;

class Login extends Component {
  constructor(props) {
    super(props);
    parent = this;
    this.state = {
      UserLogin: Object.assign({}, UserLogin),
      error: {
        username: "",
        password: "",
      },
    };
  }

  componentWillMount() {
    console.log(this.props.lang);
  }
  componentDidMount() {}
  async componentWillReceiveProps(nextProps) {
    const { UserLogin } = nextProps;

    console.log(UserLogin);

    if (UserLogin != null) {
      var AdminLoginResponse = Object.assign({}, UserLogin);
      if (AdminLoginResponse.success === true) {
        await setData(
          KEY_NORMAL_USER,
          JSON.stringify({
            ...AdminLoginResponse.data,
            token: AdminLoginResponse.token,
          })
        );
        await setData(
          KEY_USER_TOKEN,
          JSON.stringify({ token: AdminLoginResponse.token })
        );
        const redirectTo = "/";
        window.location.href = redirectTo;
      } else {
        if (AdminLoginResponse.message === "Email not found") {
          SaveAlert("EmailNotFound", "error");
        } else {
          SaveAlert("PasswordNotMatched", "error");
        }
      }
    }
  }

  handleFormFields = (event) => {
    event.preventDefault();
    const { name, value } = event.target;
    parent.handleValidation(name, value);
  };

  handleValidation(name, value) {
    var { UserLogin } = this.state;

    var status = true;
    $("#fg-username, #fg-password").removeClass("error");

    if (UserLogin.username.length === 0) {
      $("#fg-username").addClass("error");
      status = false;
    }
    if (!validator.isEmail(UserLogin.username)) {
      $("#fg-username").addClass("error");
      status = false;
    }
    if (UserLogin.password.length === 0) {
      $("#fg-password").addClass("error");
      status = false;
    }
    UserLogin[name] = value;
    parent.setState({ UserLogin });
  }

  handleSubmit(event) {
    event.preventDefault();

    var { UserLogin } = parent.state;
    var status = true;
    var key = "";
    for (key in UserLogin) {
      parent.handleValidation(key, UserLogin[key]);
    }
    if (status) {
      parent.props.actionUserLogin(parent.state.UserLogin);
    } else {
    }
  }

  render() {
    const { username, password } = this.state.UserLogin;
    const { lang } = this.props;
    const privacyStatement = this.props.lang?.ReadPrivacyStatement || ''; // Provide a default value if undefined
    const words = privacyStatement.split(' ');
    console.log(words, "read privacy statement")

    let part1 = '';
    let part2 = '';
    let part3 = '';

    if (words.length >= 10 && words[3].toLowerCase() === 'privacy') {
      part1 = words.slice(0, 3).join(' ');
      part2 = words.slice(3, 5).join(' ');
      part3 = words.slice(5).join(' ');
    } else if (words.length >= 9 && words[3].toLowerCase() === 'privacyverklaring') {
      part1 = words.slice(0, 3).join(' ');
      part2 = words.slice(3, 4).join(' ');
      part3 = words.slice(4).join(' ');
    }
    console.log(part1, "part 1", part2, "part 2", part3, "part 3")
    
    return (
      <>
        <img
          src={bgImg}
          alt="msbs"
          style={{
            // backgroundImage: `url(header-img.jpg)`,
            marginTop: "39px",
            height: "auto",
            width: "100%",
          }}
        />
        {/* <header
          style={{
            backgroundImage: `url(header-img.jpg)`,
            marginTop: "39px",
            height: "90px",
            backgroundPosition: "center",
            backgroundSize: "cover",
            backgroundRepeat: "no-repeat",
          }}
        ></header> */}
        <section className="flexbox-container mt-5">
          <div className="col-12 d-flex  align-items-center justify-content-center">
            <div className="col-lg-3 col-md-6 col-10 p-0">
              <div className="card px-1 py-1 m-0">
                {/* <div className="card-header border-0"></div> */}
                <div className="card-content">
                  {/* <p className="card-subtitle text-muted text-center font-large-1 mx-2 mb-2 primary">
                  <h5>{lang?.MONTEVIDEOSUITERESERVATIONSYSTEM}</h5>
                </p> */}
                  <h5 className="text-center font-weight-bold">
                    {lang?.MONTEVIDEOSUITERESERVATIONSYSTEM}
                  </h5>
                  <p className="card-subtitle text-muted text-center mt-2 mx-2 mb-2 primary">
                    <span>{lang?.Pleaseloginusingyouruseraccountdetails}</span>
                  </p>
                  <div className="card-body">
                    <form className="form-horizontal" novalidate>
                      <fieldset className="form-group position-relative has-icon-left">
                        <div className="form-group" id="fg-username">
                          <input
                            type="text"
                            className="form-control"
                            id="username"
                            name="username"
                            value={username}
                            placeholder={lang?.Username}
                            onChange={this.handleFormFields}
                            required
                          />
                          <div className="form-control-position">
                            <i className="ft-user"></i>
                          </div>
                        </div>
                      </fieldset>
                      <fieldset className="form-group position-relative has-icon-left">
                        <div className="form-group" id="fg-password">
                          <input
                            type="password"
                            className="form-control"
                            id="password"
                            name="password"
                            value={password}
                            placeholder={lang?.Password}
                            onChange={this.handleFormFields}
                            required
                          />
                          <div className="form-control-position">
                            <i className="fa fa-key"></i>
                          </div>
                        </div>
                      </fieldset>
                      <button
                        type="submit"
                        style={{
                          padding: "5px",
                          height: "30px",
                          alignItems: "center",
                        }}
                        className="btn btn-dark btn-block"
                        onClick={this.handleSubmit}
                      >
                        {lang?.LOGIN}
                      </button>
                      <a href="/forgot-password">
                        <p className="card-subtitle text-muted mt-2 font-weight-bold mb-2 primary">
                          <span>{lang?.Forgotyourpassword}</span>
                        </p>
                      </a>

                      <a href="/login-privacy-statement">

                      <p className="card-subtitle text-muted mb-2 primary">
                        <span style={{ textDecoration: part1.toLowerCase().includes('privacy') || part1.toLowerCase().includes('privacyverklaring') ? 'underline' : 'none' }}>
                          {part1}
                        </span>
                        {part2 && (
                          <span style={{ textDecoration: part2.toLowerCase().includes('privacy') || part2.toLowerCase().includes('privacyverklaring') ? 'underline' : 'none', marginLeft: "4px", marginRight: "4px" }}>
                            {part2}
                          </span>
                        )}
                        {part3 && (
                          <span style={{ textDecoration: part3.toLowerCase().includes('privacy') || part3.toLowerCase().includes('privacyverklaring') ? 'underline' : 'none' }}>
                            {part3}
                          </span>
                        )}
                      </p>
                    </a>

                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </>
    );
  }
}

function mapStateToProps(state) {
  var UserLogin = null;
  const lang = state?.VenueAppReducer.lang;
  if (
    state.UserLoginReducer != null &&
    Object.keys(state.UserLoginReducer).length > 0 &&
    state.UserLoginReducer.constructor === Object
  ) {
    switch (state.AdminLoginReducer.tag) {
      case ACTION_USERLOGIN_SUCCESS:
        UserLogin = Object.assign({}, state.UserLoginReducer.data);
        delete state.UserLoginReducer;
        return { UserLogin, lang };
      default:
        return { lang };
    }
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({ actionUserLogin }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(Login);
