import Api from "../../../api";
import { fetchSuccess, loading } from "../../../utils/action.util";
import { API_CHECKUSERNAME } from "../../../api/constants";
import { PRINT } from "../../../utils";

export const CHECKUSERNAME_LOADING = "CHECKUSERNAME_LOADING";
export const ACTION_CHECKUSERNAME_SUCCESS = "ACTION_CHECKUSERNAME_SUCCESS";

export function actionCheckUsername(params) {
  return (dispatch, getState) =>
    Api.post(API_CHECKUSERNAME, params)
      .then((res) => {
        const data = res;
        PRINT("ACTION RES", data);
        dispatch(fetchSuccess(ACTION_CHECKUSERNAME_SUCCESS, { data }));
      })
      .catch((reason) => {
        /** Handle Error */
        var data = {
          message: "",
        };

        dispatch(loading(CHECKUSERNAME_LOADING, { loading: false, data }));
        return reason;
      });
}
