import Api from "../../api";
import { fetchSuccess, loading } from "../../utils/action.util";
import {
  API_GET_OBJECTSETTING_APP,
  API_GET_CALENDAR_INFO,
  API_UPDATERESERVATION_BY_ID,
  API_GET_SUITEPRICEBYDATES,
  API_CHECK_OBJECTUNAVAILABLITY,
  API_GETHOUSEKEEPINGFORRESERVATION,
} from "../../api/constants";

export const OBJECTSETTINGLIST_LOADING = "OBJECTSETTINGLIST_LOADING";
export const ACTION_OBJECTSETTINGLIST_SUCCESS =
  "ACTION_OBJECTSETTINGLIST_SUCCESS";

export const ACTION_CALENDAR_SUCCESS = "ACTION_CALENDAR_SUCCESS";
export const ACTION_UPDATERESERVATION_SUCCESS =
  "ACTION_UPDATERESERVATION_SUCCESS";

export const OBJECTPRICELIST_LOADING = "ADDOBJECTPRICE_LOADING";
export const ACTION_OBJECTPRICELIST_SUCCESS = "ACTION_OBJECTPRICELIST_SUCCESS";

export function actionGetObjectSettingList() {
  return (dispatch, getState) =>
    Api.get(API_GET_OBJECTSETTING_APP)
      .then((res) => {
        const data = res;
        console.log(data);
        dispatch(fetchSuccess(ACTION_OBJECTSETTINGLIST_SUCCESS, { data }));
        return data;
      })
      .catch((reason) => {
        /** Handle Error */
        var data = {
          message: "",
        };

        dispatch(loading(OBJECTSETTINGLIST_LOADING, { loading: false, data }));
        return reason;
      });
}
export function actionUpdateReservation(params) {
  return (dispatch, getState) =>
    Api.post(API_UPDATERESERVATION_BY_ID + params.id, params)
      .then((res) => {
        const data = res;
        dispatch(fetchSuccess(ACTION_UPDATERESERVATION_SUCCESS, { data }));
        return data;
      })
      .catch((reason) => {
        /** Handle Error */
        var data = {
          message: "",
        };
        dispatch(loading(OBJECTSETTINGLIST_LOADING, { loading: false, data }));
      });
}

export function actionGetCalendarInfo(params) {
  return (dispatch, getState) =>
    Api.post(API_GET_CALENDAR_INFO, params)
      .then((res) => {
        const data = res;
        dispatch(fetchSuccess(ACTION_CALENDAR_SUCCESS, { data }));
      })
      .catch((reason) => {
        /** Handle Error */
        var data = {
          message: "",
        };
        dispatch(loading(OBJECTSETTINGLIST_LOADING, { loading: false, data }));
        return reason;
      });
}

export function actionFirstGetObjectPrice(params) {
  return (dispatch, getState) =>
    Api.post(API_GET_SUITEPRICEBYDATES, params)
      .then((res) => {
        const data = res;
        console.log(data);
        dispatch(fetchSuccess(ACTION_OBJECTPRICELIST_SUCCESS, { data }));
        return data;
      })
      .catch((reason) => {
        /** Handle Error */
        var data = {
          message: "",
        };

        dispatch(loading(OBJECTPRICELIST_LOADING, { loading: false, data }));
      });
}

export function actionCheckHouseKeepingRequest(params) {
  return (dispatch, getState) =>
    Api.post(API_GETHOUSEKEEPINGFORRESERVATION, params)
      .then((res) => {
        const data = res;
        console.log(data);
        return data;
      })
      .catch((reason) => {
        /** Handle Error */
        var data = {
          message: "",
        };
      });
}

export function checkObjectUnavailabilty(params) {
  return (dispatch, getState) =>
    Api.post(API_CHECK_OBJECTUNAVAILABLITY, params)
      .then((res) => {
        const data = res;
        console.log(data, "clllgg")
        return data;
      })
      .catch((reason) => {
        /** Handle Error */
        var data = {
          message: "",
        };
      });
}
