import Api from "../../../../api";
import { fetchSuccess, loading } from "../../../../utils/action.util";
import {
  API_CREATE_OBJECT,
  API_UPDATE_OBJECTS,
} from "../../../../api/constants";

export const ADDOBJECT_LOADING = "ADDOBJECT_LOADING";
export const ACTION_ADDOBJECT_SUCCESS = "ACTION_ADDOBJECT_SUCCESS";

export const EDITOBJECT_LOADING = "EDITOBJECT_LOADING";
export const ACTION_EDITOBJECT_SUCCESS = "ACTION_EDITOBJECT_SUCCESS";

export function actionEditObject(params) {
  return (dispatch, getState) =>
    Api.post(API_UPDATE_OBJECTS + params.id, params)
      .then((res) => {
        const data = res;
        console.log(data);
        dispatch(fetchSuccess(ACTION_EDITOBJECT_SUCCESS, { data }));
      })
      .catch((reason) => {
        /** Handle Error */
        var data = {
          message: "",
        };
        dispatch(loading(EDITOBJECT_LOADING, { loading: false, data }));
        return reason;
      });
}

export function actionAddObject(params) {
  return (dispatch, getState) =>
    Api.post(API_CREATE_OBJECT, params)
      .then((res) => {
        const data = res;
        dispatch(fetchSuccess(ACTION_ADDOBJECT_SUCCESS, { data }));
      })
      .catch((reason) => {
        /** Handle Error */
        var data = {
          message: "",
        };
        dispatch(loading(ADDOBJECT_LOADING, { loading: false, data }));
        return reason;
      });
}
