import Api from "../../../api";
import { fetchSuccess, loading } from "../../../utils/action.util";
import {
  API_GETADDONSERVICE_BY_ID,
  API_GET_SUITEPRICE,
  API_GET_SUITEPRICEBYDATES,
} from "../../../api/constants";

export const OBJECTLIST_LOADING = "ADDOBJECT_LOADING";
export const ACTION_OBJECTLIST_SUCCESS = "ACTION_OBJECTLIST_SUCCESS";

export const OBJECTPRICELIST_LOADING = "ADDOBJECTPRICE_LOADING";
export const ACTION_OBJECTPRICELIST_SUCCESS = "ACTION_OBJECTPRICELIST_SUCCESS";

export function actionGetObjectList(id) {
  return (dispatch, getState) =>
    Api.get(API_GETADDONSERVICE_BY_ID + id)
      .then((res) => {
        const data = res;
        console.log(data);
        dispatch(fetchSuccess(ACTION_OBJECTLIST_SUCCESS, { data }));
        return data;
      })
      .catch((reason) => {
        /** Handle Error */
        var data = {
          message: "",
        };

        dispatch(loading(OBJECTLIST_LOADING, { loading: false, data }));
        return reason;
      });
}

export function actionFirstGetObjectPrice(params) {
  return (dispatch, getState) =>
    Api.post(API_GET_SUITEPRICEBYDATES, params)
      .then((res) => {
        const data = res;
        console.log(data);
        dispatch(fetchSuccess(ACTION_OBJECTPRICELIST_SUCCESS, { data }));
        return data;
      })
      .catch((reason) => {
        /** Handle Error */
        var data = {
          message: "",
        };

        dispatch(loading(OBJECTPRICELIST_LOADING, { loading: false, data }));
        return reason;
      });
}
