import {
  ACTION_APP_VENUE_DETAILS_BY_VENUE_CODE_SUCCESS,
  ACTION_GET_LANG_JSON,
  ACTION_GET_STYLES
} from "./action";
import createReducer from "../../reducers/createReducer";

const INITIAL_STATE = {
  data: [],
  loading: false,
  tag: "",
  lang: {},
};

const VenueAppReducer = createReducer(INITIAL_STATE, {
  [ACTION_APP_VENUE_DETAILS_BY_VENUE_CODE_SUCCESS](state, action) {
    return {
      ...state,
      data: action.payload.data,
      tag: ACTION_APP_VENUE_DETAILS_BY_VENUE_CODE_SUCCESS,
      loading: false,
    };
  },
  [ACTION_GET_LANG_JSON](state, action) {
   // const language = JSON.parse(action.payload.data);
   const language = action.payload.data;
   localStorage.setItem("--user-lang", JSON.stringify(language));
        return {
      ...state,
      lang: action.payload.data,
      tag: ACTION_GET_LANG_JSON,
    };
  },
  [ACTION_GET_STYLES](state, action) {
         return {
       ...state,
       data: action.payload.data,
       tag: ACTION_GET_STYLES,
     };
   },
});
export default VenueAppReducer;
