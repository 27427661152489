import {
ACTION_ADDSTAFFAUTHORIZATIONGROUP_SUCCESS
} from './action';
import createReducer from '../../../../reducers/createReducer';

const INITIAL_STATE = {
        data: [],
        loading: false
    };

const AddStaffAuthorizationGroupReducer = createReducer(
	INITIAL_STATE,
	{
		[ACTION_ADDSTAFFAUTHORIZATIONGROUP_SUCCESS](state, action) {
            return Object.assign({}, {
                ...state,
                data: action.payload.data,
                tag: ACTION_ADDSTAFFAUTHORIZATIONGROUP_SUCCESS,
                loading: false
            });
        }
	}
);
export default AddStaffAuthorizationGroupReducer;