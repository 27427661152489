import {
  ACTION_VENUEAPPHOME_SUCCESS,
  ACTION_GET_SERVICE_CATEGORY_REQUEST,
  ACTION_VENUE_APP_RENTAL_GROUPS_SUCCESS,
  ACTION_VENUE_APP_ALL_RENTAL_GROUPS_SUCCESS,
  ACTION_TODAYS_NOTIFICATIONS_SUCCESS,
} from "./action";
import createReducer from "../../../reducers/createReducer";
import Moment from "moment";
const INITIAL_STATE = {
  data: [],
  loading: false,
  serviceCategories: [],
  rentalGroups: [],
  allRentalGroups: [],
  notifications: [],
};

const VenueAppHomeReducer = createReducer(INITIAL_STATE, {
  [ACTION_VENUEAPPHOME_SUCCESS](state, action) {
    return Object.assign(
      {},
      {
        ...state,
        data: action.payload.data,
        tag: ACTION_VENUEAPPHOME_SUCCESS,
        loading: false,
      }
    );
  },
  // getServiceRequest
  [ACTION_GET_SERVICE_CATEGORY_REQUEST](state, action) {
    return Object.assign(
      {},
      {
        ...state,
        serviceCategories: action.payload,
        tag: ACTION_GET_SERVICE_CATEGORY_REQUEST,
      }
    );
  },
  // getServiceRequest
  [ACTION_VENUE_APP_RENTAL_GROUPS_SUCCESS](state, action) {
    const data = action.payload.data;
    return Object.assign(
      {},
      {
        ...state,
        rentalGroups: data,
        tag: ACTION_VENUE_APP_RENTAL_GROUPS_SUCCESS,
      }
    );
  },
  [ACTION_VENUE_APP_ALL_RENTAL_GROUPS_SUCCESS](state, action) {
    const data = action.payload.data;
    const filtered = data.filter(({ rentalGroupTimings }) => {
      const start = rentalGroupTimings?.[0]?.start_time;
      const end = rentalGroupTimings?.[0]?.end_time;
      const currentTime = Moment(new Date(), "HH:mm");
      if (currentTime.isBetween(Moment(start, "HH:mm"), Moment(end, "HH:mm"))) {
        return true;
      } else {
        return false;
      }
    });
    return Object.assign(
      {},
      {
        ...state,
        allRentalGroups: filtered,
        tag: ACTION_VENUE_APP_ALL_RENTAL_GROUPS_SUCCESS,
      }
    );
  },
  [ACTION_TODAYS_NOTIFICATIONS_SUCCESS](state, action) {
    return Object.assign(
      {},
      {
        ...state,
        notifications: action.payload.data,
        tag: ACTION_TODAYS_NOTIFICATIONS_SUCCESS,
        loading: false,
      }
    );
  },
});
export default VenueAppHomeReducer;
