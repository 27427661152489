import $ from "jquery";
import { checkIfInIframe, postMessageToParent } from "./index";
import { IMAGE_PATH } from "../api/constants";
import {makeImageSrc} from "./index";
const isInIframe = checkIfInIframe();
export const setHeadAndManifest = (data) => {
  var { logo, name, description } = data;
  const faviconShortCut = $('head link[rel="shortcut icon"]');
  const favicon = $('head link[rel="icon"]');
  $("head title").text(name);
  var logoF = `${IMAGE_PATH}/${makeImageSrc(logo)}`;
  faviconShortCut[0].href = logo;
  favicon[0].href = logoF;
  var myDynamicManifest = {
    name: `${name}`,
    short_name: `${name}`,
    description: `${description}`,
    icons: [
      {
        src: logoF,
        sizes: "64x64 32x32 24x24 16x16",
        type: "image/png",
      },
      {
        src: logoF,
        type: "image/png",
        sizes: "192x192",
      },
      {
        src: logoF,
        type: "image/png",
        sizes: "512x512",
      },
    ],
    start_url: window.location.href,
    display: "standalone",
    theme_color: "#000000",
    background_color: "#ffffff",
  };
  if (isInIframe) {
    postMessageToParent({
      event: "injectManifest",
      manifest: myDynamicManifest,
    });
  } else {
    const stringManifest = JSON.stringify(myDynamicManifest);
    const blob = new Blob([stringManifest], { type: "application/json" });
    const manifestURL = URL.createObjectURL(blob);
    $('head link[rel="manifest"]').attr("href", manifestURL);
  }
};

export const setPWAStartPoint = () => {
  var origin = window.location.origin;
  var favicon = `${origin}/favicon.ico`;
  var logo = `${origin}/logo.png`;
  var myDynamicManifest = {
    short_name: "Recreatiegemak",
    name: "Recreatiegemak",
    icons: [
      {
        src: favicon,
        sizes: "64x64 32x32 24x24 16x16",
        type: "image/x-icon",
      },
      {
        src: logo,
        type: "image/png",
        sizes: "192x192",
      },
      {
        src: logo,
        type: "image/png",
        sizes: "512x512",
      },
    ],
    start_url: window.location.href,
    display: "standalone",
    theme_color: "#000000",
    background_color: "#ffffff",
  };
  // debugger;
  if (isInIframe) {
    postMessageToParent({
      event: "injectManifest",
      manifest: myDynamicManifest,
    });
  } else {
    const stringManifest = JSON.stringify(myDynamicManifest);
    const blob = new Blob([stringManifest], { type: "application/json" });
    const manifestURL = URL.createObjectURL(blob);
    $('head link[rel="manifest"]').attr("href", manifestURL);
  }
};
