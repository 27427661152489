import Api from "../../../api";
import { fetchSuccess, loading } from "../../../utils/action.util";
import {
  API_VENUE_HOME_DETAILS,
  API_GET_SERVICE_CATEGORY_REQUEST,
  API_VENUE_APP_RENTAL_GROUPS,
  API_VENUE_HOME_DETAILS_PREVIEW,
  API_VENUE_APP_RENTAL_GROUPS_PREVIEW,
  API_TODAYS_NOTIFICATION,
} from "../../../api/constants";

import { setData, KEY_VENUE_HOME_DETAILS } from "../../../utils/preferences";

export const VENUEAPPHOME_LOADING = "VENUEAPPHOME_LOADING";
export const ACTION_VENUEAPPHOME_SUCCESS = "ACTION_VENUEAPPHOME_SUCCESS";
export const ACTION_TODAYS_NOTIFICATIONS_SUCCESS =
  "ACTION_TODAYS_NOTIFICATIONS_SUCCESS";

//getServiceBtnRequest
export const ACTION_GET_SERVICE_CATEGORY_REQUEST =
  "ACTION_GET_SERVICE_CATEGORY_REQUEST";
export const ACTION_VENUE_APP_RENTAL_GROUPS_SUCCESS =
  "ACTION_VENUE_APP_RENTAL_GROUPS_SUCCESS";
export const ACTION_VENUE_APP_ALL_RENTAL_GROUPS_SUCCESS =
  "ACTION_VENUE_APP_ALL_RENTAL_GROUPS_SUCCESS";

export function actionVenueAppHome(params) {
  params = { ...params, timezoneOffset: new Date().getTimezoneOffset() };
  const isPreviewMode = localStorage.getItem("preview-mode");
  const previewType = localStorage.getItem("preview-type");
  const apiUrl =
    isPreviewMode && previewType === "all"
      ? API_VENUE_HOME_DETAILS_PREVIEW
      : API_VENUE_HOME_DETAILS;
  // debugger;
  return (dispatch, getState) =>
    Api.post(apiUrl, params)
      .then((res) => {
        const data = res;
        setData(KEY_VENUE_HOME_DETAILS, JSON.stringify(data?.response));
        dispatch(fetchSuccess(ACTION_VENUEAPPHOME_SUCCESS, { data }));
        return data;
      })
      .catch((reason) => {
        /** Handle Error */
        var data = {
          message: "",
        };
        dispatch(loading(VENUEAPPHOME_LOADING, { loading: false, data }));
        return reason;
      });
}

export function actionGetRentalGroups(venue) {
  const isPreviewMode = localStorage.getItem("preview-mode");
  const previewType = localStorage.getItem("preview-type");

  const apiUrl =
    isPreviewMode && previewType === "all"
      ? API_VENUE_APP_RENTAL_GROUPS_PREVIEW
      : API_VENUE_APP_RENTAL_GROUPS;

  return async (dispatch, getState) =>
    await Api.put(`${apiUrl}/${venue}`)
      .then((res) => {
        const data = res;
        dispatch(fetchSuccess(ACTION_VENUE_APP_RENTAL_GROUPS_SUCCESS, data));
        return data;
      })
      .catch((c) => c);
}
export const actionGetServiceCategoryRequest = (venueId) => async (
  dispatch
) => {
  return await Api.get(`${API_GET_SERVICE_CATEGORY_REQUEST}/${venueId}`).then(
    (res) => {
      dispatch(fetchSuccess(ACTION_GET_SERVICE_CATEGORY_REQUEST, res.data));
      return res.data;
    }
  );
};
export function actionTodaysNotifications(params) {
  return (dispatch, getState) =>
    Api.post(API_TODAYS_NOTIFICATION, params)
      .then((res) => {
        const data = res;
        dispatch(fetchSuccess(ACTION_TODAYS_NOTIFICATIONS_SUCCESS, { data }));
      })
      .catch((reason) => {
        /** Handle Error */
        var data = {
          message: "",
        };
        dispatch(loading(VENUEAPPHOME_LOADING, { loading: false, data }));
      });
}
