import {
  ACTION_ADDWEBAPPCMS_SUCCESS,
    } from './action';
    import createReducer from '../../../reducers/createReducer';
    
    const INITIAL_STATE = {
            data: [],
            loading: false
        };
    
    const WebAppCMSReducer = createReducer(
        INITIAL_STATE,
        {
         [ACTION_ADDWEBAPPCMS_SUCCESS](state, action) {
            return {
              ...state,
              data: action.payload.data,
              tag: ACTION_ADDWEBAPPCMS_SUCCESS,
              loading: false
            };
          },
        }
    );
    export default WebAppCMSReducer;