import Api from "../../../api";
import { fetchSuccess, loading } from "../../../utils/action.util";
import {
  API_USER_SETPASSWORD,
  API_USER_UPDATEPASSWORD,
} from "../../../api/constants";
import { PRINT } from "../../../utils";

export const RESETPASSWORD_LOADING = "RESETPASSWORD_LOADING";
export const ACTION_RESETPASSWORD_SUCCESS = "ACTION_RESETPASSWORD_SUCCESS";

export const UPDATEPASSWORD_LOADING = "RESETPASSWORD_LOADING";
export const ACTION_UPDATEPASSWORD_SUCCESS = "ACTION_RESETPASSWORD_SUCCESS";

export function actionResetPassword(params) {
  return (dispatch, getState) =>
    Api.post(API_USER_SETPASSWORD, params)
      .then((res) => {
        const data = res;
        PRINT("ACTION RES", data);
        dispatch(fetchSuccess(ACTION_RESETPASSWORD_SUCCESS, { data }));
      })
      .catch((reason) => {
        /** Handle Error */
        var data = {
          message: "",
        };
        dispatch(loading(RESETPASSWORD_LOADING, { loading: false, data }));
      });
}

export function actionUpdatePassword(params, id) {
  return (dispatch, getState) =>
    Api.post(API_USER_UPDATEPASSWORD + id, params)
      .then((res) => {
        const data = res;
        PRINT("ACTION RES", data);
        dispatch(fetchSuccess(ACTION_UPDATEPASSWORD_SUCCESS, { data }));
        return data;
      })
      .catch((reason) => {
        /** Handle Error */
        var data = {
          message: "",
        };
        dispatch(loading(UPDATEPASSWORD_LOADING, { loading: false, data }));
      });
}
