import {
  ACTION_OBJECTLIST_SUCCESS,
  ACTION_OBJECTPRICELIST_SUCCESS,
} from "./action";
import createReducer from "../../../reducers/createReducer";

const INITIAL_STATE = {
  data: [],
  data2: [],
  loading: false,
};

const ReservationOverviewListReducer = createReducer(INITIAL_STATE, {
  [ACTION_OBJECTLIST_SUCCESS](state, action) {
    return {
      ...state,
      data: action.payload.data,
      tag: ACTION_OBJECTLIST_SUCCESS,
      loading: false,
    };
  },
  [ACTION_OBJECTPRICELIST_SUCCESS](state, action) {
    return {
      ...state,
      data2: action.payload.data,
      tag: ACTION_OBJECTPRICELIST_SUCCESS,
      loading: false,
    };
  },
});
export default ReservationOverviewListReducer;
