import Api from '../../../../api';
import { fetchSuccess, loading } from '../../../../utils/action.util';
import { API_GET_OBJECTS } from '../../../../api/constants';


export const OBJECTLIST_LOADING = 'ADDOBJECT_LOADING';
export const ACTION_OBJECTLIST_SUCCESS = 'ACTION_OBJECTLIST_SUCCESS';




export function actionGetObjectList() {
    return (dispatch, getState) =>
  
      Api.get(API_GET_OBJECTS)
        .then((res) => {
          const data = res;
          console.log(data)
          dispatch(
            fetchSuccess(ACTION_OBJECTLIST_SUCCESS, { data })
          );
        })
        .catch((reason) => {
          /** Handle Error */
          var data = {
            message: "",
          };
  
          dispatch(
            loading(OBJECTLIST_LOADING, { loading: false, data })
          );
        });
      
  }

  
