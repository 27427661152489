import Api from "../../../api";
import { fetchSuccess, loading } from "../../../utils/action.util";
import {
  API_CREATE_LOOK_FEEL,
  API_UPDATE_LOOK_FEEL,
  API_GET_LOOK_FEEL,
} from "../../../api/constants";

export const ADDLOOKFEEL_LOADING = "ADDLOOKFEEL_LOADING";
export const ACTION_ADDLOOKFEEL_SUCCESS = "ACTION_ADDLOOKFEEL_SUCCESS";

export const EDITLOOKFEEL_LOADING = "EDITLOOKFEEL_LOADING";
export const ACTION_EDITLOOKFEEL_SUCCESS = "ACTION_EDITLOOKFEEL_SUCCESS";

export function actionGetWebAppLookFeel() {
  return (dispatch, getState) =>
    Api.get(API_GET_LOOK_FEEL)
      .then((res) => {
        const data = res;
        console.log(data);
        dispatch(fetchSuccess(ACTION_ADDLOOKFEEL_SUCCESS, { data }));
      })
      .catch((reason) => {
        /** Handle Error */
        var data = {
          message: "",
        };

        dispatch(loading(ADDLOOKFEEL_LOADING, { loading: false, data }));
        return reason;
      });
}

export function actionEditLookFeel(params) {
  return (dispatch, getState) =>
    Api.post(API_UPDATE_LOOK_FEEL + "/" + params.id, params)
      .then((res) => {
        const data = res;
        console.log(data);
        dispatch(fetchSuccess(ACTION_ADDLOOKFEEL_SUCCESS, { data }));
        return data;
      })
      .catch((reason) => {
        /** Handle Error */
        var data = {
          message: "",
        };
        dispatch(loading(ADDLOOKFEEL_LOADING, { loading: false, data }));
        return reason;
      });
}

export function actionAddLookFeel(params) {
  return (dispatch, getState) =>
    Api.post(API_CREATE_LOOK_FEEL, params)
      .then((res) => {
        const data = res;
        dispatch(fetchSuccess(ACTION_ADDLOOKFEEL_SUCCESS, { data }));
      })
      .catch((reason) => {
        /** Handle Error */
        var data = {
          message: "",
        };
        dispatch(loading(ADDLOOKFEEL_LOADING, { loading: false, data }));
        return reason;
      });
}
