import Api from "../../../api";
import { fetchSuccess, loading } from "../../../utils/action.util";
import { API_GET_RESERVATIONCALENDAR } from "../../../api/constants";

export const RESERVATIONLIST_LOADING = "ADDOBJECT_LOADING";
export const RESERVATIONLIST_SUCCESS = "ACTION_OBJECTLIST_SUCCESS";

export function actionGetReservationList(params) {
  return (dispatch, getState) =>
    Api.post(API_GET_RESERVATIONCALENDAR, params)
      .then((res) => {
        const data = res;
        dispatch(fetchSuccess(RESERVATIONLIST_SUCCESS, { data }));
        return data.data;
      })
      .catch((reason) => {
        /** Handle Error */
        var data = {
          message: "",
        };
        dispatch(loading(RESERVATIONLIST_LOADING, { loading: false, data }));
        return reason;
      });
}
