import Api from "../../../../api";
import { fetchSuccess, loading } from "../../../../utils/action.util";
import {
  API_EDITSTAFFAUTHORIZATIONGROUP,
  API_STAFFAUTHORIZATIONGROUPDETAILS,
} from "../../../../api/constants";

export const EDITSTAFFAUTHORIZATIONGROUP_LOADING =
  "EDITSTAFFAUTHORIZATIONGROUP_LOADING";
export const ACTION_EDITSTAFFAUTHORIZATIONGROUP_SUCCESS =
  "ACTION_EDITSTAFFAUTHORIZATIONGROUP_SUCCESS";
export const ACTION_STAFFAUTHORIZATIONGROUPDETAIL_SUCCESS =
  "ACTION_STAFFAUTHORIZATsIONGROUPDETAIL_SUCCESS";

export function actionEditStaffAuthorizationGroup(params) {
  return (dispatch, getState) =>
    Api.post(API_EDITSTAFFAUTHORIZATIONGROUP + params.id, params)
      .then((res) => {
        const data = res;
        console.log(data);
        dispatch(
          fetchSuccess(ACTION_EDITSTAFFAUTHORIZATIONGROUP_SUCCESS, { data })
        );
      })
      .catch((reason) => {
        /** Handle Error */
        var data = {
          message: "",
        };
        dispatch(
          loading(EDITSTAFFAUTHORIZATIONGROUP_LOADING, { loading: false, data })
        );
        return reason;
      });
}

export function actionStaffAuthorizationGroupDetail(params) {
  return (dispatch, getState) =>
    Api.post(API_STAFFAUTHORIZATIONGROUPDETAILS, params)
      .then((res) => {
        const data = res;
        dispatch(
          fetchSuccess(ACTION_STAFFAUTHORIZATIONGROUPDETAIL_SUCCESS, { data })
        );
      })
      .catch((reason) => {
        /** Handle Error */
        var data = {
          message: "",
        };
        dispatch(
          loading(EDITSTAFFAUTHORIZATIONGROUP_LOADING, { loading: false, data })
        );
        return reason;
      });
}
