import Api from "../../api";
import { fetchSuccess, loading } from "../../utils/action.util";
import {
  API_GETALLRESERVATION_BY_ID,
  API_GET_OBJECTS_APP,
  API_CHECKUSERNAMEEXISTS,
} from "../../api/constants";

export function actionGetObjectSettingList() {
  return (dispatch, getState) =>
    Api.get(API_GET_OBJECTS_APP)
      .then((res) => {
        const data = res;
        console.log(data);
        return data.data;
      })
      .catch((reason) => {
        /** Handle Error */
        var data = {
          message: "",
        };
      });
}
export function actionGetReservationById(id) {
  return (dispatch, getState) =>
    Api.get(API_GETALLRESERVATION_BY_ID + id)
      .then((res) => {
        const data = res;
        console.log(data);
        return data.data;
      })
      .catch((reason) => {
        /** Handle Error */
        var data = {
          message: "",
        };
      });
}

export function checkIfUserExists(params) {
  return (dispatch, getState) =>
    Api.post(API_CHECKUSERNAMEEXISTS, params)
      .then((res) => {
        const data = res;
        return data;
      })
      .catch((reason) => {
        /** Handle Error */
        var data = {
          message: "",
        };
      });
}
