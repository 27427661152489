import {
  ACTION_USERLOGIN_SUCCESS
} from './action';
import createReducer from '../../../reducers/createReducer';

const INITIAL_STATE = {
  data: [],
  loading: false
};

const UserLoginReducer = createReducer(
  INITIAL_STATE,
  {
      [ACTION_USERLOGIN_SUCCESS](state, action) {
          return Object.assign({}, {
              ...state,
              data: action.payload.data,
              tag: ACTION_USERLOGIN_SUCCESS,
              loading: false
          });
      }
  }
);
export default UserLoginReducer;