import Api from "../../../../api";
import { fetchSuccess, loading } from "../../../../utils/action.util";
import {
  API_ADDONSERVICE_STATUS,
  API_DELETEADDONSERVICE_OBJECTS,
} from "../../../../api/constants";

export const CHECKADDONSERVICE_LOADING = "CHECK_ADDONSERVICESTATUS_LOADING";
export const ACTION_CHECKADDONSERVICE_SUCCESS =
  "ACTION_CHECKADDONSERVICE_SUCCESS";

export const actionDeleteAddOnService = (id) => async (dispatch) => {
  try {
    await Api.delete(`${API_DELETEADDONSERVICE_OBJECTS}/${id}`);
  } catch (error) {
    throw error;
  }
};

export function actionGetAddOnServiceStatus(id) {
  return (dispatch, getState) =>
    Api.get(`${API_ADDONSERVICE_STATUS}/${id}`)
      .then((res) => {
        const data = res;
        console.log(data);
        dispatch(fetchSuccess(ACTION_CHECKADDONSERVICE_SUCCESS, { data }));
      })
      .catch((reason) => {
        /** Handle Error */
        var data = {
          message: "",
        };

        dispatch(loading(CHECKADDONSERVICE_LOADING, { loading: false, data }));
        return reason;
      });
}
