/* eslint-disable no-script-url */
/* eslint-disable no-script-url */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Component } from "react";
import "./index.css";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { setSideNavToggle } from "../../utils/side-nav";
import queryString from "query-string";
import { matchPath } from "react-router";
import { Switch } from "react-router-dom";
import { setCSSVars } from "../../utils";
import swal from "sweetalert";

import { clearData } from "../../utils/preferences";

import {
  actionGetReservationById,
  actionGetObjectSettingList,
  checkIfUserExists,
} from "./action";
import moment from "moment";

import HasPermission from "../content/HasPermission";
var parent = null;
const initialState = {
  masterO: false,
  serviceReq: false,
  contentMgmtO: false,
  userMgmtO: false,
  userPG1: false,
  userPG2: false,
  bookingGP1: false,
  bookingGP2: false,
  restaurantReservationOpen: false,
  pos: false,
  walkinGuest: false,
  openedProductGroupIds: [],
  openedRentalGroupIds: [],
};
class SideBar extends Component {
  constructor(props) {
    super(props);
    parent = this;
    this.state = {
      ...initialState,
      authorizedScreens: [],
      productGroupScreens: [],
      productGroups: [],
      rentalGroupScreens: [],
      rentalGroups: [],
      flagForServiceRequestScreen: false,
    };
  }

  componentDidUpdate(prevProps) {
    if (this.props?.location?.pathname !== prevProps?.location?.pathname) {
      console.log(this.props.location.pathname);

      if (!String(window.location.host).startsWith("suitesadmin")) {
        if (window.innerWidth <= 1022) {
          setCSSVars({ "--header-margin-left": "0px" });
          setCSSVars({ "--display": "none" });
        } else {
          setCSSVars({ "--header-margin-left": "240px" });
          setCSSVars({ "--display": "block" });
        }
      } else {
        if (window.innerWidth <= 1022) {
          setCSSVars({ "--admin-margin-left": "0px" });
          setCSSVars({ "--admin-display": "none" });
        } else {
          setCSSVars({ "--admin-margin-left": "280px" });
          setCSSVars({ "--admin-display": "block" });
        }
      }
    }
  }

  async componentDidMount() {
    if (this.props.styles !== undefined) {
      setCSSVars({ "--button-color": this.props.styles.web_app_button_color });
      setCSSVars({ "--font-color": this.props.styles.editor_font_color });
      setCSSVars({ "--font-family": this.props.styles.editor_font_family });
      setCSSVars({ "--fontSize": this.props.styles.editor_font_size });
      setCSSVars({
        "--button-text": this.props.styles.web_app_button_text_color,
      });
    }

    if (!String(window.location.host).startsWith("suitesadmin")) {
      var app_user = JSON.parse(localStorage.getItem("user_id"));
      if (app_user) {
        if (window.innerWidth <= 1022) {
          setCSSVars({ "--header-margin-left": "0px" });
          setCSSVars({ "--display": "none" });
        } else {
          setCSSVars({ "--header-margin-left": "240px" });
          setCSSVars({ "--display": "block" });
        }
        var obj = {};
        obj.username = app_user.user_name;
        const checkUser = await this.props.checkIfUserExists(obj);
        if (checkUser?.error) {
          var path = String(window.location.host).startsWith("suitesadmin")
            ? "/admin"
            : this.props.match.url;

          await clearData(path);
          window.location.href = "/";
        }

        const res = await this.props.actionGetReservationById(app_user.id);

        if (res) {
          const currentReservation = res.filter((data) =>
            moment(new Date()).isBetween(
              moment(data.start_date),
              moment(data.end_date),
              "date",
              "[]"
            )
          );

          if (currentReservation.length > 0) {
            localStorage.setItem(
              "ReservationForServiceRequest",
              JSON.stringify(currentReservation[0])
            );
            this.setState({ flagForServiceRequestScreen: true });
            var objectList = await this.props.actionGetObjectSettingList();
            if (objectList) {
              objectList.map((obj) => {
                if (
                  parseInt(obj.id) === parseInt(currentReservation[0].object_id)
                ) {
                  localStorage.setItem(
                    "SuiteForServiceRequest",
                    JSON.stringify(obj.object_name)
                  );
                }
              });
              if (currentReservation.length > 1) {
                const objectData = objectList.find(
                  (obj) =>
                    parseInt(obj.id) ===
                    parseInt(currentReservation[1].object_id)
                );
                localStorage.setItem(
                  "TwoSuite",
                  JSON.stringify(objectData.object_name)
                );
                localStorage.setItem(
                  "ReservationForServiceRequestSuiteTwo",
                  JSON.stringify(currentReservation[1].id)
                );
              } else {
                localStorage.removeItem("TwoSuite");
                localStorage.removeItem("ReservationForServiceRequestSuiteTwo");
              }
            }
          } else {
            localStorage.removeItem("ReservationForServiceRequest");
            localStorage.removeItem("SuiteForServiceRequest");
            localStorage.removeItem("TwoSuite");

            this.setState({ flagForServiceRequestScreen: false });
          }
        }
      }
    }

    if (this.props.langR) {
      const lang = this.formatLang(this.props.langR);
      this.setState({ lang });
    }
    const user = JSON.parse(localStorage.getItem("user"));
    const screens = user?.screens || [];
    const productGroupScreens = screens.filter(
      (screen) => screen.product_group
    );
    this.setState({ venue: user?.venue_id });
    const productGroups = Array.from(
      new Set(productGroupScreens.map((screen) => screen.product_group.id))
    ).map((groupId) => {
      const screen = productGroupScreens.find(
        (screen) => screen.product_group.id === groupId
      );
      return {
        ...screen.product_group,
      };
    });

    const rentalGroupScreens = screens.filter((screen) => screen.rental_group);

    const rentalGroups = Array.from(
      new Set(rentalGroupScreens.map((screen) => screen.rental_group.id))
    ).map((groupId) => {
      const screen = rentalGroupScreens.find(
        (screen) => screen.rental_group.id === groupId
      );
      return {
        ...screen.rental_group,
      };
    });
    this.setState({
      authorizedScreens: screens,
      productGroupScreens,
      productGroups,
      rentalGroups,
      rentalGroupScreens,
    });

    const { location } = this.props;
    this.openSubNavBars(location?.pathname);
  }

  closeOthers = (key, value = undefined) => {
    value = value || this.state?.[key];
    if (typeof value == "boolean") {
      value = !value;
    }
    if (typeof value === "number") {
      const oldValues = this.state?.[key];
      var found = oldValues.includes(value);
      if (found) {
        value = [];
      } else {
        value = [value];
      }
    }

    const newState = { ...this.state, ...initialState, [key]: value };
    this.setState({ ...newState });
  };
  componentWillReceiveProps(newProps) {
    const { location } = newProps;
    if (location !== this.props.location) {
      this.openSubNavBars(location?.pathname);
    }
    this.setLang(newProps);
  }
  setLang = (newProps) => {
    if (this.props.langR !== newProps.langR) {
      const lang = this.formatLang(newProps.langR);
      this.setState({ lang });
    }
    if (this.props.langA !== newProps.langA) {
      const lang = this.formatLang(newProps.langA);
      this.setState({ lang });
    }
    if (this.props.langS !== newProps.langS) {
      const lang = this.formatLang(newProps.langS);
      this.setState({ lang });
    }
    if (this.props.langSH !== newProps.langSH) {
      const lang = this.formatLang(newProps.langSH);
      this.setState({ lang });
    }
  };
  openSubNavBars = (path) => {
    this.isMasterOpen(path);
    this.isServiceRequestOpen(path);
    this.isContentManagementOpen(path);
    this.isUserManagementOpen(path);
    this.isUserProductGroup1Open(path);
    this.isUserProductGroup2Open(path);
    this.isBookingGroup1Open(path);
    this.isBookingGroup2Open(path);
    this.isRestaurantReservationOpen(path);
    this.isPOSOpen(path);
    this.isWalkinGuestOpen(path);
    this.onUrlChangeIsProductGroupOpen(path);
    this.onUrlChangeIsRentalGroupOpen(path);
  };

  isMasterOpen(path) {
    if (
      String(path).includes("product-groups") ||
      String(path).includes("product-categories") ||
      String(path).includes("customization-categories") ||
      String(path).includes("customizations") ||
      String(path).includes("order-status") ||
      String(path).includes("staff-authorization-groups") ||
      String(path).includes("products")
    ) {
      this.setState({ masterO: true });
    } else {
      this.setState({ masterO: false });
    }
  }

  isContentManagementOpen(path) {
    if (
      String(path).includes("content-types") ||
      String(path).includes("contents-list")
    ) {
      this.setState({ contentMgmtO: true });
    } else {
      this.setState({ contentMgmtO: false });
    }
  }

  isServiceRequestOpen(path) {
    if (String(path).includes("service-requests")) {
      this.setState({ serviceReq: true });
    } else {
      this.setState({ serviceReq: false });
    }
  }

  isUserManagementOpen(path) {
    if (String(path).includes("user-management")) {
      this.setState({ userMgmtO: true });
    } else {
      this.setState({ userMgmtO: false });
    }
  }

  isUserProductGroup1Open(path) {
    const { history } = this.props;
    const values = queryString.parse(history.location.search);
    if (
      String(path).includes("product-group-1") ||
      String(path).includes("breakfast-orders") ||
      String(path).includes("product-categories") ||
      // String(path).includes("products") ||
      String(path).includes("breakfast/product-management") ||
      (path === "/venue/products/add" && values.groupId === "1") ||
      (path === "/venue/product-categories/add" && values.groupId === "1") ||
      (path === "/venue/products/edit" && values.groupId === "1")
    ) {
      this.setState({ userPG1: true });
    } else {
      this.setState({ userPG1: false });
    }
  }

  isUserProductGroup2Open(path) {
    const { history } = this.props;
    const values = queryString.parse(history.location.search);
    if (
      String(path).includes("product-group-2") ||
      String(path).includes("snackbar-orders") ||
      String(path).includes("snackbar/product-management") ||
      (path === "/venue/products/add" && values.groupId === "2") ||
      (path === "/venue/product-categories/add" && values.groupId === "2") ||
      (path === "/venue/products/edit" && values.groupId === "2")
    ) {
      this.setState({ userPG2: true });
    } else {
      this.setState({ userPG2: false });
    }
  }

  isBookingGroup1Open(path) {
    if (String(path).includes("booking-group-1")) {
      this.setState({ bookingGP1: true });
    } else {
      this.setState({ bookingGP1: false });
    }
  }

  isBookingGroup2Open(path) {
    if (String(path).includes("booking-group-2")) {
      this.setState({ bookingGP2: true });
    } else {
      this.setState({ bookingGP2: false });
    }
  }

  isRestaurantReservationOpen(path) {
    if (
      String(path).includes("restaurant-reservations") ||
      String(path).includes("restaurant-reservations-info")
    ) {
      this.setState({ restaurantReservationOpen: true });
    } else {
      this.setState({ restaurantReservationOpen: false });
    }
  }
  isPOSOpen(path) {
    if (String(path).includes("pos")) {
      this.setState({ pos: true });
    } else {
      this.setState({ pos: false });
    }
  }
  isWalkinGuestOpen(path) {
    if (
      String(path).includes("walkin-guest-reg") ||
      String(path).includes("walkin-guest-reg-hi") ||
      String(path).includes("walkin-reg-info")
    ) {
      this.setState({ walkinGuest: true });
    } else {
      this.setState({ walkinGuest: false });
    }
  }
  isActive = (location, path) => {
    location = location?.pathname;
    if (location === path) {
      return "nav-item active open";
    } else {
      return "nav-item";
    }
  };

  getClass(value) {
    return value ? "ft-chevron-down" : "ft-chevron-right";
  }

  isSubOpen(value) {
    return value ? "nav-item has-sub open" : "nav-item has-sub";
  }

  onUrlChangeIsProductGroupOpen = (path) => {
    if (String(path).includes("venue/product-group/")) {
      const match = matchPath(path, {
        path: "/venue/product-group/:screenType/:groupId/:screensCount",
        exact: true,
        strict: false,
      });
      this.setState({ openedProductGroupIds: [Number(match.params?.groupId)] });
    } else {
      this.setState({ openedProductGroupIds: [] });
    }
  };

  isProductGroupOpen = (groupId) => {
    const { openedProductGroupIds } = this.state;
    const found = openedProductGroupIds.find((id) => id === groupId);
    return found ? "nav-item has-sub open" : "nav-item has-sub";
  };

  setProductGroupToggle = (groupId) => {
    this.closeOthers("openedProductGroupIds", Number(groupId));
  };

  onUrlChangeIsRentalGroupOpen = (path) => {
    if (String(path).includes("venue/rental-product-management/")) {
      const match = matchPath(path, {
        path: "/venue/rental-product-management/:screenType/:groupId/:screensCount",
        exact: true,
        strict: false,
      });
      this.setState({ openedRentalGroupIds: [Number(match.params?.groupId)] });
    } else {
      this.setState({ openedRentalGroupIds: [] });
    }
  };

  isRentalGroupOpen = (groupId) => {
    const { openedRentalGroupIds } = this.state;
    const found = openedRentalGroupIds.some((id) => id === Number(groupId));
    return found ? "nav-item has-sub open" : "nav-item has-sub";
  };

  setRentalGroupToggle = (groupId) => {
    this.closeOthers("openedRentalGroupIds", Number(groupId));
  };

  formatLang = (language) => {
    let lang = { ...language };
    if (lang) {
      Object.keys(lang).map(
        (key) => (lang[key] = lang[key].replace(/-/g, "-\r\n"))
      );
    }
    return lang;
  };

  checkAuthorization = (screenId) => {
    const { authorizedScreens } = this.state;
    return authorizedScreens.some((screen) => screen.id === screenId);
  };

  isInformationHeaderVisible = () => {
    return this.checkAuthorization(1);
  };

  isServiceRequestGroupAuthorized = () => {
    return this.checkAuthorization(2) || this.checkAuthorization(3);
  };

  isCommunicateHeaderVisible = () => {
    return this.isServiceRequestGroupAuthorized();
  };

  isPosGroupAuthorized = () => {
    return this.checkAuthorization(4) || this.checkAuthorization(5);
  };

  isBreakfastGroupAuthorized = () => {
    return this.checkAuthorization(6) || this.checkAuthorization(7);
  };

  isSnackbarGroupAuthorized = () => {
    return (
      this.checkAuthorization(8) ||
      this.checkAuthorization(20) ||
      this.checkAuthorization(9)
    );
  };

  isOrderHeaderVisible = () => {
    return (
      this.isPosGroupAuthorized() ||
      this.isBreakfastGroupAuthorized() ||
      this.isSnackbarGroupAuthorized()
    );
  };

  isRestaurantReservationGroupAuthorized = () => {
    return this.checkAuthorization(11) || this.checkAuthorization(12);
  };

  isBookHeaderVisible = () => {
    return this.isRestaurantReservationGroupAuthorized();
  };

  isWalkInRegGroupAuthorized = () => {
    return (
      this.checkAuthorization(13) ||
      this.checkAuthorization(14) ||
      this.checkAuthorization(15)
    );
  };

  isUserManagementGroupAuthorized = () => {
    return this.checkAuthorization(18) || this.checkAuthorization(19);
  };

  isManageHeaderVisible = () => {
    return (
      this.checkAuthorization(16) ||
      this.checkAuthorization(17) ||
      this.isUserManagementGroupAuthorized()
    );
  };
  async logout(path) {
    swal({
      title: this?.props?.lang.WantToLogout,
      text: this?.props?.lang.AreYouSureLogout,
      icon: "warning",
      buttons: [this?.props?.lang.No, this?.props?.lang.Yes],
    }).then(async (answer) => {
      if (answer) {
        try {
          path = String(window.location.host).startsWith("suitesadmin")
            ? "/admin"
            : path;
          await clearData(path);
          switch (path) {
            case "/admin":
              window.location.href = "/admin/login";
              break;
            case "/":
              window.location.href = "/";
              break;

            default:
              break;
          }
          return;
          //this.props.history.push('/admin/objects-management/edit')
        } catch (error) { }
      }
    });
  }
  render() {
    const { lang } = this.state;
    const { match } = this.props;
    var path = String(window.location.host).startsWith("suitesadmin")
      ? "/admin"
      : match.url;
    if (
      match.url === "/admin" ||
      String(window.location.host).startsWith("suitesadmin")
    ) {
      return (
        <div className="main-menu menu-accordion menu-shadow menu-light menu-static menu-fixed">
          <div
            className="bg-nav-open"
            onClick={() => setSideNavToggle(path)}
          ></div>
          <div className="main-menu-content ps-container ps-theme-light ps-active-y">
            <ul
              className="navigation navigation-main"
              id="main-menu-navigation"
              data-menu="menu-navigation"
            >
              <li className="nav-item-heading nav-item">
                <span>{lang?.ReservationManagement} </span>
              </li>

              <HasPermission screenId={1}>
                <MLink to="/admin/reservation-details">
                  {lang?.ReservationDetails}
                </MLink>
              </HasPermission>
              <HasPermission screenId={2}>
                <MLink to="/admin/reservation-calendar">
                  {lang?.ReservationCalendar}
                </MLink>
              </HasPermission>
              <HasPermission screenId={11}>
                <MLink to="/admin/refund-management">
                  {lang?.RefundManagement}
                </MLink>
              </HasPermission>
              <HasPermission screenId={13}>
                <MLink to="/admin/reservation-cms">
                  {lang?.ReservationsCMS}
                </MLink>
              </HasPermission>
              <li className="nav-item-heading nav-item">
                <span>{lang?.ServiceManagement} </span>
              </li>
              <HasPermission screenId={12}>
                <MLink to="/admin/service-requests-overview">
                  {lang?.ServiceRequestOverview}
                </MLink>
              </HasPermission>
              <HasPermission screenId={16}>
                <MLink to="/admin/service-request-cms">
                  {lang?.ServiceRequestCMS}
                </MLink>
              </HasPermission>

              <HasPermission screenId={14}>
                <MLink to="/admin/house-keep-planning-list-view">
                  {lang?.HousekeepingPlanningListView}
                </MLink>
              </HasPermission>
              <HasPermission screenId={15}>
                <MLink to="/admin/house-keep-planning-calendar-view">
                  {lang?.HouseKeepPlanningCalendarView}
                </MLink>
              </HasPermission>

              <li className="nav-item-heading nav-item">
                <span>{lang?.WEBAPPMANAGEMENT} </span>
              </li>
              <HasPermission screenId={3}>
                <MLink to="/admin/web-app-cms">{lang?.WebAppCms}</MLink>
              </HasPermission>
              <HasPermission screenId={4}>
                <MLink to="/admin/web-app-look-and-feel">
                  {lang?.WebAppLookFeel}
                </MLink>
              </HasPermission>
              <HasPermission screenId={17}>
                <MLink to="/admin/terms-and-conditions">
                  {lang?.TermsConditions}
                </MLink>
              </HasPermission>

              <li className="nav-item-heading nav-item">
                <span>{lang?.UserManagement} </span>
              </li>
              <HasPermission screenId={5}>
                <MLink to="/admin/users-management">{lang?.Users}</MLink>
              </HasPermission>
              <HasPermission screenId={6}>
                <MLink to="/admin/staff-management">
                  {lang?.StaffAccounts}
                </MLink>
              </HasPermission>
              <HasPermission screenId={7}>
                <MLink to="/admin/authorization-groups-management">
                  {lang?.ManageAuthorizationGroups}
                </MLink>
              </HasPermission>
              <HasPermission screenId={8}>
                <MLink to="/admin/staff-authorization-groups">
                  {lang?.StaffAuthorizationGroups}
                </MLink>
              </HasPermission>

              <li className="nav-item-heading nav-item">
                <span>{lang?.OBJECTMANAGEMENT} </span>
              </li>

              <HasPermission screenId={9}>
                <MLink to="/admin/objects-management">
                  {lang?.ObjectOverview}
                </MLink>
              </HasPermission>
              <HasPermission screenId={10}>
                <MLink to="/admin/object-settings">
                  {lang?.ObjectSettings}
                </MLink>
              </HasPermission>

              {/* <HasPermission screenId={6}>
                  <MLink to="/admin/objects-management/edit">
                    {lang?.ObjectDetails}
                  </MLink>
                </HasPermission> */}
            </ul>
          </div>
        </div>
      );
    } else {
      return (
        <div className="main-menu menu-accordion menu-shadow menu-light menu-static menu-fixed">
          <div
            className="bg-nav-open"
            onClick={() => setSideNavToggle(path)}
          ></div>
          <div className="main-menu-content ps-container ps-theme-light ps-active-y">
            <ul
              className="navigation navigation-main ul-class"
              id="main-menu-navigation"
            >

              <span>
                <HasPermission screenId={9}>
                  <MLink to="/"> {this.props.lang?.MainSreenApp}</MLink>
                </HasPermission>

                <HasPermission screenId={13}>
                  <MLink to="/make-reservation">
                    {this.props.lang?.Makeareservation}
                  </MLink>
                </HasPermission>
                <HasPermission screenId={14}>
                  <MLink to="/view-your-reservation-history">
                    {this.props.lang?.ViewYourReservationHistory}
                  </MLink>
                </HasPermission>
                {this.state.flagForServiceRequestScreen && (
                  <HasPermission screenId={10}>
                    <MLink to="/submit-service-request">
                      {this.props.lang?.SubmitServiceRequest}
                    </MLink>
                  </HasPermission>
                )}
                <HasPermission screenId={20}>
                  <MLink to="/service-request-history">
                    {this.props.lang?.ViewServiceHistory}
                  </MLink>
                </HasPermission>
                {/* <HasPermission screenId={15}>
              <MLink to="/update-reservation">
                {this.props.lang?.UpdateReservation}
              </MLink>
            </HasPermission> */}
                <HasPermission screenId={10}>
                  <MLink to="/update-password">
                    {this.props.lang?.UpdatePassword}
                  </MLink>
                </HasPermission>
                <HasPermission screenId={40}>
                  <li class="nav-item" onClick={() => this.logout(path)}>
                    <a>
                      {/* <i className="ft-power"></i> */}
                      {this?.props?.lang?.Logout}
                    </a>
                  </li>
                </HasPermission>
              </span>


              <span>
                <HasPermission screenId={22}>
                  <MLink to="/terms-and-conditions">
                    {this.props.lang?.TermsConditions}
                  </MLink>
                </HasPermission>
                <HasPermission screenId={24} >
                  <MLink to="/privacy-statement">
                    {this.props.lang?.PrivacyStatement}
                  </MLink>
                </HasPermission>
              </span>

              {/* <HasPermission screenId={11}>
                  <MLink to="/reservation-overview">
                    {this.props.lang?.ReservationOverviewScreen}
                  </MLink>
                </HasPermission>

                <HasPermission screenId={12}>
                  <MLink to="/reservation-completion">
                    {this.props.lang?.ReservationCompletion}
                  </MLink>
                </HasPermission> */}
            </ul>
          </div>
        </div>
      );
    }
  }
}

const MLink = ({ to, children }) => {
  // console.log("to===>.>>", to);
  const { history, location } = parent.props;
  let className = "nav-item";
  if (location?.pathname === to) {
    className += " active";
  }
  return (
    <li className={className}>
      <a
        href
        onClick={() => {
          const width = document.body.clientWidth;
          // if (width <= 1024) {
          //   setSideNavToggle(this.props.match.url);
          // }
          history.push(to);
        }}
      >
        {children}
      </a>
    </li>
  );
};
const mapStateToProps = ({ adminReducer }) => ({
  langA: adminReducer.lang,
});

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    { actionGetReservationById, actionGetObjectSettingList, checkIfUserExists },
    dispatch
  );
}
export default connect(mapStateToProps, mapDispatchToProps)(SideBar);
