import swal from "sweetalert";
import $ from "jquery";

export const PRINT = (title, data) => {
  if (
    // process.env.NODE_ENV === "development" ||
    process.env.NODE_ENV === "local"
  ) {
    if (data !== null || data !== undefined) {
      console.log(title + " : " + JSON.stringify(data));
    } else {
      console.log(title);
    }
  }
};
export const getStatusString = (status) => {
  switch (status) {
    case "A":
      return "Active";
    case "I":
      return "Inactive";
    default:
      return "";
  }
};

export function euroCurrencyFormatter(amount) {
  var aaa = Number(amount);
  var de = String(aaa).split(".")[1] || 0;
  var final = de > 0 ? aaa.toFixed(2).replace(".", ",") : aaa;

  return final;
}

export function Alert(type, msg, title, redirect = false) {
  let icon = "";
  let text = "";
  switch (type) {
    case "warning":
      icon = "warning";
      title = title ? title : "";
      text =
        msg || msg === "" ? msg : "Some thing went wrong, there is a warning.";
      break;
    case "error":
      icon = "error";
      title = title ? title : "";
      text =
        msg || msg === "" ? msg : "Some thing went wrong, there is a error.";
      break;
    case "info":
      icon = "info";
      title = title ? title : "";
      text =
        msg || msg === ""
          ? msg
          : "Some thing you should know, there is a information.";
      break;
    case "success":
      icon = "success";
      title = title ? title : "";
      text = msg || msg === "" ? msg : "Successfully Initiated";
      break;
    default:
      break;
  }
  const wrapper = document.createElement("div");
  wrapper.innerHTML = text;
  swal({
    icon: icon,
    title: title,
    content: wrapper,
    html: true,
  }).then(function () {
    if (redirect) {
      redirect();
    }
  });
}

export function roundUp(num) {
  return parseFloat(num).toFixed(2);
}
export function covertDecimal(string, to = ",") {
  string = string.toString();
  return string.replace(".", to);
}

export const postLocationToParent = (prevProps, currentProps) => {
  const { location } = currentProps;
  let pathname;
  const { host, protocol } = window.location;
  pathname = location?.pathname ? location?.pathname : window.location.pathname;
  const url = `${protocol}//${host}${pathname}`;
  manageHistoryStack(pathname);
  // console.log({ prevProps, currentProps });
  // console.log(window.history);
  postMessageToParent({ url, event: "storePreviousUrl" });
};
export const manageHistoryStack = (url, from) => {
  let stack = localStorage.getItem("routerHistoryStack");
  stack = stack ? JSON.parse(stack) : [];
  if (url) {
    if (stack.lastIndexOf(url.toLowerCase()) === -1) {
      stack.push(url.toLowerCase());
    }
  } else {
    stack = stack.slice(0, -1);
  }
  localStorage.setItem("routerHistoryStack", JSON.stringify(stack));
};
export const handleGoBack = (props, from = "navbar") => {
  manageHistoryStack(false, "handleGoBack");
  const stack = JSON.parse(localStorage.getItem("routerHistoryStack"));
  var prevUrl = stack?.length > 1 ? stack?.[stack?.length - 1] : stack?.[0];
  if (prevUrl) {
    props.history.push(`${prevUrl}`);
  } else {
    window.history.go(-1);
  }
};
export const handleHistoryStackOnBrowserBack = () => {
  let pathname;
  pathname = window.location.pathname;
  let stack = localStorage.getItem("routerHistoryStack");
  stack = stack ? JSON.parse(stack) : [];
  var lastRouteInStack = stack?.[stack?.length - 1];
  if (pathname.toLowerCase() !== lastRouteInStack) {
    stack = stack.slice(0, -1);
  }
  localStorage.setItem("routerHistoryStack", JSON.stringify(stack));
};
export const supportedImageTypes = [
  "image/jpg",
  "image/jpeg",
  "image/gif",
  "image/png",
];
export function getEditorStyles() {
  let venue_info = localStorage.getItem("app_styles");
  const {
    editor_font_family: fontFamily,
    editor_font_size: fontSize,
    editor_font_color: color,
  } = venue_info ? JSON.parse(venue_info) : {};

  return formatStyles({ fontFamily, fontSize, color });
}
export function getInformationEditorStyles() {
  let venue_info = localStorage.getItem("app_styles");
  const {
    information_font_family: fontFamily,
    information_font_size: fontSize,
    information_font_color: color,
  } = venue_info ? JSON.parse(venue_info) : {};
  return formatStyles({ fontFamily, fontSize, color });
}
function formatStyles({ fontFamily, fontSize, color }) {
  return {
    "font-family": fontFamily || "Open Sans",
    "font-size": fontSize ? `${fontSize}px` : `16px`,
    color: color || "rgb(0,0,0)",
  };
}
export function format_minutes(num, lang) {
  // console.log(num);
  var hours = Math.floor(num / 60);
  var minutes = num % 60;
  if (hours && minutes) {
    return `${hours} ${lang?.hr ? lang?.hr : ""} ${minutes} ${
      lang?.mins ? lang?.mins : ""
    }`;
  } else if (hours) {
    return `${hours} ${lang?.hr ? lang?.hr : ""}`;
  } else if (minutes) {
    return `${minutes} ${lang?.mins ? lang?.mins : ""}`;
  }
  return "";
}

export function convertTime24hTo12hFormat(time) {
  if (!time) return "";
  let hours = time.split(":")[0];
  var AmOrPm = hours >= 12 ? "pm" : "am";
  hours = hours % 12 || 12;
  var minutes = time.split(":")[1];
  var finalTime = hours + ":" + minutes + " " + AmOrPm;
  return finalTime;
}

export const formatDateToAmPmTime = (dateTime) => {
  if (!dateTime) return "";
  let time = dateTime.split("T")[1];
  return convertTime24hTo12hFormat(time);
};

export const getTimeOnly = (dateString) => {
  if (!dateString) return "";
  let time = dateString.split("T")[1];
  let timeParts = time.split(":");
  return `${timeParts?.[0]}:${timeParts?.[1]}`;
};

export const storeLastScrollPosition = (key, position, edited = false) => {
  var data = JSON.stringify({ position, edited });
  localStorage.setItem(key, data);
};
export const updateEditedForScroll = (key, edited = true) => {
  let oldData = localStorage.getItem(key);
  oldData = oldData ? JSON.parse(oldData) : null;
  if (oldData) {
    var data = JSON.stringify({ ...oldData, edited });
    localStorage.setItem(key, data);
  }
};
export const scrollToLastPosition = (
  key,
  forceToScrollThroughWindow = false
) => {
  let oldData = localStorage.getItem(key);
  oldData = oldData ? JSON.parse(oldData) : null;
  if (oldData && oldData?.edited) {
    // debugger;
    if (
      document.getElementById("content-wrapper") &&
      !forceToScrollThroughWindow
    ) {
      $("#content-wrapper").animate({
        scrollTop: oldData.position,
        behavior: "smooth",
      });
    } else {
      window.scrollTo({
        top: oldData.position,
        behavior: "smooth",
      });
    }
    localStorage.removeItem(key);
  }
};

export function stripHTML_ENTITIES(html) {
  // return html ? String(html).replaceAll(/(<([^>]+)>)/gi, "") : html;
  return html ? String(html).replace(/<[^>]*>?/gm, "") : html;
}
export function stripHTML_ENTITIESV2(html) {
  html = html ? html : "";
  html = html.replaceAll("<br>", "\n");
  return html.replaceAll(/(<([^>]+)>)/gi, "");
}
export function setCookie(cname, cvalue, exdays = 1000) {
  // if (checkIfInIframe()) {
  localStorage.setItem(cname, JSON.stringify(cvalue));
  // } else {
  var d = new Date();
  d.setTime(d.getTime() + exdays * 24 * 60 * 60 * 1000);
  var expires = "expires=" + d.toGMTString();
  var str = cname + "=" + cvalue + ";" + expires + ";path=/";
  document.cookie = str;
  // }
}

export function getCookie(cname) {
  if (checkIfInIframe()) {
    return localStorage.getItem(cname);
  } else {
    var name = cname + "=";
    var decodedCookie = decodeURIComponent(document.cookie);
    var ca = decodedCookie.split(";");
    for (var i = 0; i < ca.length; i++) {
      var c = ca[i];
      while (c.charAt(0) === " ") {
        c = c.substring(1);
      }
      if (c.indexOf(name) === 0) {
        return c.substring(name.length, c.length);
      }
    }
    return "";
  }
}
export function getWindowParentParam(param) {
  var url_string = window.location.search;
  const params = getQueryParams(url_string);
  return params?.[param];
}
export function getQueryParams(qs) {
  qs = qs.split("+").join(" ");

  var params = {},
    tokens,
    re = /[?&]?([^=]+)=([^&]*)/g;

  while ((tokens = re.exec(qs))) {
    params[decodeURIComponent(tokens[1])] = decodeURIComponent(tokens[2]);
  }

  return params;
}
export function openUrlUsingParent(url) {
  postMessageToParent({ url, event: "openUrl" });
}
export function checkIfInIframe() {
  return window.location !== window.parent.location;
}
export function clearParentParams() {
  postMessageToParent({ event: "clearParams" });
}
export const postMessageToParent = (data) => {
  window.parent.postMessage(data, "*");
};
export function objSort() {
  var args = arguments,
    array = args[0],
    case_sensitive,
    keys_length,
    key,
    desc,
    a,
    b,
    i;

  if (typeof arguments[arguments.length - 1] === "boolean") {
    case_sensitive = arguments[arguments.length - 1];
    keys_length = arguments.length - 1;
  } else {
    case_sensitive = false;
    keys_length = arguments.length;
  }

  return array.sort(function (obj1, obj2) {
    for (i = 1; i < keys_length; i++) {
      key = args[i];
      if (typeof key !== "string") {
        desc = key[1];
        key = key[0];
        a = obj1[args[i][0]];
        b = obj2[args[i][0]];
      } else {
        desc = false;
        a = obj1[args[i]];
        b = obj2[args[i]];
      }

      if (case_sensitive === false && typeof a === "string") {
        a = a.toLowerCase();
        b = b.toLowerCase();
      }

      if (!desc) {
        if (a < b) return -1;
        if (a > b) return 1;
      } else {
        if (a > b) return -1;
        if (a < b) return 1;
      }
    }
    return 0;
  });
}
export function getTextSort(direction, v1, v2) {
  if (v1 !== undefined && v2 !== undefined) {
    if (typeof v1 === "string" && typeof v2 === "string") {
      v1 = v1.trim();
      v2 = v2.trim();
      v1 = v1.toLowerCase();
      v2 = v2.toLowerCase();
      v1 = v1.length === 0 ? " " : v1;
      v2 = v2.length === 0 ? " " : v2;
    }
    if (v1 > v2) return direction;
    if (v1 < v2) return -direction;
  }
  return 0;
}
export function getAlphaNumericSort(direction, v1, v2) {
  if (v1 !== undefined && v2 !== undefined) {
    if (typeof v1 === "string" && typeof v2 === "string") {
      v1 = v1.trim();
      v2 = v2.trim();
      v1 = v1.toLowerCase();
      v2 = v2.toLowerCase();
      v1 = v1.length === 0 ? " " : v1;
      v2 = v2.length === 0 ? " " : v2;
    }
    var rv1 = v1.localeCompare(v2, undefined, {
      numeric: true,
      sensitivity: "base",
    });
    var rv2 = v2.localeCompare(v1, undefined, {
      numeric: true,
      sensitivity: "base",
    });

    if (rv1 > rv2) return direction;
    if (rv1 < rv2) return -direction;
  }
  return 0;
}

export function objectKeysReplacer(data, newFormat) {
  if (Array.isArray(data)) {
    return data.map((obj) => {
      return objectKeysReplacer(obj, newFormat);
    });
  }
  const dataK = Object.keys(data);
  if (typeof newFormat !== "object") {
    console.error(
      "newFormat should be in the form of object with old key as object key and new key as its value"
    );
    return false;
  }
  var newData = {};

  dataK.map((k) => {
    newData[newFormat?.[k] || k] = data[k];
    return true;
  });
  return newData;
}
export const onRouteChange = (currentPathname, prevPathname) => {
  if (
    !currentPathname ||
    !prevPathname ||
    prevPathname === currentPathname ||
    prevPathname === "/"
  ) {
    return;
  }
  var blackList = [];
  var whiteList = [];
  if (
    blackList.some((p) => currentPathname.includes(p.route)) &&
    !whiteList.some((p) => prevPathname.includes(p))
  ) {
    var localStorageLength = localStorage.length;
    for (let index = 0; index < localStorageLength; index++) {
      const key = localStorage.key([index]);
      var found = blackList.find((p) => currentPathname.includes(p.route));
      if (key && found.sKey && key.includes(found.sKey)) {
        localStorage.removeItem(key);
      }
    }
  }
};
export function convertDateToCurrentTimezone(date) {
  var offset = new Date().getTimezoneOffset();
  var nMinutes = new Date(date).getMinutes();
  var nDate = new Date(date);
  nDate.setMinutes(nMinutes + offset);
  return nDate;
}
export function autoReizeTextArea(element) {
  autoReizeElement(element.target);
}
export function autoReizeElement(element) {
  element.style.height = "5px";
  element.style.height = element.scrollHeight + "px";
}

export function setCSSVars(styles) {
  Object.keys(styles).map((k) => {
    var value = styles[k];
    console.log(k, value);
    return document.documentElement.style.setProperty(k, value);
  });
}

export function replaceFullStop(price) {
  return price === "" || price === null
    ? null
    : String(Number(replaceComma(price)).toFixed(2)).replace(".", ",");
}
export function replaceComma(price) {
  return price === "" || price === null
    ? null
    : Number(String(price).replace(",", "."));
}

export function getInputPriceFormat(price, priceChanged = false) {
  if (priceChanged) {
    return replaceFullStop(price);
  } else {
    return price;
  }
}
export function isPriceFine(price) {
  return price !== null && price !== "" && price !== undefined;
}
export const validateImageType = (image) => {
  return supportedImageTypes.includes(image?.type);
};
export function validateImages(images) {
  var valid = true;
  var errorCode = "";
  if (images?.length) {
    for (let index = 0; index < images.length; index++) {
      const image = images[index];
      if (!validateImageType(image)) {
        valid = false;
        errorCode = "type";
        break;
      }
    }
  }
  return { valid, errorCode };
}

export function removeEntriesFrom(start, end) {
  let stack = localStorage.getItem("routerHistoryStack");
  stack = stack ? JSON.parse(stack) : [];
  stack = stack.slice(start, end);
  localStorage.setItem("routerHistoryStack", JSON.stringify(stack));
}

export function isInEveryThingMode() {
  var type = localStorage.getItem("preview-type");
  var inPreview = localStorage.getItem("preview-mode");
  return type === "all" && Boolean(inPreview);
}
export const stopEnteringEAndDot = (e) =>
  (e.keyCode === 69 || e.keyCode === 190) && e.preventDefault();

export function getQpmByName(url, name) {
  var match = RegExp("[?&]" + name + "=([^&]*)").exec(url);
  return match && decodeURIComponent(match[1].replace(/\+/g, " "));
}
export function makeImageSrc(src) {
  return String(src).startsWith("/") ? src.slice(1) : src;
}
