import swal from "sweetalert";
import $ from "jquery";
export async function SaveAlert(
  key = "DATA_SAVED",
  type = "success",
  timeout = 2000,
  cb = () => {}
) {
  function getLang() {
    var pathname = window.location?.pathname;
    if (pathname.startsWith("/shop/")) {
      return localStorage.getItem("--shop-lang");
    } else if (pathname.startsWith("/salon/")) {
      return localStorage.getItem("--salon-lang");
    } else if (pathname.startsWith("/venue/")) {
      return localStorage.getItem("--venue-lang");
    } else if (pathname.startsWith("/admin/")) {
      return localStorage.getItem("--admin-lang");
    } else if (pathname.startsWith("/")) {
      return localStorage.getItem("--user-lang");
    } else {
      return localStorage.getItem("lang");
    }
  }
  let lang = getLang();
  lang = lang ? JSON.parse(lang) : {};
  const text = lang?.[key];
  console.log(text);
  if (text) {
    swal(text, {
      icon: type,
      closeOnClickOutside: Boolean(timeout),
    }).then((status) => {
      if (status) {
        cb && cb();
      }
    });
    if (timeout) {
      setTimeout(() => {
        swal.close();
      }, timeout);
    }
  } else {
    swal(`'${key}' This Key Is Not In Lang File`, {
      icon: "error",
      buttons: false,
    });
  }
}

export const storeAbleSwal = (config) => {
  localStorage.setItem("last_swal", JSON.stringify(config));
  return new Promise((resolve, reject) => {
    swal(config.swal).then((status) => {
      if (status) {
        localStorage.removeItem("last_swal");
        resolve(status);
      } else {
        localStorage.removeItem("last_swal");
        reject(status);
      }
    });
    $("div.swal-overlay").scrollTop(0);
  });
};

export const NonAvailabilitySwal = ({
  lang,
  message,
  route,
  products,
  onClick,
  showProducts,
  showButtons = true,
}) => {
  const wrapper = document.createElement("div");
  var html = `<span>${message} ${
    products?.length > 0 && showProducts ? "</br>" + products.join("</br>") : ""
  }</span>`;
  wrapper.innerHTML = html;
  var config = {
    swal: {
      icon: "error",
      content: wrapper,
      html: true,
      closeOnClickOutside: false,
      closeOnEsc: false,
    },
    type: "message",
    route: route,
    html: html,
  };
  if (showButtons) {
    config.swal.button = { text: lang.GoBack, value: true };
  }
  storeAbleSwal(config).then((status) => {
    if (status) {
      onClick();
    }
  });
};

export function getContentInNodesForm(title, image) {
  const wrapper = document.createElement("div");
  wrapper.innerHTML = title;
  const imageWrapper = document.createElement("div");
  const imageTag = document.createElement("img");
  imageWrapper.className = "image-w-eh-p";
  imageTag.src = image;
  imageTag.style.opacity = 0;
  imageTag.style.height = 0;
  imageTag.onerror = (ele) => {
    ele.target.onerror = "";
    ele.target.style.opacity = 0;
    ele.target.style.height = 0;
  };
  imageTag.onload = (ele) => {
    ele.target.removeAttribute("style");
  };
  imageWrapper.append(imageTag);
  wrapper.prepend(imageWrapper);
  return wrapper;
}
