var localStorage = window.localStorage;

export const KEY_USER = "user";
export const KEY_NORMAL_USER = "user_id";
export const KEY_USER_TOKEN = "--rg_user_auth";


export const KEY_TOKEN = "--rg_venue_auth";
export const KEY_ADMIN_TOKEN = "--rg_admin_auth";
export const ADMIN = "admin";
export const VENUE = "venue";
export const KEY_APP_TOKEN = '--app-user-token';
export const KEY_GUEST_DETAILS = '--gues-details';
export const KEY_SELECTED_PRODUCT_GROUP = '--selected-produt-groups';
export const KEY_TODAYS_NOTIFICATION = '--today-notifications';
export const KEY_TODAYS_NOTIFICATION_SHOWN = '--notifications-shown';
export const KEY_VENUE_DETAILS = '--venue-details';
export const KEY_VENUE_HOME_DETAILS = '--venue-home-details';
export const KEY_VENUE_LOGIN = '--venue-login';
/**
 *
 * @param {venu section} key
 * @param {*} data
 */
export const setData = async (key, data) => {
  try {
    await localStorage.setItem(key, data);
  } catch (error) {
    // Error saving data
  }
};

export const getData = async (key) => {
  try {
    const value = await localStorage.getItem(key);
    if (value !== null) {
      return value;
    }
  } catch (error) {
    return null;
  }
  return null;
};

export const removeData = async (key) => {
  try {
    await localStorage.removeItem(key);
  } catch (error) {
    // Error while removing
  }
};

export const clearData = async (path = "") => {
  try {
    var whiteList = ["lastBuildUpdateDate", "salonUserInformation"];
    var keysToRemove = [];
    var localStorageLength = localStorage.length;
    var whiteListPaths = ["/admin","/"];
    if (whiteListPaths.includes(path)) {
      for (let index = 0; index < localStorageLength; index++) {
        const key = localStorage.key([index]);
        if (key && !whiteList.includes(key)) {
          keysToRemove.push(key);
        }
      }
    } else if (path === "/shop") {
      keysToRemove = ["isNavToggled", "shop-user", "shop_selected_venue_id"];
    } else if (path === "/salon") {
      keysToRemove = ["isNavToggled", "salon-user", "salon-calendar-view"];
    }
    for (let index = 0; index < keysToRemove.length; index++) {
      const element = keysToRemove[index];
      if((path === "/") && (element === "user_id" || element === "--rg_user_auth")){
        localStorage.removeItem(element);
      }

      if((path === "/admin") && (element === "user" || element === "--rg_admin_auth" || element === "--admin-lang")){
      localStorage.removeItem(element);
      }
    }
  } catch (error) {
    // Error while removing
  }
};
