import Api from "../../../api";
import { fetchSuccess, loading } from "../../../utils/action.util";
import { API_ADMINLOGIN } from "../../../api/constants";
import { PRINT } from "../../../utils";

export const ADMINLOGIN_LOADING = "ADMINLOGIN_LOADING";
export const ACTION_ADMINLOGIN_SUCCESS = "ACTION_ADMINLOGIN_SUCCESS";

export function actionAdminLogin(params) {
  return (dispatch, getState) =>
    Api.post(API_ADMINLOGIN, params)
      .then((res) => {
        const data = res;
        PRINT("ACTION RES", data);
        dispatch(fetchSuccess(ACTION_ADMINLOGIN_SUCCESS, { data }));
      })
      .catch((reason) => {
        /** Handle Error */
        var data = {
          message: "",
        };
        dispatch(loading(ADMINLOGIN_LOADING, { loading: false, data }));
        return reason;
      });
}
