import Api from "../../../../api";
import { fetchSuccess, loading } from "../../../../utils/action.util";
import { API_ADDSTAFFAUTHORIZATIONGROUP } from "../../../../api/constants";

export const ADDSTAFFAUTHORIZATIONGROUP_LOADING =
  "ADDSTAFFAUTHORIZATIONGROUP_LOADING";
export const ACTION_ADDSTAFFAUTHORIZATIONGROUP_SUCCESS =
  "ACTION_ADDSTAFFAUTHORIZATIONGROUP_SUCCESS";

export function actionAddStaffAuthorizationGroup(params) {
  return (dispatch, getState) =>
    Api.post(API_ADDSTAFFAUTHORIZATIONGROUP, params)
      .then((res) => {
        const data = res;
        dispatch(
          fetchSuccess(ACTION_ADDSTAFFAUTHORIZATIONGROUP_SUCCESS, { data })
        );
      })
      .catch((reason) => {
        /** Handle Error */
        var data = {
          message: "",
        };
        dispatch(
          loading(ADDSTAFFAUTHORIZATIONGROUP_LOADING, { loading: false, data })
        );
        return reason;
      });
}
