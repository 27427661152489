import {
    ACTION_OBJECTSETTINGCREATE_SUCCESS,
    ACTION_OBJECTSETTINGLIST_SUCCESS
    } from './action';
    import createReducer from '../../../../reducers/createReducer';
    
    const INITIAL_STATE = {
            data: [],
            loading: false
        };
    
    const ObjectSettingReducer = createReducer(
        INITIAL_STATE,
        {
         [ACTION_OBJECTSETTINGCREATE_SUCCESS](state, action) {
            return {
              ...state,
              data: action.payload.data,
              tag: ACTION_OBJECTSETTINGCREATE_SUCCESS,
              loading: false
            };
          },
          [ACTION_OBJECTSETTINGLIST_SUCCESS](state, action) {
            return {
              ...state,
              data: action.payload.data,
              tag: ACTION_OBJECTSETTINGLIST_SUCCESS,
              loading: false
            };
          },
        }
    );
    export default ObjectSettingReducer;