/* eslint-disable no-unused-vars */
/* eslint-disable no-script-url */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Component } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import $ from "jquery";
import validator from "validator";
import { SaveAlert } from "../../../utils/Swal";
import moment from "moment";

import { actionGetReservationByResNum } from "./action";

var parent = null;

class ConfirmReservationScreen extends Component {
  constructor(props) {
    super(props);
    parent = this;
    this.state = {
      reservation: [],
    };
  }

  async componentWillMount() {}
  async componentDidMount() {
    const { location } = this.props;
    const parts = location.pathname.split("/");
    var lastSegment = parts.pop() || parts.pop();
    const res = await this.props.actionGetReservationByResNum(lastSegment);
    if (res?.data) {
      this.setState({ reservation: res.data });
    }
    // const parts = urlParams.split("/");
    // var lastSegment = parts.pop() || parts.pop();
  }
  async componentWillReceiveProps(nextProps) {}

  handleSubmit(event) {
    event.preventDefault();
  }

  render() {
    const { lang } = this.props;

    return (
      <section className="flexbox-container mt-5">
        <div className="col-12 d-flex  align-items-center justify-content-center">
          <div className="p-0">
            <div className="card px-1 py-1 m-0">
              {this.state.reservation.length > 0 &&
                this.state.reservation[0].reservation_status_ID === 1 && (
                  <div className="card-content">
                    <h5 className="font-weight-bold">
                      {lang?.YourReservationHasBeenBooked}
                    </h5>
                    <p className="card-subtitle text-muted mt-2 mb-2 primary">
                      <span>
                        {lang?.Name}: {this.state.reservation[0]?.name}
                      </span>
                    </p>
                    <p className="card-subtitle text-muted mt-2 mb-2 primary">
                      <span>
                        {lang?.EmailAddress}: {this.state.reservation[0]?.email}
                      </span>
                    </p>
                    <p className="card-subtitle text-muted mt-2 mb-2 primary">
                      <span>
                        {lang?.PhoneNumber}:{" "}
                        {this.state.reservation[0]?.phone_number}
                      </span>
                    </p>
                    <p className="card-subtitle text-muted mt-2 mb-2 primary">
                      <span>
                        {lang?.ReservationNumber}:{" "}
                        {this.state.reservation[0]?.reservation_number}
                      </span>
                    </p>
                    <p className="card-subtitle text-muted mt-2 mb-2 primary">
                      <span>
                        {lang?.ArrivalDate}:{" "}
                        {moment(this.state.reservation[0]?.start_date).format(
                          "DD-MM-Y"
                        )}
                      </span>
                    </p>
                    <p className="card-subtitle text-muted mt-2 mb-2 primary">
                      <span>
                        {lang?.DepartureDate}:{" "}
                        {moment(this.state.reservation[0]?.end_date).format(
                          "DD-MM-Y"
                        )}
                      </span>
                    </p>
                    <p className="card-subtitle text-muted mt-2 mb-2 primary">
                      <span>
                        {lang?.NumOfNights}: {this.state.reservation[0]?.days}
                      </span>
                    </p>
                    <p className="card-subtitle text-muted mt-2 mb-2 primary">
                      <span>
                        {lang?.TotalAmount}: {"€ "}
                        {String(
                          this.state.reservation[0]?.reservation_amount
                        ).replace(".", ",")}
                      </span>
                    </p>
                    <div className="row mt-1">
                      <div className="col-md-12">
                        <button
                          type="submit"
                          className="w-100 btn btn-primary text-uppercase"
                          onClick={() => {
                            parent.props.history.push({
                              pathname: "/view-your-reservation-history",
                            });
                          }}
                        >
                          {lang?.Close}
                        </button>
                      </div>
                    </div>
                  </div>
                )}
              {this.state.reservation.length > 0 &&
                this.state.reservation[0].reservation_status_ID === 2 &&
                this.state.reservation[0].payment_status === "Paid" && (
                  <div className="card-content">
                    <h5 className="font-weight-bold" style={{ width: "500px" }}>
                      {lang?.ReservationNotConfirmed}
                    </h5>
                    <div className="row mt-1">
                      <div className="col-md-12">
                        <button
                          type="submit"
                          className="w-100 btn btn-primary text-uppercase"
                          onClick={() => {
                            parent.props.history.push({
                              pathname: "/",
                            });
                          }}
                        >
                          {lang?.Close}
                        </button>
                      </div>
                    </div>
                  </div>
                )}

              {this.state.reservation.length > 0 &&
                this.state.reservation[0].reservation_status_ID === 3 &&
                this.state.reservation[0].payment_status === "Failed" && (
                  <div className="card-content">
                    <h5 className="font-weight-bold">
                      {lang?.SorryYourReservationHaveNotBooked}
                    </h5>
                    <div className="row mt-1">
                      <div className="col-md-12">
                        <button
                          type="submit"
                          className="w-100 btn btn-primary text-uppercase"
                          onClick={() => {
                            parent.props.history.push({
                              pathname: "/reservation-completion",
                            });
                          }}
                        >
                          {lang?.Close}
                        </button>
                      </div>
                    </div>
                  </div>
                )}
            </div>
          </div>
        </div>
      </section>
    );
  }
}

const mapStateToProps = ({ VenueAppReducer }) => {
  return {
    lang: VenueAppReducer.lang,
  };
};

function mapDispatchToProps(dispatch) {
  return bindActionCreators({ actionGetReservationByResNum }, dispatch);
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ConfirmReservationScreen);
