import {
    ACTION_ADMINLOGIN_SUCCESS
} from './action';
import createReducer from '../../../reducers/createReducer';

const INITIAL_STATE = {
    data: [],
    loading: false
};

const AdminLoginReducer = createReducer(
    INITIAL_STATE,
    {
        [ACTION_ADMINLOGIN_SUCCESS](state, action) {
            return Object.assign({}, {
                ...state,
                data: action.payload.data,
                tag: ACTION_ADMINLOGIN_SUCCESS,
                loading: false
            });
        }
    }
);
export default AdminLoginReducer;