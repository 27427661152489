import Api from "../../../../api";
import { fetchSuccess, loading } from "../../../../utils/action.util";
import {
  API_STAFFAUTHORIZATIONGROUPLIST,
  API_DELETESTAFFAUTHORIZATIONGROUP,
  API_EDITSTAFFAUTHORIZATIONGROUP,
  API_ADDSTAFFAUTHORIZATIONGROUP,
} from "../../../../api/constants";

export const ADDSTAFFAUTHORIZATIONGROUP_LOADING =
  "ADDSTAFFAUTHORIZATIONGROUP_LOADING";
export const ACTION_ADDSTAFFAUTHORIZATIONGROUP_SUCCESS =
  "ACTION_ADDSTAFFAUTHORIZATIONGROUP_SUCCESS";
export const STAFFAUTHORIZATIONGROUPLIST_LOADING =
  "STAFFAUTHORIZATIONGROUPLIST_LOADING";
export const ACTION_STAFFAUTHORIZATIONGROUPLIST_SUCCESS =
  "ACTION_STAFFAUTHORIZATIONGROUPLIST_SUCCESS";
export const ACTION_DELETESTAFFAUTHORIZATIONGROUP_SUCCESS =
  "ACTION_DELETESTAFFAUTHORIZATIONGROUP_SUCCESS";
export function actionStaffAuthorizationGroupList() {
  return (dispatch, getState) =>
    Api.get(API_STAFFAUTHORIZATIONGROUPLIST)
      .then((res) => {
        const data = res;
        console.log(data);
        dispatch(
          fetchSuccess(ACTION_STAFFAUTHORIZATIONGROUPLIST_SUCCESS, { data })
        );
      })
      .catch((reason) => {
        /** Handle Error */
        var data = {
          message: "",
        };

        dispatch(
          loading(STAFFAUTHORIZATIONGROUPLIST_LOADING, { loading: false, data })
        );
        return reason;
      });
}

export function actionDeleteStaffAuthorizationGroup(params) {
  return (dispatch, getState) =>
    Api.delete(API_DELETESTAFFAUTHORIZATIONGROUP + params.id, params)
      .then((res) => {
        if (res.success) {
          dispatch(actionStaffAuthorizationGroupList());
          return res;
        } else {
          return res;
        }
      })
      .catch((reason) => {
        return reason;
      });
}

export function actionAddStaffAuthorizationGroup(params) {
  return (dispatch, getState) =>
    Api.post(API_ADDSTAFFAUTHORIZATIONGROUP, params)
      .then((res) => {
        const data = res;

        return data;
      })
      .catch((reason) => {
        /** Handle Error */
        var data = {
          message: "",
        };
        return reason;
      });
}

export function actionEditStaffAuthorizationGroup(params, id) {
  return (dispatch, getState) =>
    Api.post(API_EDITSTAFFAUTHORIZATIONGROUP + id, params)
      .then((res) => {
        const data = res;
        console.log(data);
        return data;
      })
      .catch((reason) => {
        /** Handle Error */
        var data = {
          message: "",
        };
        return reason;
      });
}
