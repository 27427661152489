import Api from "../../../api";
import { fetchSuccess, loading } from "../../../utils/action.util";
import { API_GET_WEBAPPCMS } from "../../../api/constants";

export const ADDWEBAPPCMS_LOADING = "ADDWEBAPPCMS_LOADING";
export const ACTION_ADDWEBAPPCMS_SUCCESS = "ACTION_WEBAPPCMS_SUCCESS";

export function actionGetWebAppLookFeel() {
  return (dispatch, getState) =>
    Api.get(API_GET_WEBAPPCMS)
      .then((res) => {
        const data = res;
        console.log(data);
        dispatch(fetchSuccess(ACTION_ADDWEBAPPCMS_SUCCESS, { data }));
      })
      .catch((reason) => {
        /** Handle Error */
        var data = {
          message: "",
        };

        dispatch(loading(ADDWEBAPPCMS_LOADING, { loading: false, data }));
        return reason;
      });
}
