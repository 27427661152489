import Api from "../../../api";
import { fetchSuccess, loading } from "../../../utils/action.util";
import {
  API_GETRESERVATION_BY_ID,
  API_FILTERRESERVATION_BY_ID,
  API_GETPAYMENTSTATUS,
} from "../../../api/constants";

export const RESERVATIONLIST_LOADING = "RESERVATIONLIST_LOADING";
export const ACTION_RESERVATIONLIST_SUCCESS = "ACTION_RESERVATIONLIST_SUCCESS";

export const FILTERRESERVATIONLIST_LOADING = "FILTErRESERVATIONLIST_LOADING";
export const ACTION_FILTERRESERVATIONLIST_SUCCESS =
  "ACTION_FILTERRESERVATIONLIST_SUCCESS";

export function actionGetReservationById(id) {
  return (dispatch, getState) =>
    Api.get(API_GETRESERVATION_BY_ID + id)
      .then((res) => {
        const data = res;
        console.log(data);
        dispatch(fetchSuccess(ACTION_RESERVATIONLIST_SUCCESS, { data }));
        return data.data;
      })
      .catch((reason) => {
        /** Handle Error */
        var data = {
          message: "",
        };

        dispatch(loading(RESERVATIONLIST_LOADING, { loading: false, data }));
        return reason;
      });
}

export function actionFilterReservation(params, id) {
  return (dispatch, getState) =>
    Api.post(API_FILTERRESERVATION_BY_ID + id, params)
      .then((res) => {
        const data = res;
        dispatch(fetchSuccess(ACTION_FILTERRESERVATIONLIST_SUCCESS, { data }));
        return data.data;
      })
      .catch((reason) => {
        /** Handle Error */
        var data = {
          message: "",
        };
        dispatch(
          loading(FILTERRESERVATIONLIST_LOADING, { loading: false, data })
        );
        return reason;
      });
}

export function actionGetPaymentStatus(body) {
  return (dispatch, getState) =>
    Api.post(API_GETPAYMENTSTATUS, body)
      .then((res) => {
        const data = res;
        return data;
      })
      .catch((reason) => {
        /** Handle Error */
        var data = {
          message: "",
        };
        return reason;
      });
}
