import {
  ACTION_ADDRESERVATIONCMS_SUCCESS,
  ACTION_EDITRESERVATIONCMS_SUCCESS,
  ACTION_RESERVATIONCMSLIST_SUCCESS,
} from "./action";
import createReducer from "../../../reducers/createReducer";

const INITIAL_STATE = {
  data: [],
  data2: [],
  dat3: [],
  loading: false,
};

const ReservationCMSReducer = createReducer(INITIAL_STATE, {
  [ACTION_RESERVATIONCMSLIST_SUCCESS](state, action) {
    return {
      ...state,
      data: action.payload.data,
      tag: ACTION_RESERVATIONCMSLIST_SUCCESS,
      loading: false,
    };
  },
  [ACTION_EDITRESERVATIONCMS_SUCCESS](state, action) {
    return {
      ...state,
      data2: action.payload.data,
      tag: ACTION_EDITRESERVATIONCMS_SUCCESS,
      loading: false,
    };
  },
  [ACTION_ADDRESERVATIONCMS_SUCCESS](state, action) {
    return {
      ...state,
      data3: action.payload.data,
      tag: ACTION_ADDRESERVATIONCMS_SUCCESS,
      loading: false,
    };
  },
});
export default ReservationCMSReducer;
