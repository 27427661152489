import { RESERVATIONLIST_SUCCESS } from "./action";
import createReducer from "../../../reducers/createReducer";

const INITIAL_STATE = {
  data: [],
  loading: false,
};

const ReservationCalendarListReducer = createReducer(INITIAL_STATE, {
  [RESERVATIONLIST_SUCCESS](state, action) {
    return {
      ...state,
      data: action.payload.data,
      tag: RESERVATIONLIST_SUCCESS,
      loading: false,
    };
  },
});
export default ReservationCalendarListReducer;
