import React, { Suspense, lazy } from "react";
import { Route, Switch } from "react-router-dom";
import HasPermission from "./HasPermission";
import { setCSSVars } from "../../utils";
import bgImg from "../../assets/header-img.jpg";

const AdminStaffsAccounDetails = lazy(() =>
  import("../../routes/admin/staff-accounts-and-mgmt")
);
const StaffAuthGroupsManagement = lazy(() =>
  import("../../routes/admin/staff-authorization-groups")
);
const AuthorizationGroups = lazy(() =>
  import("../../routes/admin/staff-accounts-and-mgmt/group-authorizations")
);
const UsersManagement = lazy(() =>
  import("../../routes/admin/users-management")
);
const ObjectsManagement = lazy(() =>
  import("../../routes/admin/objects-management")
);
const ObjectsSettings = lazy(() =>
  import("../../routes/admin/object-settings")
);
const WebAppCms = lazy(() => import("../../routes/admin/web-app-cms"));
const TermsConditionCMS = lazy(() =>
  import("../../routes/admin/terms-and-conditions")
);

const WebAppLookAndFeel = lazy(() =>
  import("../../routes/admin/web-app-look-and-feel")
);
const LandingPageScreen = lazy(() => import("../../routes/app/landing-screen"));
const UpdatePassword = lazy(() => import("../../routes/app/update-password"));
const ReservationDetails = lazy(() =>
  import("../../routes/admin/reservation-details")
);
const ReservationCalendar = lazy(() =>
  import("../../routes/admin/reservation-calendar")
);
const ReservationScreen = lazy(() =>
  import("../../routes/app/reservation-overview-screen")
);
const ReservationCompletionScreen = lazy(() =>
  import("../../routes/app/reservation-completion-screen")
);
const TermsCOnditionScreen = lazy(() =>
  import("../../routes/app/terms-condition-screen")
);
const PrivacyStatementScreen = lazy(() =>
  import("../../routes/app/privacy-statement-screen")
);
const ObjectUnavailabilty = lazy(() =>
  import("../../routes/app/make-reservation-calender")
);
const ReservationHistory = lazy(() =>
  import("../../routes/app/reservation-history")
);

const UpdateReservation = lazy(() =>
  import("../../routes/app/reservation-update-screen")
);

const ChangeReservation = lazy(() =>
  import("../../routes/app/change-reservation")
);

const ChangeReservationSame = lazy(() =>
  import("../../routes/app/change-reservation-same-screen")
);

const ChangeReservationPaymentScreen = lazy(() =>
  import("../../routes/app/change-reservation-payment-screen")
);

const ChangeReservationAddOnServices = lazy(() =>
  import("../../routes/app/reservation-housekeeping-update")
);

const CancelReservation = lazy(() =>
  import("../../routes/app/cancel-reservation")
);

const ServiceRequest = lazy(() =>
  import("../../routes/app/service-request-screen")
);
const ServiceRequestHistory = lazy(() =>
  import("../../routes/app/service-request-history")
);

const RefundManagement = lazy(() =>
  import("../../routes/admin/refund-management")
);

const ServiceRequestsOverview = lazy(() =>
  import("../../routes/admin/service-request-overview")
);

const ReservationCms = lazy(() => import("../../routes/admin/reservation-cms"));
const HouseKeepPlanningListView = lazy(() =>
  import("../../routes/admin/housekeep-planning-listview")
);
const HouseKeepPlanningCalendarView = lazy(() =>
  import("../../routes/admin/house-keep-planning-calendarview")
);
const ServieRequestCms = lazy(() =>
  import("../../routes/admin/service-request-cms")
);
function Content(props) {
  var { match } = props;
  console.log(match);

  if (
    match.url === "/admin" ||
    String(window.location.host).startsWith("suitesadmin")
  ) {
    return (
      <div className="app-content content d-block parent-div-position">
        <div className="content-wrapper" id="content-wrapper">
          <Suspense fallback={<div>Loading...</div>}>
            {/* staff accounts and management */}
            <HasPermission screenId={6}>
              <Route
                path={`/admin/staff-management`}
                component={AdminStaffsAccounDetails}
              />
            </HasPermission>

            {/* staff auth groups management */}
            {/* <HasPermission screenId={2}>
                <Route
                  path={`${match.url}`}
                  component={StaffAuthGroupsManagement}
                  exact
                />
              </HasPermission> */}

            {/* <HasPermission screenId={2}>
                <Route
                  path={`${match.url}/login`}
                  component={StaffAuthGroupsManagement}
                  exact
                />
              </HasPermission>
              <HasPermission screenId={2}>
                <Route
                  path={`${match.url}/dashboard`}
                  component={StaffAuthGroupsManagement}
                  exact
                />
              </HasPermission> */}

            <HasPermission screenId={7}>
              <Route
                path={`/admin/authorization-groups-management`}
                component={StaffAuthGroupsManagement}
              />
            </HasPermission>

            {/* authorization groups */}
            <HasPermission screenId={8}>
              <Route
                path={`/admin/staff-authorization-groups`}
                component={AuthorizationGroups}
              />
            </HasPermission>

            {/* users management */}
            <HasPermission screenId={5}>
              <Route
                path={`/admin/users-management`}
                component={UsersManagement}
              />
            </HasPermission>

            <HasPermission screenId={9}>
              <Route
                path={`/admin/objects-management`}
                component={ObjectsManagement}
              />
            </HasPermission>

            <HasPermission screenId={10}>
              <Route
                path={`/admin/object-settings`}
                component={ObjectsSettings}
              />
            </HasPermission>

            <HasPermission screenId={3}>
              <Route path={`/admin/web-app-cms`} component={WebAppCms} />
            </HasPermission>
            <HasPermission screenId={17}>
              <Route
                path={`/admin/terms-and-conditions`}
                component={TermsConditionCMS}
              />
            </HasPermission>
            <HasPermission screenId={4}>
              <Route
                path={`/admin/web-app-look-and-feel`}
                component={WebAppLookAndFeel}
              />
            </HasPermission>
            <HasPermission screenId={1}>
              <Route
                path={`/admin/reservation-details`}
                component={ReservationDetails}
              />
            </HasPermission>
            <HasPermission screenId={2}>
              <Route
                path={`/admin/reservation-calendar`}
                component={ReservationCalendar}
              />
            </HasPermission>
            <HasPermission screenId={11}>
              <Route
                path={`/admin/refund-management`}
                component={RefundManagement}
              />
            </HasPermission>
            <HasPermission screenId={12}>
              <Route
                path={`/admin/service-requests-overview`}
                component={ServiceRequestsOverview}
              />
            </HasPermission>
            <HasPermission screenId={13}>
              <Route
                path={`/admin/reservation-cms`}
                component={ReservationCms}
              />
            </HasPermission>
            <HasPermission screenId={14}>
              <Route
                path={`/admin/house-keep-planning-list-view`}
                component={HouseKeepPlanningListView}
              />
            </HasPermission>
            <HasPermission screenId={15}>
              <Route
                path={`/admin/house-keep-planning-calendar-view`}
                component={HouseKeepPlanningCalendarView}
              />
            </HasPermission>
            <HasPermission screenId={16}>
              <Route
                path={`/admin/service-request-cms`}
                component={ServieRequestCms}
              />
            </HasPermission>
          </Suspense>
        </div>
      </div>
    );
  } else {
    return (
      <div
        className="app-content content d-block parent-div-position"
        style={{ backgroundColor: "white", marginTop: "50px" }}
      >
        <img
          src={bgImg}
          alt="msbs"
          style={{
            // backgroundImage: `url(header-img.jpg)`,
            marginTop: "39px",
            height: "auto",
            width: "100%",
          }}
        />
        {/* <div
          style={{
            backgroundImage: `url(header-img.jpg)`,
            marginTop: "39px",
            height: "90px",
            backgroundPosition: "center",
            backgroundSize: "cover",
            backgroundRepeat: "no-repeat",
          }}
        ></div> */}
        <div
          className="content-wrapper"
          id="content-wrapper"
          style={{ height: "100%" }}
        >
          <Suspense fallback={<div>Loading...</div>}>
            {/* staff accounts and management */}

            <HasPermission screenId={9}>
              <Route path="/" component={LandingPageScreen} exact />
            </HasPermission>
            <HasPermission screenId={10}>
              <Route path="/update-password" component={UpdatePassword} />
            </HasPermission>
            <HasPermission screenId={11}>
              <Route
                path="/reservation-overview"
                component={ReservationScreen}
              />
            </HasPermission>
            <HasPermission screenId={12}>
              <Route
                path="/reservation-completion"
                component={ReservationCompletionScreen}
              />
            </HasPermission>
            <HasPermission screenId={22}>
              <Route
                path="/terms-and-conditions"
                component={TermsCOnditionScreen}
              />
            </HasPermission>
            <HasPermission screenId={24}>
            <Route
              path="/privacy-statement"
              component={PrivacyStatementScreen}
            />
          </HasPermission>
            <HasPermission screenId={13}>
              <Route path="/make-reservation" component={ObjectUnavailabilty} />
            </HasPermission>
            <HasPermission screenId={14}>
              <Route
                path="/view-your-reservation-history"
                component={ReservationHistory}
              />
            </HasPermission>
            <HasPermission screenId={15}>
              <Route
                path="/change-reservation-1"
                component={UpdateReservation}
              />
            </HasPermission>
            <HasPermission screenId={16}>
              <Route
                path="/change-reservation-2"
                component={ChangeReservation}
              />
            </HasPermission>
            <HasPermission screenId={23}>
              <Route
                path="/change-reservation-3"
                component={ChangeReservationSame}
              />
            </HasPermission>
            <HasPermission screenId={17}>
              <Route
                path="/change-reservation-payment"
                component={ChangeReservationPaymentScreen}
              />
            </HasPermission>

            <HasPermission screenId={17}>
              <Route
                path="/change-add-on-services"
                component={ChangeReservationAddOnServices}
              />
            </HasPermission>
            <HasPermission screenId={18}>
              <Route path="/cancel-reservation" component={CancelReservation} />
            </HasPermission>
            <HasPermission screenId={19}>
              <Route
                path="/submit-service-request"
                component={ServiceRequest}
              />
            </HasPermission>
            <HasPermission screenId={20}>
              <Route
                path="/service-request-history"
                component={ServiceRequestHistory}
              />
            </HasPermission>
          </Suspense>
        </div>
      </div>
    );
  }
}

export default Content;
