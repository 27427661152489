import {
ACTION_FORGOTPASSWORD_SUCCESS
} from './action';
import createReducer from '../../../reducers/createReducer';

const INITIAL_STATE = {
        data: [],
        loading: false
    };

const ForgotPasswordReducer = createReducer(
	INITIAL_STATE,
	{
		[ACTION_FORGOTPASSWORD_SUCCESS](state, action) {
            return Object.assign({}, {
                ...state,
                data: action.payload.data,
                tag: ACTION_FORGOTPASSWORD_SUCCESS,
                loading: false
            });
        }
	}
);
export default ForgotPasswordReducer;