import Api from "../../../api";
import { fetchSuccess, loading } from "../../../utils/action.util";
import { API_GET_PRIVACYSTATEMENT } from "../../../api/constants";

export function actionGetPrivacyStatement() {
  return (dispatch, getState) =>
    Api.get(API_GET_PRIVACYSTATEMENT)
      .then((res) => {
        const data = res;
        console.log(data);
        return data;
      })
      .catch((reason) => {
        /** Handle Error */
        var data = {
          message: "",
        };

        return reason;
      });
}
