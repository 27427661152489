import { createBrowserHistory } from "history";
import { applyMiddleware, createStore } from "redux";
import { routerMiddleware } from "connected-react-router";
import createRootReducer from "../reducers";
import thunkMiddleWare from "redux-thunk";

export const history = createBrowserHistory({ basename: "/" });

export default function configureStore(preloadedState) {
  const store = createStore(
    createRootReducer(history), // root reducer with router state
    preloadedState,
    applyMiddleware(thunkMiddleWare, routerMiddleware(history))
  );

  return store;
}
