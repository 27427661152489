import Api from "../../../../api";
import { fetchSuccess, loading } from "../../../../utils/action.util";
import {
  API_CREATE_OBJECTSETTING,
  API_GET_OBJECTSETTING,
  API_UPDATE_OBJECTSETTING,
} from "../../../../api/constants";

export const OBJECTSETTINGCREATE_LOADING = "ADDOBJECTSETTING_LOADING";
export const ACTION_OBJECTSETTINGCREATE_SUCCESS =
  "ACTION_ADDOBJECTSETTING_SUCCESS";

export const OBJECTSETTINGLIST_LOADING = "OBJECTSETTINGLIST_LOADING";
export const ACTION_OBJECTSETTINGLIST_SUCCESS =
  "ACTION_OBJECTSETTINGLIST_SUCCESS";

export function actionGetObjectSettingList() {
  return (dispatch, getState) =>
    Api.get(API_GET_OBJECTSETTING)
      .then((res) => {
        const data = res;
        console.log(data);
        dispatch(fetchSuccess(ACTION_OBJECTSETTINGLIST_SUCCESS, { data }));
      })
      .catch((reason) => {
        /** Handle Error */
        var data = {
          message: "",
        };

        dispatch(loading(OBJECTSETTINGLIST_LOADING, { loading: false, data }));
        return reason;
      });
}

export function actionAddObjectSetting(params) {
  return (dispatch, getState) =>
    Api.post(API_CREATE_OBJECTSETTING, params)
      .then((res) => {
        const data = res;
        dispatch(fetchSuccess(ACTION_OBJECTSETTINGCREATE_SUCCESS, { data }));
        return data.data;
      })
      .catch((reason) => {
        /** Handle Error */
        var data = {
          message: "",
        };
        dispatch(
          loading(OBJECTSETTINGCREATE_LOADING, { loading: false, data })
        );
        return reason;
      });
}

export function actionEditObjectSetting(params) {
  return (dispatch, getState) =>
    Api.post(API_UPDATE_OBJECTSETTING + "/" + params.id, params)
      .then((res) => {
        const data = res;
        dispatch(fetchSuccess(ACTION_OBJECTSETTINGCREATE_SUCCESS, { data }));
        return data;
      })
      .catch((reason) => {
        /** Handle Error */
        var data = {
          message: "",
        };
        dispatch(
          loading(OBJECTSETTINGCREATE_LOADING, { loading: false, data })
        );
        return reason;
      });
}
