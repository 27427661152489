import Api from "../../../api";
import { fetchSuccess, loading } from "../../../utils/action.util";
import { API_ADDRESERVATION } from "../../../api/constants";

export const ADDRESERVATION_LOADING = "ADDRESERVATION_LOADING";
export const ACTION_ADDRESERVATION_SUCCESS = "ACTION_ADDRESERVATION_SUCCESS";

export function actionAddReservation(params) {
  return (dispatch, getState) =>
    Api.post(API_ADDRESERVATION, params)
      .then((res) => {
        const data = res;
        dispatch(fetchSuccess(ACTION_ADDRESERVATION_SUCCESS, { data }));
        return data;
      })
      .catch((reason) => {
        /** Handle Error */
        var data = {
          message: "",
        };
        dispatch(loading(ADDRESERVATION_LOADING, { loading: false, data }));

        return reason;
      });
}
