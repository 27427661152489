import Api from "../../../api";
import {
  API_CREATE_USER,
  API_GET_USERS,
  API_UPDATE_USER,
  API_DELETE_UPDATE_USER,
  API_CONFIRM_DELETE_USER,
  API_GET_USER_FORM_CONFIG,
  API_UPDATE_USER_FORM_CONFIG,
  API_UPDATE_USER_FORM_FIELDS_SORT,
} from "../../../api/constants";

export const ACTION_GET_USERS = "ACTION_GET_USERS";
export const ACTION_GET_USER_FORM_CONFIG = "ACTION_GET_USER_FORM_CONFIG";
export const ACTION_UPDATE_USER_FORM_CONFIG = "ACTION_UPDATE_USER_FORM_CONFIG";
export const ACTION_UPDATE_USER_FORM_FIELDS_SORT =
  "ACTION_UPDATE_USER_FORM_FIELDS_SORT";

export const actionGetUserFormConfig = (adminId) => async (dispatch) => {
  try {
    const response = await Api.get(`${API_GET_USER_FORM_CONFIG}/${adminId}`);
    dispatch({
      type: ACTION_GET_USER_FORM_CONFIG,
      payload: response.data,
    });
    return response;
  } catch (error) {
    throw error;
  }
};

export const actionUpdateUserFormConfigs =
  (data, adminId) => async (dispatch) => {
    try {
      const response = await Api.put(
        `${API_UPDATE_USER_FORM_CONFIG}/${adminId}`,
        data
      );
      dispatch({
        type: ACTION_UPDATE_USER_FORM_CONFIG,
        payload: response.data,
      });
      return response;
    } catch (err) {}
  };

export const actionUpdateUserFormFieldsSort = (data) => async (dispatch) => {
  try {
    const response = await Api.put(`${API_UPDATE_USER_FORM_FIELDS_SORT}`, data);
    dispatch({
      type: ACTION_UPDATE_USER_FORM_FIELDS_SORT,
      payload: response.data,
    });
    return response;
  } catch (err) {}
};

export function actionAddUser(data) {
  return (dispatch, getState) =>
    Api.post(API_CREATE_USER, data)
      .then((res) => {
        const data = res;
        console.log(data);
        return data;
      })
      .catch((reason) => {
        return reason;
      });
}

export function actionUpdateUser(data, userId) {
  return (dispatch, getState) =>
    Api.post(`${API_UPDATE_USER}/${userId}`, data)
      .then((res) => {
        const data = res;
        console.log(data);
        return data;
      })
      .catch((reason) => {
        return reason;
      });
}

export const actionGetUsers = (adminId) => async (dispatch) => {
  const response = await Api.get(API_GET_USERS);
  dispatch({ type: ACTION_GET_USERS, payload: response.data });
  return response;
};

export function actionDeleteUser(userId) {
  return (dispatch, getState) =>
    Api.delete(`${API_DELETE_UPDATE_USER}/${userId}`)
      .then((res) => {
        const data = res;
        console.log(data);
        return data;
      })
      .catch((reason) => {
        /** Handle Error */
        var data = {
          message: "",
        };
      });
}

export function actionConfirmDeleteUser(userId) {
  return (dispatch, getState) =>
    Api.delete(`${API_CONFIRM_DELETE_USER}/${userId}`)
      .then((res) => {
        const data = res;
        console.log(data);
        return data;
      })
      .catch((reason) => {
        /** Handle Error */
        var data = {
          message: "",
        };
      });
}
