import Api from "../../api";
import { fetchSuccess, loading } from "../../utils/action.util";
import {
  API_GET_APP_LANGUAGE,
  API_VENUE_DETAILS_BY_VENUE_CODE,
  API_GETCMSSTYLES,
  API_GET_APP_LANGUAGE_WITHOUT_LOCALE,
} from "../../api/constants";

export const VENUEAPP_LOADING = "VENUEAPP_LOADING";
export const ACTION_APP_VENUE_DETAILS_BY_VENUE_CODE_SUCCESS =
  "ACTION_APP_VENUE_DETAILS_BY_VENUE_CODE_SUCCESS";
export const ACTION_GET_LANG_JSON = "ACTION_GET_LANG_JSON";
export const ACTION_GET_STYLES = "ACTION_GET_STYLES";
export const ACTION_GET_STYLESLOADING = "ACTION_GET_STYLESLOADING";

export function actionVenueDetailFromCode(params) {
  return (dispatch, getState) =>
    Api.post(API_VENUE_DETAILS_BY_VENUE_CODE, params)
      .then((res) => {
        const data = res;
        dispatch(
          fetchSuccess(ACTION_APP_VENUE_DETAILS_BY_VENUE_CODE_SUCCESS, { data })
        );
      })
      .catch((reason) => {
        /** Handle Error */
        var data = {
          message: "",
        };
        dispatch(loading(VENUEAPP_LOADING, { loading: false, data }));
      });
}

export function actionGetLanguageJson() {
  return (dispatch, getState) =>
    Api.get(API_GET_APP_LANGUAGE)
      .then((response) => {
        const data = response;
        console.log(response);
        dispatch(fetchSuccess(ACTION_GET_LANG_JSON, { data }));
      })
      .catch((reason) => {
        console.log(reason, "reason");
        dispatch(loading(VENUEAPP_LOADING));
      });
}

export function actionGetStyles() {
  return (dispatch, getState) =>
    Api.get(API_GETCMSSTYLES)
      .then((response) => {
        const data = response;
        console.log(response);
        dispatch(fetchSuccess(ACTION_GET_STYLES, { data }));
      })
      .catch((reason) => {
        dispatch(loading(ACTION_GET_STYLESLOADING));
      });
}
