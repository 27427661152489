export const getUrlByScreenId = (id) => {
  var screens = [
    { id: 1, url: "/admin/reservation-details" },
    { id: 2, url: "/admin/reservation-calendar" },
    { id: 3, url: "/admin/web-app-cms" },
    { id: 4, url: "/admin/web-app-look-and-feel" },
    { id: 5, url: "/admin/users-management" },
    { id: 6, url: "/admin/staff-management" },
    { id: 7, url: "/admin/authorization-groups-management" },
    { id: 8, url: "/admin/staff-authorization-groups" },
    { id: 9, url: "/admin/objects-management" },
    { id: 10, url: "/admin/object-settings" },
    { id: 11, url: "/admin/refund-management" },
    { id: 12, url: "/admin/service-requests-overview" },
    { id: 13, url: "/admin/reservation-cms" },
    { id: 14, url: "/admin/house-keep-planning-list-view" },
    { id: 15, url: "/admin/house-keep-planning-calendar-view" },
    { id: 16, url: "/admin/service-request-cms" },
    { id: 17, url: "/admin/terms-and-conditions" },
    { id: null, url: "/admin/staff-management" },
  ];
  return screens.find((r) => r.id === id)?.url;
};
