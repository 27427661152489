import {
    ACTION_CHECKUSERNAME_SUCCESS
  } from './action';
  import createReducer from '../../../reducers/createReducer';
  
  const INITIAL_STATE = {
    data: [],
    loading: false
  };
  
  const CheckUsernameReducer = createReducer(
    INITIAL_STATE,
    {
        [ACTION_CHECKUSERNAME_SUCCESS](state, action) {
            return Object.assign({}, {
                ...state,
                data: action.payload.data,
                tag: ACTION_CHECKUSERNAME_SUCCESS,
                loading: false
            });
        }
    }
  );
  export default CheckUsernameReducer;