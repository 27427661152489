import React, { Component } from "react";
import $ from "jquery";
let last_window_position = 0;
class stickyPage extends Component {
  componentDidMount() {
    this.props.sendScrollPosition &&
      this.props.sendScrollPosition(this.sendScrollPosition);
    $("body").css({ overflow: "hidden" });
    $("#content-wrapper").css({
      overflow: "scroll",
      height: "95vh",
      "padding-top": "0px",
    });
    $("#content-wrapper").on("scroll", function (event) {
      var scroll = event.target.scrollTop;
      last_window_position = scroll;
      if (scroll > 0) {
        $(".content-sticky-header").css({ "background-color": "#f4f6fa" });
      } else if (scroll < 10) {
        $(".content-sticky-header").css({ background: "transparent" });
      }
    });
  }
  componentWillUnmount() {
    $("body").removeAttr("style");
    $("#content-wrapper").removeAttr("style");
  }
  sendScrollPosition = () => {
    return last_window_position;
  };
  render() {
    return <div className="content-sticky-page" id="content-sticky-page"></div>;
  }
}

export default stickyPage;
