import Api from "../../../../api";
import { fetchSuccess, loading } from "../../../../utils/action.util";
import {
  API_GET_OBJECTS,
  API_DELETE_OBJECTS,
  API_UPDATE_STATUS,
} from "../../../../api/constants";

export const OBJECTLIST_LOADING = "ADDOBJECT_LOADING";
export const ACTION_OBJECTLIST_SUCCESS = "ACTION_OBJECTLIST_SUCCESS";

export const ACTION_EDITSTATUS_LOADING = "ACTIONEDITSTATUS_LOADING";
export const ACTION_EDITSTATUS_SUCCESS = "ACTIONEDITSTATUS_SUCCESS";

export function actionGetObjectList() {
  return (dispatch, getState) =>
    Api.get(API_GET_OBJECTS)
      .then((res) => {
        const data = res;
        console.log(data);
        dispatch(fetchSuccess(ACTION_OBJECTLIST_SUCCESS, { data }));
      })
      .catch((reason) => {
        /** Handle Error */
        var data = {
          message: "",
        };

        dispatch(loading(OBJECTLIST_LOADING, { loading: false, data }));
        return reason;
      });
}

export const actionDeleteObject = (id) => async (dispatch) => {
  try {
    await Api.delete(`${API_DELETE_OBJECTS}/${id}`);
  } catch (error) {
    throw error;
  }
};

export function actionEditStatus(id, status) {
  return (dispatch, getState) =>
    Api.post(API_UPDATE_STATUS + id, status)
      .then((res) => {
        const data = res;
        console.log(data);
        dispatch(fetchSuccess(ACTION_EDITSTATUS_SUCCESS, { data }));
      })
      .catch((reason) => {
        /** Handle Error */
        var data = {
          message: "",
        };
        dispatch(loading(ACTION_EDITSTATUS_LOADING, { loading: false, data }));
        return reason;
      });
}
