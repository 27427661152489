import React, { Component } from "react";
import PropTypes from "prop-types";
import { ConnectedRouter } from "connected-react-router";
import { Provider } from "react-redux";
import { Route, Redirect } from "react-router-dom";
import ScrollToTop from "./components/scroll_to_top";
import Root from "./routes";
import "suneditor/dist/css/suneditor.min.css";
import "react-datepicker/dist/react-datepicker.css";
import "./assets/scss/App.scss";
import "./assets/scss/responsiveness.scss";
import { registerLocale } from "react-datepicker";
import nl from "date-fns/locale/nl";
import en from "date-fns/locale/en-GB";
import { LastLocationProvider } from "react-router-last-location";
registerLocale("nl", nl);
registerLocale("en", en);
let hashUrlParts = [];
class App extends Component {
  componentWillMount() {
    const { history } = this.props;
    const { hash } = history.location;
    hashUrlParts = hash.split("/");
    if (hashUrlParts[0] === "#!") {
      hashUrlParts.splice(0, 1);
    }
  }

  render() {

    const { store, history } = this.props;
    const { hash } = history.location;
    return (
      <Provider store={store}>
        <ConnectedRouter history={history}>
          <LastLocationProvider>
            <ScrollToTop>
              <Route path="/" component={Root} />
              {hash && (
                <Redirect
                  to={{
                    pathname: hashUrlParts.join("/"),
                  }}
                />
              )}
            </ScrollToTop>
          </LastLocationProvider>
        </ConnectedRouter>
      </Provider>
    );
  }
}

App.propTypes = {
  store: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
};

export default App;
