import $ from "jquery";
import { setCSSVars } from "./index";

export async function toggleSideNav() {
  var toggled = await localStorage.getItem("isNavToggled");
  if (toggled === "true") {
    $("body").addClass("hide-sideNav");
  } else {
    $("body").removeClass("hide-sideNav");
  }
}
export async function setSideNavToggle(url) {
  var toggled = await localStorage.getItem("isNavToggled");
  if (toggled === "true") {
    if (url === "/") {
      setCSSVars({ "--header-margin-left": "240px" });
      setCSSVars({ "--display": "block" });
    }
    if (url === "/admin") {
      setCSSVars({ "--admin-margin-left": "280px" });
      setCSSVars({ "--admin-display": "block" });
    }
    await localStorage.setItem("isNavToggled", "false");
  } else {
    if (url === "/") {
      setCSSVars({ "--header-margin-left": "0px" });
      setCSSVars({ "--display": "none" });
    }
    if (url === "/admin") {
      setCSSVars({ "--admin-margin-left": "0px" });
      setCSSVars({ "--admin-display": "none" });
    }
    await localStorage.setItem("isNavToggled", "true");
  }
  toggleSideNav();
}
