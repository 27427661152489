import React, { Component } from "react";
import suneditor from "suneditor";
import plugins from "suneditor/src/plugins";
import $ from "jquery";
import { RICH_TEXT_EDITOR_OPTIONS } from "../../constants";
import { GENERIC_FILE_UPLOAD, IMAGE_PATH } from "../../api/constants";
import { SaveAlert } from "../../utils/Swal";

let options = { ...RICH_TEXT_EDITOR_OPTIONS, plugins: plugins };
class RichTextEditor extends Component {
  constructor(props) {
    super(props);
    this.state = { html: "" };
    this.editor = null;
  }
  componentDidMount() {
    this.initializeEditor();
  }

  componentDidUpdate(prevProps) {
    if (
      prevProps.value !== this.props.value &&
      this.props.value !== this.state.html
    ) {
      this.setState(
        { html: this.props.value },
        () => this.editor && this.editor.setContents(this.props.value)
      );
    }
  }
  initializeEditor = () => {
    const { name } = this.props;
    let value = this.props.value;
    if (name !== undefined) {
      const initEditor = suneditor.init({
        ...options,
        linkProtocol: `${window.location.protocol}//`,
        historyStackDelayTime: 100,
      });
      var ref = "rich-text-editor-" + name;
      this.editor = initEditor.create(ref);
      this.editor.onChange = this.handleChange;
      this.editor.onImageUploadBefore = function (
        files,
        info,
        core,
        uploadHandler
      ) {
        try {
          resizeImage(files, core, info);
        } catch (err) {
          uploadHandler(err.toString());
        }
      };
      async function resizeImage(files, core, info) {
        const uploadFile = files[0];
        const img = document.createElement("img");
        const canvas = document.createElement("canvas");
        const reader = new FileReader();
        reader.onload = function (e) {
          img.src = e.target.result;
          img.onload = function () {
            let ctx = canvas.getContext("2d");
            ctx.drawImage(img, 0, 0);
            const MAX_WIDTH = 1000;
            const MAX_HEIGHT = 1000;
            let width = Number(info.inputWidth) || img.width;
            info.inputWidth = info.inputWidth || String(width);
            let height = Number(info.inputHeight) || img.height;
            info.inputHeight = info.inputHeight || String(height);
            if (width > height) {
              if (width > MAX_WIDTH) {
                height *= MAX_WIDTH / width;
                width = MAX_WIDTH;
              }
            } else {
              if (height > MAX_HEIGHT) {
                width *= MAX_HEIGHT / height;
                height = MAX_HEIGHT;
              }
            }
            canvas.width = width;
            canvas.height = height;
            ctx = canvas.getContext("2d");
            ctx.drawImage(img, 0, 0, width, height);
            canvas.toBlob(
              async function (blob) {
                let data = new FormData();
                var f = new File([blob], uploadFile.name);
                data.append("file", f);
                var res = await $.ajax({
                  data: data,
                  type: "POST",
                  url: GENERIC_FILE_UPLOAD,
                  cache: false,
                  contentType: false,
                  processData: false,
                });
                var respoonseImage = res?.result;
                const file = {name: respoonseImage?.name, size: respoonseImage?.size};
                const imageUrl = `data:image/${respoonseImage?.name};base64,${respoonseImage?.url}`;
                
                const response = {
                  result: [
                    {
                      ...file,
                      url: imageUrl,
                    },
                  ],
                };

                if (info.isUpdate) {
                  core.plugins.image.update_src.call(
                    core,
                    imageUrl,
                    info.element,
                    file
                  );
                  return;
                } else {
                  core.plugins.image.register.call(core, info, response);
                }
                core.closeLoading();
              },
              uploadFile.type,
              1
            );
          };
        };
        try {
          await reader.readAsDataURL(uploadFile);
        } catch (error) {
          SaveAlert("OnlyImageIsAccepted", "error");
          core.closeLoading();
        }
      }
      this.setState({
        html: value,
      });
    }
  };

  componentWillUnmount() {
    this.editor && this.editor.destroy();
  }
  handleChange = (html, core) => {
    this.setState({ html: html }, () => {
      this.props.onChange(html);
    });
  };
  render() {
    const { name, error, required, label, lang, normalize } = this.props;
    return (
      <div
        className={`form-group  ${error ? "invalid" : ""} ${
          normalize ? "mb-0" : ""
        }`}
        id={"fg-rich-text-editor-" + name}
      >
        <label className="font-weight-bold">
          {`${label || ""}${required ? "*" : ""}`}
        </label>
        <textarea id={"rich-text-editor-" + name}></textarea>
        <small class="error-msg-editor">{lang?.ThisFieldIsRequired}</small>
      </div>
    );
  }
}
export default React.memo(RichTextEditor);
