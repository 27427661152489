import React,{ Component } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { actionNewPassword, ACTION_NEWPASSWORD_SUCCESS } from "./action";
import { PRINT } from "../../../utils";
import $ from 'jquery';
import swal from "sweetalert";

var parent = null;

class NewPassword extends Component {
	constructor(props){
		super(props);
		parent = this;
		this.state = {
			password: '',
			confirm_password: ''
		};
	}

	componentWillMount() {
		PRINT('PROPSSSSSS', this.props.match.params.verify_id)
		this.setState({
			verify_id: this.props.match.params.verify_id
		})
	}

	async componentWillReceiveProps(nextProps) {
		const { NewPassword } = nextProps;

		if (NewPassword != null) {
			var NewPasswordResponse = Object.assign({}, NewPassword);
			if (NewPasswordResponse.result === true) {
				swal({
					title: 'New Password',
					text: 'Password updated successfully',
					icon: 'success',

				}).then( success => {
					parent.props.history.replace('/admin/login')
				})
			} else {
				swal({
					title: 'Message',
					text: NewPasswordResponse.message,
					icon: 'error'
				})
			}
		}
	}

	handleFormFields = (event) => {
		event.preventDefault();
		var { password, confirm_password } = this.state;
		switch (event.target.id) {
			case 'password':
				password = event.target.value
				break;
			case 'confirm_password':
				confirm_password = event.target.value
				break;
			default:
		}
		parent.setState({
			password,
			confirm_password
		})
	  };

	handleValidation() {
		const { password, confirm_password } = this.state;

		var status = true;
		$("#fg-password, #fg-confirm-password").removeClass("error");

		if (password.length === 0) {
			$("#fg-password").addClass("error");
			status = false;
		}
		if (confirm_password.length === 0) {
			$("#fg-confirm-password").addClass("error");
			status = false;
		}
		if (password !== confirm_password) {
			$("#fg-confirm-password").addClass("error");
			swal({
				title: 'Message',
				text: 'Password and Confirm Password must be same',
				icon: 'warning'
			})
			status = false;
		}
		return status;
	}

	async handleSubmit(event) {
		event.preventDefault();
		const {password, verify_id} = parent.state
		if(parent.handleValidation()) {

			var inputMap = new Map()
			inputMap.set('verification_code', verify_id)
			inputMap.set('password', password)

			var input = Object.fromEntries(inputMap.entries());
			PRINT('INPUT', input)
			await parent.props.actionNewPassword(input)
		}
	}


	render() {
		const {password, confirm_password} = this.state
		return (
			<section className="flexbox-container mt-5">
				<div className="col-12 d-flex align-items-center justify-content-center">
					<div className="col-lg-4 col-md-8 col-10 box-shadow-2 p-0">
						<div className="card border-grey border-lighten-3 px-1 py-1 m-0">
							<div className="card-header border-0"></div>
							<div className="card-content">
								<p className="card-subtitle text-muted text-center font-large-1 mx-2 mb-2 primary">
								<span>Admin New Password</span>
								</p>
								<div className="card-body">
									<form
										className="form-horizontal"
										novalidate
									>
										<fieldset className="form-group position-relative has-icon-left">
											<div className="form-group" id="fg-password">
												<input
													type="text"
													className="form-control"
													id="password"
													name="password"
													placeholder="New password"
													value={password}
													onChange={this.handleFormFields}
													required
												/>
												<div className="form-control-position">
													<i className="fa fa-key"></i>
												</div>
											</div>
										</fieldset>
										<fieldset className="form-group position-relative has-icon-left">
											<div className="form-group" id="fg-confirm-password">
												<input
													type="text"
													className="form-control"
													id="confirm_password"
													name="confirm_password"
													placeholder="Confirm password"
													value={confirm_password}
													onChange={this.handleFormFields}
													required
												/>
												<div className="form-control-position">
													<i className="fa fa-key"></i>
												</div>
											</div>
										</fieldset>
										<button
										type="submit"
										className="btn btn-outline-primary btn-block"
										onClick={this.handleSubmit}
										>
										<i className="ft-unlock"></i> Submit
										</button>
									</form>
								</div>
							</div>
						</div>
					</div>
				</div>
			</section>
		)
	}
}

function mapStateToProps(state) {

	var NewPassword = null;
	if (state.NewPasswordReducer != null && Object.keys(state.NewPasswordReducer).length > 0 && state.NewPasswordReducer.constructor === Object) {
		switch(state.NewPasswordReducer.tag) {
			case ACTION_NEWPASSWORD_SUCCESS:
				NewPassword = Object.assign({}, state.NewPasswordReducer.data);
				delete state.NewPasswordReducer;
				return { NewPassword };
			default:
				return { NewPassword };
		}
	}
}

function mapDispatchToProps(dispatch) {
	return bindActionCreators({actionNewPassword},dispatch)
}
export default connect(mapStateToProps, mapDispatchToProps)(NewPassword);