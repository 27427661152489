import {
    ACTION_ADDOBJECT_SUCCESS,
    ACTION_EDITOBJECT_SUCCESS
    } from './action';
    import createReducer from '../../../../reducers/createReducer';
    
    const INITIAL_STATE = {
            data: [],
            loading: false
        };
    
    const ObjectManagementAddReducer = createReducer(
        INITIAL_STATE,
        {
            [ACTION_ADDOBJECT_SUCCESS](state, action) {
                return Object.assign({}, {
                    ...state,
                    data: action.payload.data,
                    tag: ACTION_ADDOBJECT_SUCCESS,
                    loading: false
                });
            },
            [ACTION_EDITOBJECT_SUCCESS](state, action) {
                return Object.assign({}, {
                    ...state,
                    data: action.payload.data,
                    tag: ACTION_EDITOBJECT_SUCCESS,
                    loading: false
                });
            },
        }
    );
    export default ObjectManagementAddReducer;