import {
  ACTION_OBJECTSETTINGLIST_SUCCESS,
  ACTION_CALENDAR_SUCCESS,
  ACTION_OBJECTPRICELIST_SUCCESS,
} from "./action";
import createReducer from "../../reducers/createReducer";

const INITIAL_STATE = {
  data: [],
  data2: [],
  data3: [],
  loading: false,
};

const ObjectUnavailabiltyListReducer = createReducer(INITIAL_STATE, {
  [ACTION_OBJECTSETTINGLIST_SUCCESS](state, action) {
    return {
      ...state,
      data: action.payload.data,
      tag: ACTION_OBJECTSETTINGLIST_SUCCESS,
      loading: false,
    };
  },
  [ACTION_CALENDAR_SUCCESS](state, action) {
    return {
      ...state,
      data2: action.payload.data,
      tag: ACTION_CALENDAR_SUCCESS,
      loading: false,
    };
  },
  [ACTION_OBJECTPRICELIST_SUCCESS](state, action) {
    return {
      ...state,
      data3: action.payload.data,
      tag: ACTION_OBJECTPRICELIST_SUCCESS,
      loading: false,
    };
  },
});
export default ObjectUnavailabiltyListReducer;
