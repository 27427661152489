import packageJson from "../package.json";
import { setCookie, getCookie } from "./utils";
import { clearData } from "./utils/preferences";
import moment from "moment";
const resetCacheAndReload = async () => {
  if (window.caches) {
    window.caches.keys().then((names) => {
      for (const name of names) {
        caches.delete(name);
      }
    });
  }
  clearData();
  window.location.reload(true);
};
export function checkNewUpdates() {
  fetch("/meta.json")
    .then((response) => response.json())
    .then((meta) => {
      const latestVersionDate = meta.buildDate;

      try {
        buildDateGreaterThan(latestVersionDate);
      } catch (error) {
        console.error(error);
      }
    });
}

const buildDateGreaterThan = (latestDate) => {
  const momLatestDateTime = moment(latestDate);
  var cookieDate = getCookie("lastBuildUpdateDate");
  const momCurrentDateTime = moment(
    Number(cookieDate || packageJson?.buildDate)
  );
  if (momLatestDateTime.isAfter(momCurrentDateTime)) {
    // console.log({
    //   cookieDate,
    //   momLatestDateTime,
    //   momCurrentDateTime: momCurrentDateTime.toDate(),
    // });
    // debugger;
    setCookie("lastBuildUpdateDate", latestDate, 1000);
    resetCacheAndReload();
  }
};
