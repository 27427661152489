import createReducer from '../../../../reducers/createReducer';
import {ACTION_GET_SUPPORT_INFO, ACTION_SAVE_SUPPORT_REQUEST} from './SupportRequestActions';

const INITIAL_STATE = {
    apiIntroInfo: {}
};

const SupportRequestReducer = createReducer(INITIAL_STATE, {
    [ACTION_GET_SUPPORT_INFO](state, action) {
        return {
            ...state,
            
            apiIntroInfo: action.payload
        };
    },
    [ACTION_SAVE_SUPPORT_REQUEST](state, action) {
        return {
            ...state
        };
    },
});

export default SupportRequestReducer;
