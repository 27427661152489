import React, { Component, Suspense, lazy } from "react";
import PropTypes from "prop-types";
import { Route, Redirect } from "react-router-dom";
import AdminRoute from "./admin";
import AppRoute from "./app";
import ResetPassword from "./app/reset-password";
import ConfirmReservationScreen from "./app/reservation-confirmed-screen";

import Header from "../components/header";
import SideBar from "../components/sidebar";
import Content from "../components/content";
import Footer from "../components/footer";
import { ConnectedRouter } from "connected-react-router";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import { withLastLocation } from "react-router-last-location";
import { setCSSVars } from "../utils";

import {
  postLocationToParent,
  handleHistoryStackOnBrowserBack,
  onRouteChange,
} from "../utils";
import { checkNewUpdates } from "../CacheMiddleWare";
import {
  actionGetLanguageJson,
  ACTION_GET_LANG_JSON,
  actionGetStyles,
  ACTION_GET_STYLES,
} from "./app/action";

const blackListRoutes = ["admin", "salon", "venue", "shop"];
class Root extends Component {
  componentDidMount() {
    var eventMethod = window.addEventListener
      ? "addEventListener"
      : "attachEvent";
    var eventer = window[eventMethod];
    var messageEvent = eventMethod === "attachEvent" ? "onmessage" : "message";
    eventer(messageEvent, function (e) {
      if (e.data.event === "saveParentOrigin") {
        sessionStorage.setItem("parentOrigin", e.data.origin);
      }
      if (e.data.event === "saveParentHref") {
        sessionStorage.setItem("parentHref", e.data.href);
      }
    });
    checkNewUpdates();
    document.addEventListener("visibilitychange", function () {
      if (document.visibilityState === "hidden") {
        if (!window.location.pathname.includes("salon-app")) {
          localStorage.removeItem("commingFrom");
        }
      }
    });
  }

  async componentWillMount() {
    await this.props.actionGetStyles();
    await this.props.actionGetLanguageJson();
  }

  hexToRgbA(hex) {
    var c;
    if (/^#([A-Fa-f0-9]{3}){1,2}$/.test(hex)) {
      c = hex.substring(1).split("");
      if (c.length == 3) {
        c = [c[0], c[0], c[1], c[1], c[2], c[2]];
      }
      c = "0x" + c.join("");
      return (
        "rgba(" + [(c >> 16) & 255, (c >> 8) & 255, c & 255].join(",") + ",1)"
      );
    }
  }

  componentWillReceiveProps(nextProps) {
    const { Styles } = nextProps;

    //alert(Styles)
    console.log(Styles);
    if (Styles !== undefined) {
      if (Styles.length > 0) {
        setCSSVars({ "--button-color": Styles[0].web_app_button_color });
        setCSSVars({ "--font-color": Styles[0].editor_font_color });
        setCSSVars({ "--font-family": Styles[0].editor_font_family });
        setCSSVars({ "--fontSize": Styles[0].editor_font_size });
        setCSSVars({ "--button-text": Styles[0].web_app_button_text_color });
        //setCSSVars({ "--body-color": Styles[0].web_app_bg_color });
        var obj = {};
        obj["editor_font_family"] = Styles[0].editor_font_family;
        obj["editor_font_size"] = parseInt(Styles[0].editor_font_size);
        obj["editor_font_color"] = this.hexToRgbA(Styles[0].editor_font_color);
        localStorage.setItem("app_styles", JSON.stringify(obj));
      }
    }
  }

  componentDidUpdate(prevProps) {
    if (this.props !== prevProps) {
      checkNewUpdates();
    }
    const urlParts = this.props.location.pathname.split("/");
    if (blackListRoutes.indexOf(urlParts?.[1]) === -1) {
      postLocationToParent(prevProps, this.props);
      handleHistoryStackOnBrowserBack(this.props);
    }
    if (this.props?.lastLocation !== prevProps?.lastLocation) {
      var currentPathname = window.location.pathname;
      var previousPathname = this.props?.lastLocation?.pathname;
      onRouteChange(currentPathname, previousPathname);
    }
  }
  componentWillUnmount() {
    document.removeEventListener("visibilitychange", () => {});
  }
  render() {
    const { match, location } = this.props;
    var { props } = this;
    var { lang } = props;
    var pathUrl = `${
      String(window.location.host).startsWith("suitesadmin")
        ? "/"
        : `${match.url}admin`
    }`;
    let adminL = localStorage.getItem("user")?.length;
    if (location.pathname.includes("setpassword")) {
      return (
        <>
          <div className="app-class">
            <Route path={`${match.url}`} component={ResetPassword} />
          </div>
        </>
      );
    }
    if (location.pathname.includes("reservation-status")) {
      return (
        <>
          <div className="app-class">
            <Route path={`${match.url}`} component={ConfirmReservationScreen} />
          </div>
        </>
      );
    }

    console.log("windowlocationhost", window.location.host);
    console.log(
      "windowlocationhoststatus",
      String(window.location.host).startsWith("suitesadmin")
    );
    console.log("pathname", location.pathname);
    console.log("weburl", `${match.url}admin`);

    if (
      location.pathname.includes("/admin") ||
      String(window.location.host).startsWith("suitesadmin")
    ) {
      console.log("weburlstatus", `${match.url}admin`);
      console.log("pathUrl", pathUrl);
      return (
        <>
          <div className="admin-content">
            {!adminL && (
              <Redirect
                to={{
                  pathname: `/admin/login`,
                }}
              />
            )}

            <Route path={pathUrl} component={AdminRoute} />
          </div>
        </>
      );
    } else {
      let userL = localStorage.getItem("user_id")?.length;
      return (
        <>
          <div
            className="app-class"
            style={{ marginTop: "-100px", height: "100%" }}
          >
            {userL ? (
              <ConnectedRouter history={this.props.history}>
                <Header {...{ ...props, styles: this.props.Styles }} />
                <SideBar {...{ ...props, styles: this.props.Styles }} />
                <Content {...{ ...props, styles: this.props.Styles }} />
                {/* <Footer {...{ ...props, styles: this.props.Styles }} />

                <div
                  id="btn-actions"
                  className="invisible display-inline-block"
                >
                  <button
                    type="button"
                    class="btn btn-primary btn-sm round ml-1"
                  >
                    {lang?.Edit}
                  </button>
                  <button
                    type="button"
                    class="btn btn-danger btn-sm round ml-1"
                  >
                    {lang?.Inactivate}
                  </button>
                  <button
                    type="button"
                    class="btn btn-danger btn-delete btn-sm round ml-1"
                  >
                    {lang?.Delete}
                  </button>
                </div> */}
              </ConnectedRouter>
            ) : (
              <div className="app">
                <Route path={`${match.url}`} component={AppRoute} />
              </div>
            )}
          </div>
        </>
      );
    }
  }
}

const mapStateToProps = ({ VenueAppReducer }) => {
  var Styles = null;
  switch (VenueAppReducer.tag) {
    case ACTION_GET_LANG_JSON:
      return { lang: VenueAppReducer.lang };
    case ACTION_GET_STYLES:
      Styles = VenueAppReducer.data.data;
      return { lang: VenueAppReducer.lang, Styles };
    default:
      return { lang: VenueAppReducer.lang };
  }
};

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    { actionGetLanguageJson, actionGetStyles },
    dispatch
  );
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withLastLocation(Root));

Root.propTypes = {
  store: PropTypes.object,
  history: PropTypes.object.isRequired,
};

//export default ;
