import Api from '../../../api';
import { fetchSuccess, loading } from '../../../utils/action.util';
import { API_FORGOT_PASSWORD } from '../../../api/constants';

export const FORGOTPASSWORD_LOADING = 'FORGOTPASSWORD_LOADING';
export const ACTION_FORGOTPASSWORD_SUCCESS = 'ACTION_FORGOTPASSWORD_SUCCESS';

export function actionForgotPassword(params) {
    return (dispatch, getState) => Api.post(API_FORGOT_PASSWORD, params).then((res) => {
        const data = res;
        dispatch(fetchSuccess(ACTION_FORGOTPASSWORD_SUCCESS, { data }));
    }).catch((reason) => {
        /** Handle Error */
        var data = {
            message: ''
        }
        dispatch(loading(FORGOTPASSWORD_LOADING, { loading: false, data }));
    });
}
