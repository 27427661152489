import React from "react";

function StickyHeader(props) {
  const { children } = props;
  return (
    <div className="content-sticky-header sticky-header">
      {children && children}
    </div>
  );
}

export default React.memo(StickyHeader);
