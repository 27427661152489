/* eslint-disable no-unused-vars */
/* eslint-disable no-script-url */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Component } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import $ from "jquery";
import validator from "validator";
import { actionGetLanguageJson, ACTION_GET_LANG_JSON } from "../action";
import { actionResetPassword, ACTION_RESETPASSWORD_SUCCESS } from "./action";
import {
  setData,
  KEY_USER_TOKEN,
  KEY_NORMAL_USER,
} from "../../../utils/preferences";
import { SaveAlert } from "../../../utils/Swal";

var UserLogin = {
  password: "",
  confirmpassword: "",
};

var parent = null;

class ResetPassword extends Component {
  constructor(props) {
    super(props);
    parent = this;
    this.state = {
      status: false,
      UserLogin: Object.assign({}, UserLogin),
      error: {
        password: "",
        confirmpassword: "",
      },
    };
  }

  async componentWillMount() {
    await this.props.actionGetLanguageJson();
  }
  async componentDidMount() {
    const { location } = this.props;
    const urlParams = new URLSearchParams(location.search);
    const token = urlParams.get("token");
    localStorage.removeItem(KEY_NORMAL_USER);
    await setData(KEY_USER_TOKEN, JSON.stringify({ token: token }));
  }
  async componentWillReceiveProps(nextProps) {
    const { UserData } = nextProps;
    console.log(this.props);

    if (UserData !== null) {
      try {
        if (localStorage.getItem(KEY_USER_TOKEN)?.length && this.state.status) {
          await localStorage.removeItem(KEY_USER_TOKEN);
          SaveAlert("PasswordResetSuccessfully");
          const redirectTo = "/";
          window.location.href = redirectTo;
        }
      } catch (error) {
        // Error while removing
      }
    }
  }

  handleFormFields = (event) => {
    event.preventDefault();
    const { name, value } = event.target;
    parent.handleValidation(name, value);
  };

  handleValidation(name, value) {
    var { UserLogin } = this.state;
    UserLogin[name] = value;
    parent.setState({ UserLogin });

    $("fg-confirm-password, #fg-password").removeClass("error");

    if (UserLogin.confirmpassword.length === 0) {
      $("#fg-confirm-password").addClass("error");
      this.setState({ status: false });
    }
    if (UserLogin.confirmpassword.length !== 0) {
      $("#fg-confirm-password").removeClass("error");
      this.setState({ status: false });
    }
    if (UserLogin.password.length === 0) {
      $("#fg-password").addClass("error");
      this.setState({ status: false });
    }

    if (UserLogin.password !== UserLogin.confirmpassword) {
      $("#fg-confirm-password").addClass("error");
      $(".errorp").addClass("d-block");
      this.setState({ status: false });
    }

    if (UserLogin.password === UserLogin.confirmpassword) {
      $(".errorp").removeClass("d-block");
      this.setState({ status: true });
    }
  }

  handleSubmit(event) {
    event.preventDefault();

    var { UserLogin, status } = parent.state;
    var key = "";
    for (key in UserLogin) {
      parent.handleValidation(key, UserLogin[key]);
    }
    if (status) {
      parent.props.actionResetPassword(parent.state.UserLogin);
    }
  }

  render() {
    const { confirmpassword, password } = this.state.UserLogin;
    const { lang } = this.props;

    return (
      <section className="flexbox-container mt-5">
        <div className="col-12 d-flex  align-items-center justify-content-center">
          <div className="col-lg-3 col-md-6 col-10 p-0">
            <div className="card px-1 py-1 m-0">
              {/* <div className="card-header border-0"></div> */}
              <div className="card-content">
                {/* <p className="card-subtitle text-muted text-center font-large-1 mx-2 mb-2 primary">
                  <h5>{lang?.MONTEVIDEOSUITERESERVATIONSYSTEM}</h5>
                </p> */}
                <h5 className="text-center font-weight-bold">
                  {lang?.SetPassword}
                </h5>
                <p className="card-subtitle text-muted text-center mt-2 mx-2 mb-2 primary">
                  <span>{lang?.PleaseSetPassword}</span>
                </p>
                <div className="card-body">
                  <form className="form-horizontal" novalidate>
                    <fieldset className="form-group position-relative has-icon-left">
                      <div className="form-group" id="fg-password">
                        <input
                          type="password"
                          className="form-control"
                          id="password"
                          name="password"
                          value={password}
                          placeholder={lang?.Password}
                          onChange={this.handleFormFields}
                          required
                        />
                        <div className="form-control-position">
                          <i className="fa fa-key"></i>
                        </div>
                      </div>
                    </fieldset>
                    <fieldset className="form-group position-relative has-icon-left">
                      <div className="form-group" id="fg-confirm-password">
                        <input
                          type="password"
                          className="form-control"
                          id="confirmpassword"
                          name="confirmpassword"
                          value={confirmpassword}
                          placeholder={lang?.ConfirmPassword}
                          onChange={this.handleFormFields}
                          required
                        />
                        <div className="form-control-position">
                          <i className="fa fa-key"></i>
                        </div>
                      </div>
                      <p class="errorp text-danger d-none">
                        {lang.ConfirmPasswordNotMacthed}
                      </p>
                    </fieldset>
                    <button
                      type="submit"
                      style={{
                        backgroundColor: "#434D59",
                        padding: "5px",
                        height: "30px",
                        alignItems: "center",
                      }}
                      className="btn btn-dark btn-block"
                      onClick={this.handleSubmit}
                    >
                      {lang?.SetPassword}
                    </button>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    );
  }
}

const mapStateToProps = ({ VenueAppReducer, ResetPasswordReducer }) => {
  var UserData = null;
  switch (ResetPasswordReducer.tag) {
    case ACTION_GET_LANG_JSON:
      return { lang: VenueAppReducer.lang };
    case ACTION_RESETPASSWORD_SUCCESS:
      UserData = Object.assign({}, ResetPasswordReducer.data);
      delete ResetPasswordReducer.data;
      return { lang: VenueAppReducer.lang, UserData };
    default:
      return { lang: VenueAppReducer.lang };
  }
};

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    { actionGetLanguageJson, actionResetPassword },
    dispatch
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(ResetPassword);
