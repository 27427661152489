import { ACTION_ADDRESERVATION_SUCCESS } from "./action";
import createReducer from "../../../reducers/createReducer";

const INITIAL_STATE = {
  data: [],
  loading: false,
};

const ReservationAddReducer = createReducer(INITIAL_STATE, {
  [ACTION_ADDRESERVATION_SUCCESS](state, action) {
    return Object.assign(
      {},
      {
        ...state,
        data: action.payload.data,
        tag: ACTION_ADDRESERVATION_SUCCESS,
        loading: false,
      }
    );
  },
});
export default ReservationAddReducer;
