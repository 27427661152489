import createReducer from "../../reducers/createReducer";
import { ACTION_GET_ADMIN_LANG, ADMIN_PROFILE_BY_TOKEN_SUCCESS } from "./action";

const INITIAL_STATE = {
  lang: {},
  myProfile: {},
};

const adminReducer = createReducer(INITIAL_STATE, {
  [ACTION_GET_ADMIN_LANG](state, action) {
    const language = action.payload;
    localStorage.setItem("--admin-lang", JSON.stringify(language));
    return {
      ...state,
      lang: language,
    };
  },
  [ADMIN_PROFILE_BY_TOKEN_SUCCESS](state, action) {
    return {
      ...state,
      myProfile: action?.payload
    }
  }
});

export default adminReducer;
