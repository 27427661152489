import { ACTION_OBJECTLIST_SUCCESS, ACTION_EDITSTATUS_SUCCESS } from "./action";
import createReducer from "../../../../reducers/createReducer";

const INITIAL_STATE = {
  data: [],
  loading: false,
};
var reA = /[^a-zA-Z]/g;
var reN = /[^0-9]/g;

const sortAlphaNum = (a, b) => {
  var aA = a.replace(reA, "");
  var bA = b.replace(reA, "");
  if (aA === bA) {
    var aN = parseInt(a.replace(reN, ""), 10);
    var bN = parseInt(b.replace(reN, ""), 10);
    return aN === bN ? 0 : aN > bN ? 1 : -1;
  } else {
    return aA > bA ? 1 : -1;
  }
};

const ObjectManagementListReducer = createReducer(INITIAL_STATE, {
  [ACTION_OBJECTLIST_SUCCESS](state, action) {
    return {
      ...state,
      data: action.payload.data,
      tag: ACTION_OBJECTLIST_SUCCESS,
      loading: false,
    };
  },
  [ACTION_EDITSTATUS_SUCCESS](state, action) {
    return {
      ...state,
      tag: ACTION_EDITSTATUS_SUCCESS,
      loading: false,
    };
  },
});

export default ObjectManagementListReducer;
