import {
  ACTION_RESERVATIONLIST_SUCCESS,
  ACTION_FILTERRESERVATIONLIST_SUCCESS,
} from "./action";
import createReducer from "../../../reducers/createReducer";

const INITIAL_STATE = {
  data: [],
  loading: false,
};

const ReservationListReducer = createReducer(INITIAL_STATE, {
  [ACTION_RESERVATIONLIST_SUCCESS](state, action) {
    return {
      ...state,
      data: action.payload.data,
      tag: ACTION_RESERVATIONLIST_SUCCESS,
      loading: false,
    };
  },
  [ACTION_FILTERRESERVATIONLIST_SUCCESS](state, action) {
    return {
      ...state,
      data: action.payload.data,
      tag: ACTION_FILTERRESERVATIONLIST_SUCCESS,
      loading: false,
    };
  },
});
export default ReservationListReducer;
