import React, { Component, Suspense, lazy } from "react";
import { Route, Redirect } from "react-router-dom";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import Header from "../../components/header";
import SideBar from "../../components/sidebar";
import Content from "../../components/content";
import Footer from "../../components/footer";
import {
  actionGetLanguageJson,
  ACTION_GET_LANG_JSON,
  actionGetStyles,
  ACTION_GET_STYLES,
} from "./action";
import { ConnectedRouter } from "connected-react-router";
import { checkIfInIframe, manageHistoryStack, setCSSVars } from "../../utils";
import { setHeadAndManifest } from "../../utils/jquery";
import { defaultColors } from "../../constants";
import { getData, KEY_NORMAL_USER } from "../../utils/preferences";

import $ from "jquery";

import Login from "./login";
import ForgotPassword from "./forgot-password";
import PrivacyStatementLoginScreen from "./privacy-statement-login-screen";

class AppRoute extends Component {
  constructor(props) {
    super(props);
    this.state = {
      user: null,
    };
    if (
      !checkIfInIframe() &&
      !window?.parent?.location?.pathname?.includes("app-preview") &&
      Boolean(localStorage.getItem("preview-mode"))
    ) {
      [
        "preview-type",
        "preview-mode",
        "preview-mode-start",
        "active_preview_prevUrl",
        "all_preview_prevUrl",
        "venue_login",
        "guest_details",
      ].forEach((item) => {
        localStorage.removeItem(item);
      });
    }
  }

  async componentDidMount() {
    await this.props.actionGetLanguageJson();
    this.setBtnWidths();
    // if (window.innerWidth <= 1022) {
    //   setCSSVars({ "--header-margin-left": "0px" });
    //   setCSSVars({ "--display": "none" });
    // } else {
    //   setCSSVars({ "--header-margin-left": "240px" });
    //   setCSSVars({ "--display": "block" });
    // }
    // alert("yes")
  }
  async componentWillMount() {
    await this.props.actionGetStyles();

    // var r = document.querySelector(':root');
    // r.style.setProperty('--body-color', 'white');

    var user = await getData(KEY_NORMAL_USER);
    if (user !== null) {
      var objUser = JSON.parse(user);
      this.setState({ user: objUser });
    }
  }

  setBtnWidths = () => {
    const ac_wi = $("#btn-actions").width();
    var styles = {
      "--actions-width": ac_wi + "px",
    };
    Object.keys(styles).map((k) => {
      var value = styles[k];
      return document.documentElement.style.setProperty(k, value);
    });
  };

  componentDidUpdate(prevProps) {
    if (this.props?.location?.pathname !== prevProps?.location?.pathname) {
      console.log(this.props.location.pathname);

      if (!String(window.location.host).startsWith("suitesadmin")) {
        if (window.innerWidth <= 1022) {
          setCSSVars({ "--header-margin-left": "0px" });
          setCSSVars({ "--display": "none" });
        } else {
          setCSSVars({ "--header-margin-left": "240px" });
          setCSSVars({ "--display": "block" });
        }
      }
    }
  }
  componentWillReceiveProps(nextProps) {
    const { lang, Styles } = nextProps;

    if (Styles !== undefined) {
      if (Styles.length > 0) {
        // setCSSVars({'--body-color':Styles[0].web_app_bg_color})
        setCSSVars({ "--button-color": Styles[0].web_app_button_color });
        setCSSVars({ "--font-color": Styles[0].editor_font_color });
        setCSSVars({ "--font-family": Styles[0].editor_font_family });
        setCSSVars({ "--fontSize": Styles[0].editor_font_size });
        setCSSVars({ "--button-text": Styles[0].web_app_button_text_color });
        setCSSVars({ "--body-color": Styles[0].web_app_bg_color });
      }
    }
  }

  render() {
    var { user } = this.state;
    var { props } = this;
    var { location } = props;
    var { lang } = props;

    if (location.pathname === "/forgot-password") {
      return (
        <ConnectedRouter history={this.props.history}>
          <ForgotPassword {...props} />
        </ConnectedRouter>
      );
    }
    else if (location.pathname === "/login-privacy-statement") {
      return (
        // <ConnectedRouter history={this.props.history}>
        <PrivacyStatementLoginScreen {...props} />
        // </ConnectedRouter>
      );
    }
    else if (user !== null) {
      return (
        <ConnectedRouter history={this.props.history}>
          <Header {...{ ...props, styles: this.props.Styles }} />
          <SideBar {...{ ...props, styles: this.props.Styles }} />
          <Content {...{ ...props, styles: this.props.Styles }} />
          {/* <Footer {...{ ...props, styles: this.props.Styles }} />
          <div id="btn-actions" className="invisible display-inline-block">
            <button type="button" class="btn btn-primary btn-sm round ml-1">
              {lang?.Edit}
            </button>
            <button type="button" class="btn btn-danger btn-sm round ml-1">
              {lang?.Inactivate}
            </button>
            <button
              type="button"
              class="btn btn-danger btn-delete btn-sm round ml-1"
            >
              {lang?.Delete}
            </button>
          </div> */}
        </ConnectedRouter>
      );
    } else if (location.pathname === "/" || user === null) {
      return (
        <ConnectedRouter history={this.props.history}>
          <Login {...props} />
        </ConnectedRouter>
      );
    }
  }
}

const mapStateToProps = ({ VenueAppReducer }) => {
  var Styles = null;
  switch (VenueAppReducer.tag) {
    case ACTION_GET_LANG_JSON:
      return { lang: VenueAppReducer.lang };
    case ACTION_GET_STYLES:
      Styles = VenueAppReducer.data.data;
      return { lang: VenueAppReducer.lang, Styles };
    default:
      return { lang: VenueAppReducer.lang };
  }
};

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    { actionGetLanguageJson, actionGetStyles },
    dispatch
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(AppRoute);
