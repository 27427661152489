import createReducer from "../../../reducers/createReducer";
import {
  ACTION_GET_USERS,
  ACTION_GET_USER_FORM_CONFIG,
} from "./actions";

const INITIAL_STATE = {
  users: [],
  formConfig: [],
};

const UsersManagementReducer = createReducer(INITIAL_STATE, {
  [ACTION_GET_USERS](state, action) {
    return {
      ...state,
      users: [].concat(action.payload),
    };
  },
  [ACTION_GET_USER_FORM_CONFIG](state, action) {
    return {
      ...state,
      formConfig: action.payload.sort((a, b) => {
        if (a.sort > b.sort) return 1;
        if (a.sort < b.sort) return -1;
        return 0;
      }),
    };
  },
});

export default UsersManagementReducer;
