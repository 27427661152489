import { combineReducers } from "redux";
import { connectRouter } from "connected-react-router";

// ADMIN
import AdminLoginReducer from "../routes/admin/login/reducer";
import ForgotPasswordReducer from "../routes/admin/forgot_password/reducer";
import NewPasswordReducer from "../routes/admin/new_password/reducer";
import adminReducer from "../routes/admin/reducer";
import AdminStaffAccountsManagement from "../routes/admin/staff-accounts-and-mgmt/reducer";

// App
import VenueAppReducer from "../routes/app/reducer";
import VenueAppLoginReducer from "../routes/app/login/reducer";
import VenueAppHomeReducer from "../routes/app/home/reducer";
// import ReservationsReducer from "../routes/app/reservations/reducer";
import SupportRequestReducer from "../routes/app/support-req/store/SupportRequestReducer";
// import ServiceRequestsHistoryReducer from "../routes/app/service-requests-history/reducer";
import StaffAuthorizationGroupListReducer from "../routes/admin/staff-authorization-groups/list/reducer";
import EditStaffAuthorizationGroupReducer from "../routes/admin/staff-authorization-groups/edit/reducer";
import AddStaffAuthorizationGroupReducer from "../routes/admin/staff-authorization-groups/add/reducer";
import userManagementReducer from "../routes/admin/staff-accounts-and-mgmt/reducer";
import UsersManagementReducer from "../routes/admin/users-management/reducer";
import ObjectManagementListReducer from "../routes/admin/objects-management/list/reducer";
import ObjectManagementAddReducer from "../routes/admin/objects-management/edit/reducer";
import ObjectListReducer from "../routes/admin/object-settings/object-unavailiablity/reducer";
import AddOnServiceReducer from "../routes/admin/objects-management/addon/reducer";
import ObjectSettingReducer from "../routes/admin/object-settings/object-setting-details/reducer";
import LookFeelReducer from "../routes/admin/web-app-look-and-feel/reducer";
import WebAppCMSReducer from "../routes/admin/web-app-cms/reducer";
import UserLoginReducer from "../routes/app/login/reducer";
import UserLandingPageReducer from "../routes/app/landing-screen/reducer";
import ResetPasswordReducer from "../routes/app/reset-password/reducer";
import CheckUsernameReducer from "../routes/app/forgot-password/reducer";
import ReservationOverviewListReducer from "../routes/app/reservation-overview-screen/reducer";
import ReservationAddReducer from "../routes/app/reservation-completion-screen/reducer";
import ReserationDetailsReducer from "../routes/admin/reservation-details/reducer";
import UpdatePasswordReducer from "../routes/app/update-password/reducer";
import ReservationCalendarListReducer from "../routes/admin/reservation-calendar/reducer";
import ReservationListReducer from "../routes/app/reservation-history/reducer";
import ReservationCMSReducer from "../routes/admin/reservation-cms/reducer";
import ObjectUnavailabiltyListReducer from "../components/reservation-calender/reducer";

export default (history) =>
  combineReducers({
    router: connectRouter(history),
    // Admin
    adminReducer,
    UsersManagementReducer,
    userManagementReducer,
    AdminLoginReducer,
    ForgotPasswordReducer,
    NewPasswordReducer,
    AdminStaffAccountsManagement,
    ReservationCMSReducer,
    // APP
    VenueAppReducer,
    VenueAppLoginReducer,
    VenueAppHomeReducer,
    // ReservationsReducer,
    SupportRequestReducer,
    // ServiceRequestsHistoryReducer,
    StaffAuthorizationGroupListReducer,
    EditStaffAuthorizationGroupReducer,
    AddStaffAuthorizationGroupReducer,
    //object
    ObjectManagementListReducer,
    ObjectManagementAddReducer,
    ObjectListReducer,
    AddOnServiceReducer,
    ObjectSettingReducer,
    LookFeelReducer,
    WebAppCMSReducer,
    //reservation
    ReservationCalendarListReducer,
    //User
    UserLoginReducer,
    UserLandingPageReducer,
    ResetPasswordReducer,
    CheckUsernameReducer,
    ReservationOverviewListReducer,
    ReservationAddReducer,
    ObjectUnavailabiltyListReducer,
    UpdatePasswordReducer,
    ReservationListReducer,

    //admin
    ReserationDetailsReducer,
  });
