import Api from "../../api";
import {
  API_GET_ADMIN_LANGUAGE,
  API_GET_ADMIN_LANGUAGE_WITHOUT_LOCALE,
  ADMIN_PROFILE_BY_TOKEN,
} from "../../api/constants";
import { fetchSuccess } from "../../utils/action.util";

export const ACTION_GET_ADMIN_LANG = "ACTION_GET_ADMIN_LANG";
export const ADMIN_PROFILE_BY_TOKEN_SUCCESS = "ADMIN_PROFILE_BY_TOKEN_SUCCESS";

export const actionGetAdminLanguage =
  (langSpecific, noOfTiemesTried = 0) =>
  (dispatch) => {
    var url = `${
      langSpecific
        ? API_GET_ADMIN_LANGUAGE_WITHOUT_LOCALE
        : API_GET_ADMIN_LANGUAGE
    }`;
    return Api.get(url)
      .then((response) => {
        dispatch(fetchSuccess(ACTION_GET_ADMIN_LANG, response));
        return response;
      })
      .catch((error) => {
        console.error("error", error);
        const maxTries = 5;
        if (noOfTiemesTried <= maxTries) {
          dispatch(actionGetAdminLanguage(true, noOfTiemesTried + 1));
        }
      });
  };

export const actionGetMyProfile = () => (dispatch) => {
  return Api.get(ADMIN_PROFILE_BY_TOKEN)
    .then((result) => {
      dispatch({ type: ADMIN_PROFILE_BY_TOKEN_SUCCESS, payload: result?.data });
      return result?.data;
    })
    .catch((err) => {
      console.log(err);
    });
};
