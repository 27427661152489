import Api from "../../../api";
import { fetchSuccess, loading } from "../../../utils/action.util";
import {
  API_CREATE_RESERVATIONCMS,
  API_UPDATE_RESERVATIONCMS,
  API_GET_RESERVATIONCMS,
} from "../../../api/constants";

export const ADDRESERVATIONCMS_LOADING = "ADDRESERVATIONCMS_LOADING";
export const ACTION_ADDRESERVATIONCMS_SUCCESS =
  "ACTION_ADDRESERVATIONCMS_SUCCESS";

export const EDITRESERVATIONCMS_LOADING = "EDITRESERVATIONCMS_LOADING";
export const ACTION_EDITRESERVATIONCMS_SUCCESS =
  "ACTION_EDITRESERVATIONCMS_SUCCESS";

export const RESERVATIONCMSLIST_LOADING = "RESERVATIONCMSLIST_LOADING";
export const ACTION_RESERVATIONCMSLIST_SUCCESS =
  "ACTION_RESERVATIONCMSLIST_SUCCESS";

export function actionGetReservationCMS() {
  return (dispatch, getState) =>
    Api.get(API_GET_RESERVATIONCMS)
      .then((res) => {
        const data = res;
        console.log(data);
        dispatch(fetchSuccess(ACTION_RESERVATIONCMSLIST_SUCCESS, { data }));
      })
      .catch((reason) => {
        /** Handle Error */
        var data = {
          message: "",
        };

        dispatch(loading(RESERVATIONCMSLIST_LOADING, { loading: false, data }));
        return reason;
      });
}

export function actionEditReservationCMS(params) {
  return (dispatch, getState) =>
    Api.post(API_UPDATE_RESERVATIONCMS + "/" + params.id, params)
      .then((res) => {
        const data = res;
        console.log(data);
        dispatch(fetchSuccess(ACTION_EDITRESERVATIONCMS_SUCCESS, { data }));
        return data;
      })
      .catch((reason) => {
        /** Handle Error */
        var data = {
          message: "",
        };
        dispatch(loading(EDITRESERVATIONCMS_LOADING, { loading: false, data }));
        return reason;
      });
}

export function actionAddReservationCMS(params) {
  return (dispatch, getState) =>
    Api.post(API_CREATE_RESERVATIONCMS, params)
      .then((res) => {
        const data = res;
        dispatch(fetchSuccess(ACTION_ADDRESERVATIONCMS_SUCCESS, { data }));
      })
      .catch((reason) => {
        /** Handle Error */
        var data = {
          message: "",
        };
        dispatch(loading(ADDRESERVATIONCMS_LOADING, { loading: false, data }));
        return reason;
      });
}
