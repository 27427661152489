import Api from "../../../api";
import { fetchSuccess, loading } from "../../../utils/action.util";
import {
  API_CREATE_WEBAPPCMS,
  API_UPDATE_WEBAPPCMS,
  API_GET_WEBAPPCMS,
} from "../../../api/constants";

export const ADDWEBAPPCMS_LOADING = "ADDWEBAPPCMS_LOADING";
export const ACTION_ADDWEBAPPCMS_SUCCESS = "ACTION_WEBAPPCMS_SUCCESS";

export const EDITLOOKFEEL_LOADING = "EDITLOOKFEEL_LOADING";
export const ACTION_EDITLOOKFEEL_SUCCESS = "ACTION_EDITLOOKFEEL_SUCCESS";

export function actionGetWebAppLookFeel() {
  return (dispatch, getState) =>
    Api.get(API_GET_WEBAPPCMS)
      .then((res) => {
        const data = res;
        console.log(data);
        dispatch(fetchSuccess(ACTION_ADDWEBAPPCMS_SUCCESS, { data }));
      })
      .catch((reason) => {
        /** Handle Error */
        var data = {
          message: "",
        };

        dispatch(loading(ADDWEBAPPCMS_LOADING, { loading: false, data }));
        return reason;
      });
}

export function actionEditLookFeel(params) {
  return (dispatch, getState) =>
    Api.post(API_UPDATE_WEBAPPCMS + "/" + params.id, params)
      .then((res) => {
        const data = res;
        console.log(data);
        dispatch(fetchSuccess(ACTION_ADDWEBAPPCMS_SUCCESS, { data }));
      })
      .catch((reason) => {
        /** Handle Error */
        var data = {
          message: "",
        };
        dispatch(loading(ADDWEBAPPCMS_LOADING, { loading: false, data }));
        return reason;
      });
}

export function actionAddLookFeel(params) {
  return (dispatch, getState) =>
    Api.post(API_CREATE_WEBAPPCMS, params)
      .then((res) => {
        const data = res;
        dispatch(fetchSuccess(ACTION_ADDWEBAPPCMS_SUCCESS, { data }));
      })
      .catch((reason) => {
        /** Handle Error */
        var data = {
          message: "",
        };
        dispatch(loading(ADDWEBAPPCMS_LOADING, { loading: false, data }));
        return reason;
      });
}
