import Api from "../../../api";
import { fetchSuccess, loading } from "../../../utils/action.util";
import {
  API_GET_RESERVATION_INFO,
  API_UPDATE_RESERVATION_NOTE,
  API_CANCEL_RESERVATION,
  API_GET_OBJECTS,
  API_UPDATESUITE,
  API_GET_ALLADDONSERVICES,
} from "../../../api/constants";

export const ACTION_SEARCHRESERVATION_LOADING = "ADDOBJECT_LOADING";
export const ACTION_SEARCHRESERVATION_SUCCESS =
  "ACTION_SEARCHRESERVATION_SUCCESS";

export const ACTION_UPDATENOTE_LOADING = "UPDATENOTE_LOADING";
export const ACTION_UPDATENOTE_SUCCESS = "ACTION_UPDATENOTE_SUCCESS";

export const ACTION_CANCELSTATUS_LOADING = "CANCELSTATUS__LOADING";
export const ACTION_CANCELSTATUS__SUCCESS = "ACTION_CANCELSTATUS__SUCCESS";

export const ACTION_OBJECTLIST_LOADING = "OBJECTLIST__LOADING";
export const ACTION_OBJECTLIST__SUCCESS = "ACTION_OBJECTLIST__SUCCESS";

export const ACTION_UPDATESUITE_LOADING = "UPDATESUITE__LOADING";
export const ACTION_UPDATESUITE__SUCCESS = "ACTION_UPDATESUITE__SUCCESS";

export function actionSearchReservation(params) {
  return (dispatch, getState) =>
    Api.post(API_GET_RESERVATION_INFO, params)
      .then((res) => {
        const data = res;
        dispatch(fetchSuccess(ACTION_SEARCHRESERVATION_SUCCESS, { data }));
      })
      .catch((reason) => {
        /** Handle Error */
        var data = {
          message: "",
        };
        dispatch(
          loading(ACTION_SEARCHRESERVATION_LOADING, { loading: false, data })
        );
        return reason;
      });
}

export function actionUpdateInternalNotes(params, id) {
  return (dispatch, getState) =>
    Api.post(API_UPDATE_RESERVATION_NOTE + id, params)
      .then((res) => {
        const data = res;
        dispatch(fetchSuccess(ACTION_UPDATENOTE_SUCCESS, { data }));
        return data;
      })
      .catch((reason) => {
        /** Handle Error */
        var data = {
          message: "",
        };
        dispatch(loading(ACTION_UPDATENOTE_LOADING, { loading: false, data }));
        return reason;
      });
}

export function actionCancelStatuss(params, id) {
  return (dispatch, getState) =>
    Api.post(API_CANCEL_RESERVATION + id, params)
      .then((res) => {
        const data = res;
        dispatch(fetchSuccess(ACTION_CANCELSTATUS__SUCCESS, { data }));
        return data;
      })
      .catch((reason) => {
        /** Handle Error */
        var data = {
          message: "",
        };
        dispatch(
          loading(ACTION_CANCELSTATUS_LOADING, { loading: false, data })
        );
        return reason;
      });
}

export function actionUpdateSuite(params) {
  return (dispatch, getState) =>
    Api.post(API_UPDATESUITE, params)
      .then((res) => {
        const data = res;
        dispatch(fetchSuccess(ACTION_UPDATESUITE__SUCCESS, { data }));
        return data;
      })
      .catch((reason) => {
        /** Handle Error */
        var data = {
          message: "",
        };
        dispatch(loading(ACTION_UPDATESUITE_LOADING, { loading: false, data }));
        return reason;
      });
}

export function actionGetAllAddOnServies() {
  return (dispatch, getState) =>
    Api.get(API_GET_ALLADDONSERVICES)
      .then((res) => {
        const data = res;
        return data;
      })
      .catch((reason) => {
        /** Handle Error */
        var data = {
          message: "",
        };
        return reason;
      });
}

export function actionGetObjectList() {
  return (dispatch, getState) =>
    Api.get(API_GET_OBJECTS)
      .then((res) => {
        const data = res;
        console.log(data);
        dispatch(fetchSuccess(ACTION_OBJECTLIST__SUCCESS, { data }));
        return data;
      })
      .catch((reason) => {
        /** Handle Error */
        var data = {
          message: "",
        };

        dispatch(loading(ACTION_OBJECTLIST_LOADING, { loading: false, data }));
        return reason;
      });
}
