import Api from '../../../api';
import { fetchSuccess, loading } from '../../../utils/action.util';
import { API_USERLOGIN } from '../../../api/constants';
import { PRINT } from '../../../utils';

export const USEROGIN_LOADING = 'ADMINLOGIN_LOADING';
export const ACTION_USERLOGIN_SUCCESS = 'ACTION_ADMINLOGIN_SUCCESS';

export function actionUserLogin(params) {
    return (dispatch, getState) => Api.post(API_USERLOGIN, params).then((res) => {
        const data = res;
        PRINT('ACTION RES', data)
        dispatch(fetchSuccess(ACTION_USERLOGIN_SUCCESS, { data }));
    }).catch((reason) => {
        /** Handle Error */
        var data = {
            message: ''
        }
        dispatch(loading(USEROGIN_LOADING, { loading: false, data }));
    });
}
