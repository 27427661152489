import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Form } from "react-bootstrap";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { Button } from "react-bootstrap";
import StickyPage from "../../../components/ui/stickyPage";
import Header from "../../../components/ui/Header";
import StickyHeader from "../../../components/ui/StickyHeader";
import RichTextEditor from "../../../components/ui/RichTextEditor";
import { actionGetPrivacyStatement } from "./action";

var parent = null;

class PrivacyStatementLoginScreen extends React.Component {
    constructor(props) {
        super(props);
        parent = this;
        this.state = {
            initialValues: {
                privacy_statement_en: "",
                privacy_statement_de: "",
            },
        };
    }

    async componentWillMount() { }

    async componentDidMount() {
        const res = await this.props.actionGetPrivacyStatement();
        if (res?.data) {
            var obj = {};
            for (let i = 0; i < res?.data?.length; i++) {
                obj = res?.data[i];
            }
            if (res?.data?.length > 0) {
                this.setState({ initialValues: { ...obj } });
            }
        }
    }

    componentDidUpdate(prevProps) { }

    async componentWillReceiveProps(nextProps) { }

    strip(html) {
        var tmp = document.createElement("DIV");
        tmp.innerHTML = html;
        return tmp.textContent || tmp.innerText;
    }

    handleEditClick = (data) => { };

    render() {
        var userLang = navigator.language || navigator.userLanguage;
        const { lang } = this.props;
        return (
            <>
                <div style={{
                    margin: "80px 100px 10px 100px",
                    padding: "20px 30px 10px 30px",
                    backgroundColor: "white"
                }}>
                    <section className="flexbox-container">
                        <p className="text-uppercase font-weight-bold">
                            <h3>{lang?.PrivacyStatement}</h3>
                        </p>
                        <div
                            className="col-12 d-flex"
                            style={{ paddingRight: "0px", paddingLeft: "0px" }}
                        >
                            <div className="p-0">
                                <div className="mt-2">
                                    <div className="card-content custom-home-title">
                                        <p className="card-subtitle text-muted primary">
                                            <div
                                                dangerouslySetInnerHTML={{
                                                    __html:
                                                        userLang !== "nl"
                                                            ? this.state.initialValues.privacy_statement_en
                                                            : this.state.initialValues.privacy_statement_de,
                                                }}
                                            />
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                </div>
            </>
        );
    }
}

// const mapStateToProps = ({
//   adminReducer,
// }) => ({
//   lang: adminReducer.lang,
//   users: [],
// });

function mapStateToProps({ VenueAppReducer }) {
    return { lang: VenueAppReducer.lang };
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({ actionGetPrivacyStatement }, dispatch);
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(PrivacyStatementLoginScreen);
