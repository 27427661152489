import {
  ACTION_RESETPASSWORD_SUCCESS,
  ACTION_UPDATEPASSWORD_SUCCESS,
} from "./action";
import createReducer from "../../../reducers/createReducer";

const INITIAL_STATE = {
  data: [],
  data2: [],
  loading: false,
};

const ResetPasswordReducer = createReducer(INITIAL_STATE, {
  [ACTION_RESETPASSWORD_SUCCESS](state, action) {
    return Object.assign(
      {},
      {
        ...state,
        data: action.payload.data,
        tag: ACTION_RESETPASSWORD_SUCCESS,
        loading: false,
      }
    );
  },
  [ACTION_UPDATEPASSWORD_SUCCESS](state, action) {
    return Object.assign({
      ...state,
      data2: action.payload.data,
      tag: ACTION_UPDATEPASSWORD_SUCCESS,
      loading: false,
    });
  },
});
export default ResetPasswordReducer;
